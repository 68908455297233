import { KeyboardEvent, MouseEvent, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { PopoverPosition } from 'react-tiny-popover'

import Image from 'domains/Sanity/Image'

import { FacePile, FacePileUser } from 'components/FacePile'
import FreePill from 'components/FreePill'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import {
  BookmarkFolderPartsFragment,
  CmsSectionContentType,
  ConceptCardSectionPartsFragment,
  Host,
  LessonSectionPartsFragment,
  ProgramBookmarkPartsFragment,
  useTrackServerEventMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { listify } from 'utils/stringUtils'
import { cn } from 'utils/tailwind'
import { getAnonymousId } from 'utils/tracking/segment'

import BaseCard, { CardVariant, CardVariants } from './BaseCard'

export interface LegacyContentCardProps {
  content: ConceptCardSectionPartsFragment | LessonSectionPartsFragment
  variant?: CardVariant
  onClick?: (e: MouseEvent | KeyboardEvent) => void
  additionalTrackingParams?: {}
  // Bookmark-related props
  bookmark?: ProgramBookmarkPartsFragment
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  hideBookmarkButton?: boolean
  // This is needed because some cards are rendered in a container that manages overflow (e.g., uk-slider) that
  // will clip the bookmark dropdown menu now that the button is at the bottom of the card.
  bookmarkDropdownPosition?: PopoverPosition
  pageLocation?: string
  additionalRelatedIdentifiers?: {}
  customFooter?: ReactNode
  customHoverMiniCard?: boolean
}

const LegacyContentCard = ({
  content,
  variant = CardVariants.Vertical,
  onClick,
  bookmark,
  currentFolder,
  bookmarkFolders,
  openAddToBookmarkFolderModal,
  restoreBookmark,
  handleRemoveFromFolder,
  hideBookmarkButton,
  bookmarkDropdownPosition,
  pageLocation,
  additionalRelatedIdentifiers,
  customFooter,
  customHoverMiniCard
}: LegacyContentCardProps) => {
  const { currentUser } = useCurrentUser()
  const [trackServerEvent] = useTrackServerEventMutation()
  const { pathname } = useLocation()
  const relatedIdentifiers = {
    content_mode: 'async',
    ...additionalRelatedIdentifiers
  }

  const handleTracking = () => {
    trackServerEvent({
      variables: {
        input: {
          event: 'Content Clicked - Server',
          anonymousId: getAnonymousId(),
          properties: {
            content_title: content.name,
            content_type: 'CmsSection',
            cms_section_id: content.id,
            referrer: pathname,
            destination: content.href,
            location: pageLocation,
            display_type: `${variant}_card`,
            logged_in: !!currentUser,
            is_previewable: content.accessLevel === 'partial',
            relatedIdentifiers: relatedIdentifiers
          }
        }
      }
    })
  }

  const showFreeIcon = content.accessLevel !== 'partial'

  return (
    <BaseCard
      id={`ContentCard_${content.contentType?.toLowerCase()}_${content.id}`}
      contentType={content.contentType || CmsSectionContentType.LESSON}
      variant={variant}
      cmsContentId={content.id}
      cmsModuleId={content.cmsModule?.id}
      cmsProgramId={content.cmsProgram?.id}
      title={content.name}
      header={
        <LegacyContentHeader
          content={content}
          variant={variant}
          showFreeIcon={showFreeIcon}
        />
      }
      body={content.shortDescription}
      horizontalThumbnail={<LegacyContentHorizontalThumbnail content={content} />}
      verticalThumbnail={
        <LegacyContentVerticalThumbnail content={content} showFreeIcon={showFreeIcon} />
      }
      thumbnail={<Thumbnail content={content} />}
      footer={customFooter || <LegacyContentFooter content={content} />}
      destination={content.href}
      onClick={onClick}
      trackCardClick={handleTracking}
      bookmarkId={content.contentBookmarkId}
      bookmark={bookmark}
      currentFolder={currentFolder}
      bookmarkFolders={bookmarkFolders}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      restoreBookmark={restoreBookmark}
      handleRemoveFromFolder={handleRemoveFromFolder}
      hideBookmarkButton={hideBookmarkButton}
      bookmarkDropdownPosition={bookmarkDropdownPosition}
      customHoverMiniCard={customHoverMiniCard}
    />
  )
}

const LegacyContentHeader = ({
  content,
  showFreeIcon,
  variant
}: {
  content: ConceptCardSectionPartsFragment | LessonSectionPartsFragment
  showFreeIcon: boolean
  variant: CardVariant
}) => {
  const isSmall = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const authors = content.cmsProgram?.collaborators as Host[]
  if (!authors || authors.length === 0) return null

  const authorNames = authors
    .filter((author) => author && author.name)
    .map((author) => author.name) as string[]
  const facepileUsers = authors
    .filter((author) => author && author.thumbnailUrl)
    .map((author) => {
      return { id: author.id, avatarUrl: author.thumbnailUrl } as FacePileUser
    })

  return (
    <div className="flex gap-2 relative">
      <div className="flex shrink-0 grow-0">
        <FacePile users={facepileUsers} imageSize="small" />
      </div>
      <div
        className={cn(
          'text-ellipsis line-clamp-1',
          showFreeIcon && variant === CardVariants.Horizontal && 'pr-14'
        )}
      >
        {listify(authorNames)}
      </div>

      {showFreeIcon && variant === CardVariants.Horizontal && !isSmall && (
        <div className="absolute -top-0.5 right-0">
          <FreePill />
        </div>
      )}
    </div>
  )
}

const LegacyContentVerticalThumbnail = ({
  content,
  showFreeIcon
}: {
  content: ConceptCardSectionPartsFragment | LessonSectionPartsFragment
  showFreeIcon: boolean
}) => {
  if (!content.heroImageUrl) return null

  return (
    <>
      <Image
        className="h-full w-full rounded-t-xl object-cover"
        src={content.heroImageUrl}
        alt={content.name}
      />
      {showFreeIcon && (
        <div className="absolute top-0 right-0 mt-3 mr-3">
          <FreePill />
        </div>
      )}
    </>
  )
}

const LegacyContentHorizontalThumbnail = ({
  content
}: {
  content: ConceptCardSectionPartsFragment | LessonSectionPartsFragment
}) => {
  if (!content.heroImageUrl) return null

  return (
    <Image
      className="h-full w-full rounded-xl object-cover"
      src={content.heroImageUrl}
      alt={content.name}
    />
  )
}

const Thumbnail = ({
  content
}: {
  content: ConceptCardSectionPartsFragment | LessonSectionPartsFragment
}) => {
  if (!content.heroImageUrl) return null

  return (
    <Image
      className="h-full w-full rounded-l-xl object-cover"
      src={content.heroImageUrl}
      alt={content.name}
    />
  )
}

const isVideoContent = (type?: string | null) =>
  type && ['Clip', 'Event', 'Lesson'].includes(type)

const LegacyContentFooter = ({
  content
}: {
  content: ConceptCardSectionPartsFragment
}) => {
  const typename = content.__typename
  const contentType = content.contentType
  const contentSubtype = content.contentSubtype

  const numPhases = content.numPhases
  const estimatedTimeInMinutes = content.estimatedTime
  const estimatedType =
    isVideoContent(typename) || isVideoContent(contentType) ? 'video' : 'read'

  const showEstimatedTime = contentType !== 'Project' && contentSubtype !== 'Template'
  const showPhases = contentType === CmsSectionContentType.PROJECT

  return (
    <div className="whitespace-nowrap">
      <span>
        {showEstimatedTime && `${estimatedTimeInMinutes} min ${estimatedType}`}
        {showPhases && `${numPhases} phases`}
      </span>
    </div>
  )
}

export default LegacyContentCard
