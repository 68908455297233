import {
  CclCourseIdentifierPartsFragment,
  CourseIdentifierPartsFragment,
  CoursePreviewIdentifierPartsFragment,
  CourseSessionIdentifierPartsFragment,
  CourseSessionIsFirstPilotPartsFragment,
  MarketingCourseStartDatePartsFragment
} from 'gql'

import { formatInTimezone, isAfterDate } from 'utils/date'

export const COURSE_PAYMENT_METHODS = {
  unlimited: 'unlimited',
  cohortPass: 'cohort_pass',
  stripePayment: 'stripe_payment'
}

export type CourseIdentifiersType = {
  course?: CourseIdentifierPartsFragment | CoursePreviewIdentifierPartsFragment | null
  cclCourse?: CclCourseIdentifierPartsFragment | null
  courseSession?: CourseSessionIdentifierPartsFragment | null
}

export const downloadCourseCertificateOfCompletion = (userCourse: any) => {
  if (userCourse?.courseCompletionCertificate?.certificateUrl) {
    const url = userCourse?.courseCompletionCertificate.certificateUrl
    const a: HTMLAnchorElement = document.createElement('a')
    a.href = url
    a.download = 'Course Completion Certificate.png'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export const isFirstPilotCourseSession = (
  courseSession?: CourseSessionIsFirstPilotPartsFragment
) => {
  if (!courseSession?.startsAt) {
    return false
  }

  return !isAfterDate(new Date('2023-10-01'), courseSession.startsAt)
}

export interface CourseDashboardLinkParams {
  courseSlug: string
  sessionId?: string | number | null
  courseBlockIndex?: string | number | null
  contentBlockSlug?: string | null
  guideSlug?: string | null
  artifactSlug?: string | null
  eventId?: string | null
}

const extendCourseDashboardLink = (
  url: string,
  {
    courseBlockIndex,
    contentBlockSlug,
    guideSlug,
    artifactSlug,
    eventId
  }: CourseDashboardLinkParams
) => {
  if (guideSlug) {
    url += `/guides/${guideSlug}`
  } else if (artifactSlug) {
    url += `/artifacts/${artifactSlug}`
  } else if (eventId) {
    url += `/events/${eventId}`
  } else if ((courseBlockIndex || courseBlockIndex === 0) && contentBlockSlug) {
    url += `/course-blocks/${courseBlockIndex}/content-blocks/${contentBlockSlug}`
  }

  return url
}

export interface BuildCourseSessionLinkParams extends CourseDashboardLinkParams {
  sessionId: string | number
}

export const buildCourseSessionLink = (
  courseDashboardLinkParams: BuildCourseSessionLinkParams
) => {
  const { courseSlug, sessionId } = courseDashboardLinkParams

  return extendCourseDashboardLink(
    `/courses/${courseSlug}/sessions/${sessionId}`,
    courseDashboardLinkParams
  )
}

export const buildCourseOnDemandLink = (
  courseDashboardLinkParams: CourseDashboardLinkParams
) => {
  const { courseSlug } = courseDashboardLinkParams

  return extendCourseDashboardLink(
    `/courses/${courseSlug}/on-demand`,
    courseDashboardLinkParams
  )
}

export const buildCoursePreviewLink = (
  courseDashboardLinkParams: CourseDashboardLinkParams
) => {
  const { courseSlug } = courseDashboardLinkParams

  const extendedUrl = extendCourseDashboardLink(
    `/courses/${courseSlug}`,
    courseDashboardLinkParams
  )

  return `${extendedUrl}/preview`
}

export const buildCourseDashboardLinkFromPath = (
  courseDashboardLinkParams: CourseDashboardLinkParams
) => {
  const pathname = window.location.pathname
  const { sessionId } = courseDashboardLinkParams

  if (pathname.includes('on-demand')) {
    return buildCourseOnDemandLink(courseDashboardLinkParams)
  }

  if (pathname.includes('preview')) {
    return buildCoursePreviewLink(courseDashboardLinkParams)
  }

  if (pathname.includes('details')) {
    return buildCourseDetailsUrl(courseDashboardLinkParams)
  }

  if (pathname.includes('sessions')) {
    if (!sessionId) {
      return pathname
    }

    return buildCourseSessionLink(
      courseDashboardLinkParams as BuildCourseSessionLinkParams
    )
  }

  return pathname
}

export const buildCourseDetailsUrl = (
  courseDashboardLinkParams: CourseDashboardLinkParams
) => {
  const { courseSlug } = courseDashboardLinkParams

  const baseCourseSlug = `/courses/${courseSlug}`

  const extendedLink = extendCourseDashboardLink(
    baseCourseSlug,
    courseDashboardLinkParams
  )

  if (extendedLink === baseCourseSlug) {
    return `${baseCourseSlug}/details`
  } else {
    return `${extendedLink}/preview`
  }
}

export const buildCourseContentBlockLink = (
  courseDashboardLinkParams: CourseDashboardLinkParams
) => {
  const { courseSlug, contentBlockSlug } = courseDashboardLinkParams

  if (!courseSlug || !contentBlockSlug) {
    return ''
  }

  return `/courses/${courseSlug}/content-blocks/${contentBlockSlug}`
}

export const prettyStartDateFromMarketingCourse = (
  marketingCourse?: MarketingCourseStartDatePartsFragment,
  formatString: string = 'MMMM do'
) => {
  // ex: converts `2024-11-12` to `Starts November 12th`

  // we need to cast startDate on marketingCourse as UTC because the field in Sanity is just a date, ex 2023-11-02;
  // if we do not specify UTC then JS will cast it as the client's local timezone and could change the date
  if (!marketingCourse?.startDate) {
    return ''
  }

  return formatInTimezone(
    new Date(marketingCourse.startDate).toISOString(),
    'utc',
    formatString
  )
}

export const formatSessionTitle = (sessionTitle?: string | null) => {
  if (!sessionTitle) return ''
  return (
    sessionTitle.match(/\d{4}\s*\w*\s*\d{1,2}\s*-\s*\w*\s*\d{1,2}\s*([^]*)/)?.[1] ||
    sessionTitle
  )
}

export const slugOfFirstGuideInCourseBlocks = (courseBlocks: any) => {
  let slug
  for (const block of courseBlocks) {
    if (block?.guides?.length > 0) {
      const sortedGuides = [...block.guides].sort(
        (a: { displayOrder: number }, b: { displayOrder: number }) =>
          a.displayOrder - b.displayOrder
      )
      slug = sortedGuides[0].slug
      if (slug) return slug
    }
  }
  return null
}
