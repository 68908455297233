import { useLottie } from 'lottie-react'

import RequestLoadingBar from 'components/RequestLoadingBar'
import logoLoadingAnimation from 'components/animations/logo-loading.json'

export const LoadingAnimation = ({
  requestCompleted,
  onCompleted
}: {
  requestCompleted: boolean
  onCompleted: () => void
}) => {
  const { View } = useLottie({
    animationData: logoLoadingAnimation,
    loop: true,
    autoplay: true
  })

  return (
    <div className="h-full flex-grow w-full flex flex-col items-center justify-center rounded-xl">
      <div className="w-20 h-20 mb-6">{View}</div>
      <p className="font-semibold mb-6 text-center">
        Creating your goal based on your motivations & experience level...
      </p>
      <div className="w-full max-w-[12.5rem]">
        <RequestLoadingBar
          estimatedTimeToCompletion={2000}
          minimumTime={4000}
          requestCompleted={requestCompleted}
          onCompleted={onCompleted}
        />
      </div>
    </div>
  )
}
