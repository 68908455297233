import MuxPlayer from '@mux/mux-player-react'

import {
  trackVideoTrackingMetadata,
  useHandleContentProgressChange
} from 'utils/contentTrackingUtils'

import { ComplexPortableTextBlockValueProp } from '../types'

export const Video = ({
  value,
  onPressPlay,
  trackingMetadata
}: ComplexPortableTextBlockValueProp & {
  onPressPlay?: () => void
  trackingMetadata?: trackVideoTrackingMetadata
}) => {
  const handleContentProgressChange = useHandleContentProgressChange()

  // TODO: Deprecate passed-in calls of onPressPlay, and just handle things here.
  const onPlay = () => {
    if (onPressPlay) {
      onPressPlay()
    }

    if (trackingMetadata) {
      handleContentProgressChange({
        pressedPlay: true,
        trackingMetadata
      })
    }
  }

  const onTimeUpdate = (event: Event) => {
    if (trackingMetadata) {
      const currentTime = (event.target as HTMLMediaElement)?.currentTime
      const duration = value.video?.asset?.data?.duration

      if (currentTime && duration && duration > 0) {
        const percentage = Math.round((currentTime / duration) * 100)
        handleContentProgressChange({
          percentage,
          trackingMetadata
        })
      }
    }
  }

  return (
    <div className="my-6" id={value?._key}>
      <MuxPlayer
        onPlay={onPlay}
        className="max-h-2xl w-full max-w-2xl"
        playbackId={value.video?.asset?.playbackId || value.video?.playbackId}
        streamType="on-demand"
        onTimeUpdate={onTimeUpdate}
      />
    </div>
  )
}
