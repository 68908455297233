import Tooltip from 'components/Tooltip/Tooltip'

import { ProgramBookmarkPartsFragment } from 'gql'

import { trackBookmarkAction } from 'utils/tracking/analytics'

import { ReactComponent as TrashIcon } from 'images/icon--trash.svg'

interface BookmarkNoteControlsProps {
  bookmark: ProgramBookmarkPartsFragment
  onDeleteBookmark?: () => void
  onEditBookmark: (editedBookmark: ProgramBookmarkPartsFragment) => void
  type?: string
}

const BookmarkNoteControls = ({
  bookmark,
  onDeleteBookmark,
  onEditBookmark,
  type
}: BookmarkNoteControlsProps) => {
  const deleteNoteBody = () => {
    onEditBookmark({ ...bookmark, noteBody: '' })
  }

  // Saved Items (Dashboard) deletes note body
  // BookmarkableWistiaPlayer (Video) delete bookmark
  const handleClick = () => {
    const isDashboard = type === 'Dashboard'
    trackBookmarkAction({ action: 'delete_note', component: 'hover' })
    return isDashboard ? deleteNoteBody() : onDeleteBookmark?.()
  }
  const tooltip = type === 'Dashboard' ? 'Delete Note' : 'Delete Highlight'

  const trashElement = (
    <div
      className="cursor-pointer opacity-0 transition-opacity duration-500 group-hover:opacity-100"
      onClick={handleClick}
    >
      <Tooltip tooltipBody={tooltip}>
        <TrashIcon width="10" height="12" />
      </Tooltip>
    </div>
  )

  return trashElement
}

export default BookmarkNoteControls
