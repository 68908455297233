/* eslint-disable camelcase */
import { Link } from 'react-router-dom'

import { ReactComponent as ReforgeLogo } from 'images/reforge-logo.svg'

import BookADemoForm from './BookADemoForm'

const FormHeader = () => {
  return (
    <div className="flex h-20 w-full items-center bg-black pl-7 font-sans text-xl uppercase tracking-vast text-white">
      <Link to="/">
        <ReforgeLogo className="h-5 w-full text-rb-white" />
      </Link>
    </div>
  )
}

const BookADemoPage = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <FormHeader />
      <BookADemoForm />
    </div>
  )
}

export default BookADemoPage
