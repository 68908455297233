import React from 'react'

import Button from 'components/Button'
import SeatAvatar from 'components/SeatAvatar'

import { MAX_WIDTH_TAILWIND_MD } from 'constants/breakpoints'

import {
  TeamMembersPageDocument,
  TeamMembersPageSubscriptionSeatFragment,
  useBulkUnassignSeatsMutation,
  useUnassignSeatMutation
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { displayErrorToast, displaySuccessToast } from 'utils/toastService'

import { ReactComponent as TrashIcon } from 'images/icon--manage-seats-trash.svg'

import { PanelContentProps } from '.'

export interface UnassignSeatPanelContentProps extends PanelContentProps {
  currentUserId: string
  onUnassignCtaClick: () => void
  onActionSuccess: () => void
  onActionError?: () => void
  seats: TeamMembersPageSubscriptionSeatFragment[]
  onCancelCtaClick: () => void
  subscriptionHasDomainName: boolean
}

export const UnassignSeatPanelContent = ({
  currentUserId,
  onUnassignCtaClick,
  onActionError,
  onActionSuccess,
  onCancelCtaClick,
  seats,
  subscriptionHasDomainName
}: UnassignSeatPanelContentProps) => {
  const seatsWithMembers = seats.filter((seat) => seat.subscriptionMember)
  const memberCount = seatsWithMembers.length

  const [unassignSeat, { loading: singleLoading }] = useUnassignSeatMutation()
  const [bulkUnassignSeats, { loading: bulkLoading }] = useBulkUnassignSeatsMutation()

  const isBulkUnassign = seats.length > 1
  const loading = isBulkUnassign ? bulkLoading : singleLoading

  const handleSubmit = async () => {
    if (isBulkUnassign) {
      const result = await bulkUnassignSeats({
        variables: {
          input: {
            seatIds: seats.map((seat) => seat.id)
          }
        },
        refetchQueries: [
          {
            query: TeamMembersPageDocument,
            variables: { id: currentUserId }
          }
        ]
      })

      if (
        result.data?.bulkUnassignSeats?.errors ||
        !result.data?.bulkUnassignSeats?.seats
      ) {
        const errorMessages = result.data?.bulkUnassignSeats?.errors
          ? result.data.bulkUnassignSeats.errors.map((error) => `• ${error}`)
          : ['• Unable to remove members']
        displayErrorToast({
          message: errorMessages,
          multiline: true
        })
        if (onActionError) {
          onActionError()
        }
      } else {
        const removedMembersMessages = seats
          .filter((seat) => seat.subscriptionMember)
          .map((seat) => {
            const fullName = seat.subscriptionMember?.user.fullName
            const email = seat.subscriptionMember?.user.email
            return fullName ? `• ${fullName} (${email})` : `• ${email}`
          })

        displaySuccessToast({
          message: [
            "You've successfully removed these members:",
            ...removedMembersMessages
          ],
          multiline: true
        })
        onActionSuccess()
        onUnassignCtaClick()
      }
    } else {
      const result = await unassignSeat({
        variables: {
          input: {
            id: seats[0].id
          }
        },
        refetchQueries: [
          {
            query: TeamMembersPageDocument,
            variables: { id: currentUserId }
          }
        ]
      })

      if (result.data?.unassignSeat?.errors || !result.data?.unassignSeat?.seat) {
        displayErrorToast({
          message: result.data?.unassignSeat?.errors
            ? result.data.unassignSeat.errors[0]
            : 'Unable to remove member'
        })
        if (onActionError) {
          onActionError()
        }
      } else {
        const removedMember = seats[0].subscriptionMember?.user
        const fullName = removedMember?.fullName
        const email = removedMember?.email

        let message = "You've successfully removed "
        if (fullName) {
          message += `${fullName} (${email})`
        } else {
          message += email
        }

        displaySuccessToast({ message })
        onActionSuccess()
        onUnassignCtaClick()
      }
    }
  }

  return (
    <>
      <div
        className={`${memberCount === 1 ? 'mt-4' : 'mt-0'} mb-2 md:mt-12 md:mb-6 font-semibold`}
      >
        Are you sure you want to remove{' '}
        {memberCount > 1 ? `these ${memberCount} members` : 'this member'}?
      </div>

      <p className="text-rb-destructive-100 mb-2 md:mb-4">
        Once you do, {memberCount > 1 ? 'these members' : 'this member'} will immediately
        lose access to Reforge.
      </p>

      <div className="bg-gray-100 px-4 py-2 md:p-4 rounded-md my-4 md:mb-6">
        <p className="mb-2 font-semibold">How does this affect my subscription?</p>
        <p className="my-0 md:my-4">
          {seats.length > 1 ? 'These seats' : 'This seat'} will be empty but{' '}
          {seats.length > 1 ? "they'll" : "it'll"} still be a part of your subscription.
          You can invite {seats.length > 1 ? 'new members' : 'a new member'} to{' '}
          {seats.length > 1 ? 'these seats' : 'this seat'} at any time.
        </p>
      </div>

      {seatsWithMembers.length > 1 && (
        <div className="max-h-60 overflow-y-auto mb-4 md:mb-6">
          {seatsWithMembers.map((seat, index) => (
            <div key={`${seat.id}-${index}`} className="flex items-center mb-3">
              <SeatAvatar
                noEmailTruncate={true}
                avatarSize={40}
                nameStyles="text-base ml-3 md:w-auto md:max-w-max"
                emailStyles="text-sm ml-3 md:w-auto md:max-w-max"
                memberName={seat.subscriptionMember?.user.fullName || ''}
                email={seat.subscriptionMember?.user.email || ''}
                avatarUrl={seat.subscriptionMember?.user.profile.avatarUrl || ''}
                isWorkEmail={!!seat.subscriptionMember?.user.workEmail}
                subscriptionHasDomainName={subscriptionHasDomainName}
              />
            </div>
          ))}
        </div>
      )}

      <Button
        data-test="panel-unassign-seat-button"
        data-testid="panel-unassign-seat-button"
        className="mb-2 mt-2 md:mb-4 md:mt-6 w-full"
        disabled={loading}
        isLoadingSpinner={loading}
        onClick={handleSubmit}
        size={
          useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_MD})`) ? 'small' : undefined
        }
      >
        <TrashIcon className="mr-2 h-4 w-4" />
        Remove {memberCount > 1 ? 'members' : 'member'}
      </Button>
      <Button
        className="w-full"
        variant="outline"
        onClick={onCancelCtaClick}
        size={
          useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_MD})`) ? 'small' : undefined
        }
      >
        Cancel
      </Button>
    </>
  )
}
