import { twMerge } from 'tailwind-merge'

import BadgeableAvatar from 'components/BadgeableAvatar'
import Tag from 'components/Tag/Tag'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { ArtifactAuthor, CclExpert } from 'gql'

interface ExpertPreviewProps {
  expert: ArtifactAuthor | CclExpert
  avatarImageClassName?: string
  expertNameClassName?: string
  containerClassName?: string
  isInstructor?: boolean
}

export const ExpertPreview = ({
  expert,
  expertNameClassName,
  containerClassName,
  isInstructor = false
}: ExpertPreviewProps) => {
  const getAvatarPhotoUrl = (): string => {
    if (expert.__typename === 'CclExpert') {
      return `${expert.avatarUrl}?auto=format&w=200` || ''
    } else {
      return expert.avatarPhoto?.imageUrl || ''
    }
  }

  const getAltText = (): string => {
    if (expert.__typename === 'CclExpert') {
      return expert.name || ''
    } else {
      return expert.avatarPhoto?.imageAlt || ''
    }
  }

  const getCompanyName = (): string | undefined => {
    if (expert.__typename === 'CclExpert') {
      return expert.companyName || undefined
    } else {
      return expert.company?.name || undefined
    }
  }

  const createProfile = () => {
    const isCollective =
      (expert.__typename === 'ArtifactAuthor' || expert.__typename === 'CclExpert') &&
      !!expert?.isCollectiveMember

    return {
      ...expert,
      kind: isCollective ? 'collective' : ''
    }
  }

  return (
    <div className={twMerge('flex flex-row gap-3.5', containerClassName)}>
      <BadgeableAvatar
        className="h-14 w-14"
        alt={getAltText()}
        avatarUrl={getAvatarPhotoUrl()}
        user={createProfile()}
      />
      <div
        className={twMerge(
          'flex flex-col justify-center flex-wrap max-w-[175px]',
          isInstructor && 'max-w-[255px]'
        )}
      >
        <div className="m-0 p-0 text-[13px] leading-[19.5px] sm:text-[14px] sm:leading-[21px] text-rb-gray-400">
          <span className={expertNameClassName}>{expert.name}</span>
          {isInstructor && (
            <Tag className="ml-2" variant="light">
              Instructor
            </Tag>
          )}
        </div>
        <RfParagraphMini color={ColorOptions.gray}>
          {expert.position}
          {getCompanyName() && ` at ${getCompanyName()}`}
        </RfParagraphMini>
      </div>
    </div>
  )
}

export default ExpertPreview
