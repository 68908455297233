import { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

import { OnboardingChecklistWrapper } from 'domains/Onboarding/Checklist/OnboardingChecklistWrapper'

import Button from 'components/Button'
import { ProgressRing } from 'components/ProgressRing/ProgressRing'
import { useRefetch } from 'components/RefetchProvider'

import { ONBOARDING_CHECKLIST } from 'constants/checklistNames'

import { useChecklistTasksQuery } from 'gql'

import useLocalStorage from 'hooks/useLocalStorage'

import { trackCtaClicked } from 'utils/tracking/analytics'

export const ONBOARDING_CHECKLIST_INDICATOR_ID = 'onboarding-checklist-indicator'

export const OnboardingChecklistIndicator = () => {
  const checklistName = 'onboarding'
  const buttonText = 'Get Started'
  const buttonRef = useRef<HTMLDivElement>(null)
  const [showChecklist, setShowChecklist] = useState(false)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [indicatorRef, setIndicatorRef] = useState<HTMLDivElement | null>(null)

  const [hasShownCompleted, setHasShownCompleted] = useLocalStorage(
    `has-shown-${ONBOARDING_CHECKLIST}-completed`,
    false
  )

  const { fetchChecklistTasks } = useRefetch()
  const { data, refetch } = useChecklistTasksQuery({
    variables: {
      checklistName
    }
  })

  if (fetchChecklistTasks) {
    refetch()
  }

  const { styles, attributes } = usePopper(indicatorRef, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }]
  })

  const checklistOverlayAnchor = document.getElementById('checklist-overlay-anchor')
  if (!checklistOverlayAnchor) return null

  const handleClick = () => {
    trackCtaClicked({
      cta_location: 'header',
      cta_type: 'button',
      text: buttonText.toLowerCase()
    })
    if (showChecklist && !hasShownCompleted && percentComplete >= 100) {
      setHasShownCompleted(true)
    }
    setShowChecklist(!showChecklist)
  }

  if (!data || data.checklistTasks.length < 1) {
    return null
  }

  const percentComplete = Math.round(
    (data.checklistTasks.filter((task) => task.completed).length /
      data.checklistTasks.length) *
      100
  )

  const handleCloseChecklist = () => {
    setShowChecklist(false)
    if (!hasShownCompleted && percentComplete >= 100) {
      setHasShownCompleted(true)
    }
  }

  const indicatorElement = document.getElementById(ONBOARDING_CHECKLIST_INDICATOR_ID)

  return (
    <div
      id={ONBOARDING_CHECKLIST_INDICATOR_ID}
      ref={setIndicatorRef}
      className="relative hidden xs:block"
    >
      <div ref={buttonRef}>
        <Button
          className="flex flex-row items-center group h-10 px-4 gap-2 text-sm"
          variant="text-only"
          onClick={handleClick}
        >
          <div className="inline-block">{buttonText}</div>
          <ProgressRing
            size={32}
            className="text-rb-gray-300"
            fontSize={10}
            trackWidth={3}
            progressPercent={percentComplete}
            strokeClassName="stroke-rb-info"
            completedClassName="bg-rb-blue-100"
          />
        </Button>
      </div>
      {showChecklist &&
        indicatorElement &&
        createPortal(
          <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            <OnboardingChecklistWrapper
              checklistTasks={data.checklistTasks}
              closeChecklist={handleCloseChecklist}
              openButtonRef={buttonRef}
            />
          </div>,
          checklistOverlayAnchor
        )}
    </div>
  )
}
