import { FC } from 'react'
import { useHistory } from 'react-router-dom'

import LiveCourseEnrollmentCard from 'domains/CourseDetail/LiveCourseEnrollmentCard'

import Button from 'components/Button'

import { CclCourseSessionPartsFragment, CourseDetailPartsFragment } from 'gql'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import { ReactComponent as InformationIcon } from 'images/p-info.svg'

interface LiveCourseEnrollmentSectionProps {
  loggedOut: boolean
  courseDetails?: CourseDetailPartsFragment
  upcomingCourseSessions?: CclCourseSessionPartsFragment[] | null
  startCourseCTADestination: string
  handleCtaClick: () => void
  setIsModalOpen: (isOpen: boolean) => void
  setShowEnrollmentCTAFooter: (showEnrollmentCTAFooter: boolean) => void
}
const LiveCourseEnrollmentSection: FC<LiveCourseEnrollmentSectionProps> = ({
  loggedOut,
  courseDetails,
  upcomingCourseSessions,
  startCourseCTADestination,
  setIsModalOpen,
  setShowEnrollmentCTAFooter
}) => {
  const history = useHistory()
  const onDemandCtaText = 'Get started for free'

  const onStartOnDemandClick = () => {
    const destination = `/courses/${courseDetails?.slug}/on-demand`

    trackNavigationClicked({
      destination: destination,
      location: 'course_details_page',
      location_type: 'details_page',
      location_id: courseDetails?.id,
      type: 'button',
      text: onDemandCtaText,
      related_identifiers: {
        ccl_course_id: courseDetails?.id,
        course_title: courseDetails?.title,
        course_sanity_id: courseDetails?.sourceId,
        content_mode: 'on-demand'
      }
    })

    history.push(destination)
  }
  return (
    <>
      <div className="flex flex-col md:flex-row xl:flex-col gap-6">
        <LiveCourseEnrollmentCard
          courseDetails={courseDetails}
          courseSessions={upcomingCourseSessions}
          loggedOut={loggedOut}
          setShowEnrollmentCTAFooter={setShowEnrollmentCTAFooter}
        />
        {courseDetails?.onDemand && (
          <div className="w-full max-w-[360px] h-min border rounded-xl p-6">
            <div className="font-semibold pb-1">Want to take this course on-demand?</div>
            <div className="text-sm pb-6">
              Discover 25+ on-demand courses and explore our entire library of Guides for
              a head start with the knowledge you need.
            </div>
            <Button
              variant="outline"
              fullWidth
              href={startCourseCTADestination}
              size="medium"
              className="px-8 py-2"
              onClick={onStartOnDemandClick}
            >
              {onDemandCtaText}
            </Button>
          </div>
        )}
      </div>
      {courseDetails?.onDemand && (
        <Button
          fullWidth
          variant="text-only"
          className="text-center py-0 text-rb-gray-300 max-w-[360px]"
          onClick={() => setIsModalOpen(true)}
        >
          <InformationIcon className="h-4 w-4 min-h-4 min-w-4 mr-2 fill-rb-gray-300" />
          Should I enroll in live or on demand?
        </Button>
      )}
    </>
  )
}
export default LiveCourseEnrollmentSection
