import { Link } from 'react-router-dom'

import FreePill from 'components/FreePill'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

import { SimilaritySearchBreadcrumb } from 'gql'

import { formatTitle } from '../utils'
import { ContentResultItemProps } from './SimilaritySearchResultItem'

const LessonItem = ({
  searchDocument,
  searchQuery,
  onResultClick,
  impressionRef,
  currentUser
}: ContentResultItemProps) => {
  const { title, highlight, thumbnail, breadCrumbs, url, isFree } = searchDocument
  return (
    <Link to={url} onClick={onResultClick}>
      {(!currentUser || currentUser?.is?.premember) && isFree && (
        <div className="absolute top-3 right-3 z-1">
          <FreePill />
        </div>
      )}
      <div
        ref={impressionRef}
        className="flex rounded border border-rb-gray-100 p-4 hover:shadow-card-hover xs:p-6"
      >
        <div className="relative flex-1">
          <RfParagraphLarge className="mb-0 font-medium">
            <span dangerouslySetInnerHTML={{ __html: formatTitle(searchQuery, title) }} />
          </RfParagraphLarge>
          <BreadCrumb crumbs={breadCrumbs} />
          <div className="mt-1">
            <span
              dangerouslySetInnerHTML={{
                __html: formatTitle(searchQuery, highlight, 190)
              }}
              className="overflow-wrap break-words line-clamp-4 text-rb-black"
            />
          </div>
        </div>
        <div className="relative hidden pl-6 tl:block lgr:hidden xl:block">
          {thumbnail && (
            <img src={thumbnail} className="h-fit max-h-28 object-right" alt="" />
          )}
        </div>
      </div>
    </Link>
  )
}

const titleizeText = (text: string) =>
  text
    .split(' ')
    .map((word) => (word.length ? `${word[0].toUpperCase()}${word.slice(1)}` : ''))
    .join(' ')

const BreadCrumb = ({ crumbs }: { crumbs: Array<SimilaritySearchBreadcrumb> }) => {
  if (crumbs.length === 0) return null

  return (
    <div className="mt-1 text-sm">
      {crumbs.map(({ label }, idx) => {
        return (
          <span key={`search-crumb-${idx}`}>
            {idx > 0 && ' > '}
            {titleizeText(label || '')}
          </span>
        )
      })}
    </div>
  )
}

export default LessonItem
