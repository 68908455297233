import pluralize from 'pluralize'

import { FacePile } from 'components/FacePile'

import { EngagementReportCourse } from 'gql'

interface TopCoursesProps {
  topCourses: EngagementReportCourse[]
}

export const TopCourses = ({ topCourses }: TopCoursesProps) => {
  if (topCourses.length === 0) {
    return null
  }
  const topCourse = topCourses[0]
  const otherTopCourses = topCourses.slice(1, topCourses.length)

  return (
    <div className="mt-2 mb-8">
      <h1 className="my-0 text-xl font-medium">What your team is learning</h1>
      <p className="text-sm">Top courses this billing period</p>

      <div className="justify-between lg:flex">
        <div className="mb-2 min-h-[135px] grow rounded border border-rb-gray-300 p-4 lg:border-rb-gray-250 lg:px-10">
          <div className="mb-4 inline-block rounded border border-rb-gray-250 bg-gradient-to-r from-[#FFA2CF80] to-[#DBFFFF80] p-2 text-xs font-medium uppercase leading-3 tracking-wider">
            Top Course
          </div>
          <div className="flex">
            <img className="mr-3 h-9 w-9" alt="" src={topCourse.imageUrl || ''} />
            <div>
              <p className="mb-1 text-sm font-semibold lg:text-2xl">{topCourse.name}</p>
              <div className="lg:-ml-11">
                <div className="flex items-center lg:mt-2 lg:pl-2">
                  <FacePile
                    users={topCourse.participants.slice(0, 4)}
                    className="-ml-1 h-4 w-4 lg:-ml-2 lg:h-6 lg:w-6"
                  />
                  {topCourse.participants.length > 0 && (
                    <p className="ml-2 mb-0 text-xs lg:text-base">
                      {topCourse.participants.length}{' '}
                      {pluralize('Participant', topCourse.participants.length)}
                    </p>
                  )}
                </div>
                <p className="mt-6 hidden text-sm lg:block">{topCourse.synopsis}</p>
              </div>
            </div>
          </div>
        </div>

        {!!otherTopCourses.length && (
          <div className="lg:ml-10 lg:flex lg:flex-col xl:ml-20">
            <p className="m-0 hidden text-xs font-medium uppercase tracking-wider lg:block">
              Other Top Courses
            </p>
            {otherTopCourses.map((course) => {
              return <OtherTopCoursesCard key={course.id} course={course} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}

const OtherTopCoursesCard = ({ course }: { course: EngagementReportCourse }) => {
  return (
    <div className="mb-2 min-h-[75px] rounded border border-rb-gray-250 p-4 lg:w-[255px] xl:w-[355px]">
      <div className="flex">
        <img className="mr-3 h-9 w-9" alt="" src={course.imageUrl || ''} />
        <div>
          <p className="mb-1 text-sm">{course.name}</p>
          <div className="flex items-center whitespace-nowrap">
            <FacePile
              users={course.participants.slice(0, 3)}
              className="-ml-1 h-4 w-4 grayscale"
            />
            {course.participants.length > 0 && (
              <p className="ml-2 mb-0 text-xs">
                {course.participants.length}{' '}
                {pluralize('Participant', course.participants.length)}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
