import dayjs from 'dayjs'
import pluralize from 'pluralize'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import { LessonIcon } from 'components/icons'
import ClockIcon from 'components/icons/ClockIcon'
import EventIcon from 'components/icons/EventIcon'
import MoneyIcon from 'components/icons/MoneyIcon'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import {
  CclCourseSessionPartsFragment,
  CourseDetailPartsFragment,
  PlanName,
  useCurrentUserMemberAccessQuery,
  useTrackCourseEnrollmentStartedMutation
} from 'gql'

import { getUniqueEventDays, getUniqueEventTimes } from 'utils/courseSessionUtils'
import { MONTH_ABBREV_FORMAT, formatDateRange } from 'utils/date'
import { formatMoney } from 'utils/moneyUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

interface LiveCourseEnrollmentCardProps {
  courseSessions?: CclCourseSessionPartsFragment[] | null
  courseDetails?: CourseDetailPartsFragment
  loggedOut?: boolean
  setShowEnrollmentCTAFooter: (showEnrollmentCTAFooter: boolean) => void
}

const LiveCourseEnrollmentCard = ({
  courseSessions,
  courseDetails,
  loggedOut = false,
  setShowEnrollmentCTAFooter
}: LiveCourseEnrollmentCardProps) => {
  const observedElementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowEnrollmentCTAFooter(false)
          } else {
            setShowEnrollmentCTAFooter(true)
          }
        })
      },
      { threshold: 0 }
    )

    const currentElement = observedElementRef.current
    if (currentElement) {
      observer.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement)
      }
    }
  }, [setShowEnrollmentCTAFooter])

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { data: currentUserData } = useCurrentUserMemberAccessQuery({
    skip: loggedOut
  })
  const currentUser = currentUserData?.currentUser

  let eventTimes: string[] = []
  let eventDays: string[] = []

  if (courseSessions && courseSessions.length > 0) {
    eventTimes = getUniqueEventTimes(courseSessions[0].events, userTimeZone) || []
    eventDays = getUniqueEventDays(courseSessions[0].events, userTimeZone) || []
  }

  const enrollableSessions =
    courseSessions?.filter((session) => session.isDuringEnrollmentPeriod) || []

  const userHasUseableCohortCredits =
    (currentUser?.hasUseableCohortCredits ||
      (currentUser?.is.trialing &&
        currentUser?.subscriptions?.active?.planName === PlanName.INDIVIDUAL)) &&
    enrollableSessions.length > 0

  const enrollableSession = enrollableSessions[0]

  const sessionDates = formatDateRange(
    enrollableSession?.startsAt,
    enrollableSession?.endsAt,
    MONTH_ABBREV_FORMAT
  )

  return (
    <div className="flex w-full max-w-[360px] flex-col rounded-xl p-8 bg-rb-orange-25">
      <RfParagraphLarge className="text-xl font-semibold pb-2">
        Take this course live
      </RfParagraphLarge>
      <RfParagraphMedium>
        Live courses provide study materials, live sessions with top tech experts,
        real-world case studies, and a community of peers.
      </RfParagraphMedium>
      <div className="flex flex-col pt-6">
        <div className="flex items-center pb-2">
          <LessonIcon className="w-4 h-4 mr-2.5" />
          {sessionDates} • {enrollableSession.duration}
        </div>
        {eventTimes?.length > 0 && (
          <div className="flex items-center pb-2">
            <ClockIcon className="w-4 h-4 mr-2.5" />
            {eventTimes?.length === 1 && eventTimes[0]}
            {eventTimes?.length > 1 && (
              <div>
                See event details
                <a className="font-semibold " href="#event-details">
                  {' '}
                  here
                </a>
              </div>
            )}
          </div>
        )}
        {eventDays?.length > 0 && (
          <div className="flex items-center pb-2">
            <EventIcon className="w-4 h-4 mr-2.5" />
            {eventDays.join(', ')}
          </div>
        )}
        {enrollableSession.price && (
          <div className="flex items-center pb-6">
            <MoneyIcon className="w-4 h-4 mr-2.5" />
            <div
              className={twMerge(
                'font-normal',
                userHasUseableCohortCredits && 'line-through'
              )}
            >
              {formatMoney(enrollableSession.price)}
            </div>
            {userHasUseableCohortCredits && <div className="font-normal">&nbsp;Free</div>}
          </div>
        )}
        <div ref={observedElementRef}>
          <EnrollmentCTA
            courseSessions={courseSessions}
            courseDetails={courseDetails}
            enrollableSession={enrollableSession}
          />
        </div>
      </div>
    </div>
  )
}

export default LiveCourseEnrollmentCard

interface EnrollmentCTAProps {
  enrollableSession: CclCourseSessionPartsFragment | null
  courseSessions?: CclCourseSessionPartsFragment[] | null
  courseDetails?: CourseDetailPartsFragment
}

const EnrollmentCTA = ({
  courseSessions,
  courseDetails,
  enrollableSession
}: EnrollmentCTAProps) => {
  const ctaText = 'Enroll Now'
  const history = useHistory()
  const [trackCourseEnrollmentStarted] = useTrackCourseEnrollmentStartedMutation()
  const onCtaClick = (e: any, courseSession: CclCourseSessionPartsFragment | null) => {
    if (!courseSession) {
      return
    }
    const cannotEnroll = !courseDetails?.slug || !courseSession.isDuringEnrollmentPeriod

    if (cannotEnroll) {
      e.preventDefault()
      history.push('/courses-marketplace-waitlist/course-full')
      return
    }

    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'course_details_page',
      text: ctaText.toLowerCase()
    })

    trackCourseEnrollmentStarted({
      variables: {
        input: {
          ctaLocation: 'course_details_page',
          courseSlug: courseDetails.slug,
          cclCourseSessionId: courseSession.id
        }
      }
    })

    const formattedStartDate = dayjs(courseSession.startsAt).format('YYYY-MM-DD')

    window.location.href = `/course-payment/${courseDetails.slug}/${formattedStartDate}/${enrollableSession?.id}`
  }

  return (
    <>
      <Button
        variant="fill"
        fullWidth
        size="medium"
        className="px-8 py-2 mb-6"
        onClick={(e) => onCtaClick(e, enrollableSession)}
      >
        {ctaText}
      </Button>
      {courseSessions && courseSessions[0].seatsLeft && (
        <div className="self-center">
          {courseSessions[0].seatsLeft === 1 && (
            <div>
              <span className="font-semibold">{courseSessions[0].seatsLeft}</span> seat
              left
            </div>
          )}
          {courseSessions[0].seatsLeft > 1 && (
            <div className="text-center">
              <span className="font-semibold">{courseSessions[0].seatsLeft}</span>{' '}
              {pluralize('seat', courseSessions[0].seatsLeft)} left
            </div>
          )}
        </div>
      )}
    </>
  )
}

export const EnrollmentCTAFooter = ({
  courseSessions,
  courseDetails
}: EnrollmentCTAProps) => {
  const enrollableSessions =
    courseSessions?.filter((session) => session.isDuringEnrollmentPeriod) || []

  const enrollableSession = enrollableSessions[0]

  return (
    <div className="fixed bottom-0 left-0 w-full rounded-xl p-4 bg-rb-orange-25 text-center z-1 tl:hidden">
      <EnrollmentCTA
        courseSessions={courseSessions}
        courseDetails={courseDetails}
        enrollableSession={enrollableSession}
      />
    </div>
  )
}
