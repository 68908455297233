import React from 'react'

import Button from 'components/Button'
import { PendingInviteSeatAvatar } from 'components/SeatAvatar/PendingInviteSeatAvatar'

import { MAX_WIDTH_TAILWIND_MD } from 'constants/breakpoints'

import {
  TeamMembersPageDocument,
  TeamMembersPageSubscriptionSeatFragment,
  useBulkResendSubscriptionInvitationsMutation
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { displayErrorToast, displaySuccessToast } from 'utils/toastService'

import { ReactComponent as EnvelopeIcon } from 'images/icon--envelope.svg'

import { PanelContentProps } from './index'

interface BulkResendInvitationsPanelContentProps extends PanelContentProps {
  currentUserId: string
  seats: TeamMembersPageSubscriptionSeatFragment[]
}

export const BulkResendInvitationsPanelContent = ({
  currentUserId,
  seats,
  onActionSuccess,
  onActionError,
  onCancelCtaClick
}: BulkResendInvitationsPanelContentProps) => {
  const [resendInvitations, { loading }] = useBulkResendSubscriptionInvitationsMutation()

  const handleResendInvitations = async () => {
    const result = await resendInvitations({
      variables: { input: { seatIds: seats.map((seat) => seat.id) } },
      refetchQueries: [
        {
          query: TeamMembersPageDocument,
          variables: { id: currentUserId }
        }
      ]
    })

    if (
      result.data?.bulkResendSubscriptionInvitations?.errors ||
      !result.data?.bulkResendSubscriptionInvitations?.success
    ) {
      const errorMessages = result.data?.bulkResendSubscriptionInvitations?.errors
        ? result.data.bulkResendSubscriptionInvitations.errors.map(
            (error) => `• ${error}`
          )
        : ['• Unable to resend invitations']
      displayErrorToast({
        message: errorMessages,
        multiline: true
      })
      if (onActionError) {
        onActionError()
      }
    } else {
      const resendMessages = seats.map((seat) => {
        const email = seat.subscriptionMember?.user.email
        return `• ${email}`
      })

      displaySuccessToast({
        message: ["You've successfully resent invitations to:", ...resendMessages],
        multiline: true
      })
      onActionSuccess()
    }
  }

  return (
    <>
      <div className="mt-0 mb-4 md:mt-12 md:mb-6 font-semibold text-lg ">
        {seats.length > 1 ? `Resend ${seats.length} invitations` : 'Resend invitation'}
      </div>

      <div className="max-h-60 overflow-y-auto mb-4 md:mb-6">
        {seats.map((seat, index) => (
          <div key={`${seat.id}-${index}`} className="flex items-center mb-3">
            <PendingInviteSeatAvatar
              avatarSize={40}
              className="h-fit"
              emailStyles="text-sm font-semibold text-[#757B74] md:w-auto md:max-w-max"
              email={
                seat.subscriptionMember?.user.workEmail ||
                seat.subscriptionMember?.user.email ||
                ''
              }
              noEmailTruncate={true}
            />
          </div>
        ))}
      </div>

      <Button
        className="mb-2 mt-2 md:mb-4 md:mt-6 w-full"
        dataTest="bulk-resend-invitations-cta"
        disabled={loading}
        isLoadingSpinner={loading}
        onClick={handleResendInvitations}
        size={
          useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_MD})`) ? 'small' : undefined
        }
      >
        <EnvelopeIcon className="mr-2 h-4 w-4" />
        {seats.length > 1 ? 'Resend invitations' : 'Resend invitation'}
      </Button>
      <Button
        className="w-full hover:bg-gray-50 hover:border-gray-500"
        variant="outline"
        onClick={onCancelCtaClick}
        size={
          useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_MD})`) ? 'small' : undefined
        }
      >
        Cancel
      </Button>
    </>
  )
}
