import { ApolloError } from '@apollo/client'
import { MouseEvent } from 'react'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import { CardVariants } from 'components/cards/Content/BaseCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS,
  COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
} from 'components/cards/Content/CourseCard'

import { CclCourseCourseCardPartsFragment, useCoursesListUserQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked } from 'utils/tracking/analytics'

interface CoursesProps {
  activeTab: string
  courses: CclCourseCourseCardPartsFragment[]
  isLoadingCourses: boolean
  isLoadingMore: boolean
  onShowMore: () => void
  pageLocation: string
  error?: ApolloError
  totalResultsCount: number
  additionalRelatedIdentifiers: any
}

const Courses = ({
  activeTab,
  courses,
  pageLocation,
  isLoadingCourses,
  isLoadingMore,
  error,
  totalResultsCount,
  onShowMore,
  additionalRelatedIdentifiers
}: CoursesProps) => {
  const { isLoggedIn, currentUser } = useCurrentUser()

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const {
    data: userData,
    loading: bookmarksLoading,
    error: bookmarksError
  } = useCoursesListUserQuery({ skip: !isLoggedIn })

  if (bookmarksError || error) {
    return <ErrorMessage error={error} />
  }

  if (bookmarksLoading || isLoadingCourses || !courses) {
    return <Loading className="min-h-[500px]" />
  }

  const courseBookmarks = userData?.currentUser?.courseBookmarks || []

  const onShowMoreClick = (e: MouseEvent<HTMLButtonElement>) => {
    const text = (e.target as HTMLButtonElement).innerText.toLowerCase()

    trackCtaClicked({
      text,
      destination: pageLocation,
      cta_type: 'button',
      cta_location: pageLocation,
      logged_in: isLoggedIn
    })
    onShowMore()
  }

  const destinationType = currentUser?.is.paidMember
    ? COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
    : COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS

  return (
    <>
      <div className="flex flex-col gap-4 mx-auto min-h-[500px]">
        {totalResultsCount === 0 && (
          <div className="text-center flex flex-col gap-4 py-16 max-w-[800px] mx-auto text-base leading-[1.6] text-rb-gray-300">
            <span className="font-semibold">No matches found</span>
            <span>
              It looks like we don’t have any courses that fit your current filters. Try
              adjusting your filters or explore our full catalog to discover courses that
              can help you achieve your goals.
            </span>
          </div>
        )}

        {courses.map((course, idx) => {
          const bookmark = courseBookmarks.find(
            (courseBookmark) => courseBookmark.cclCourse?.id === course.id
          )
          const nextSession = course.upcomingSessions?.[0] || null

          return (
            <CourseCard
              destinationType={destinationType}
              pageLocation={pageLocation}
              locationType="index"
              activeTab={activeTab}
              additionalRelatedIdentifiers={additionalRelatedIdentifiers}
              variant={CardVariants.Horizontal}
              courseSession={nextSession}
              course={course}
              key={course.id}
              hideBookmarkButton={!isLoggedIn}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              bookmark={bookmark}
              styleVariant="default"
              impressionTrackingProperties={{
                cclEntityId: course.id,
                location: `/courses ${activeTab}`,
                sectionImpressionIndex: idx
              }}
              onCoursesIndexPage={true}
            />
          )
        })}
      </div>

      {courses.length < totalResultsCount && (
        <Button
          color="teal"
          onClick={onShowMoreClick}
          size="small"
          className="mx-auto mt-8"
          isLoadingSpinner={isLoadingMore}
        >
          View more courses
        </Button>
      )}

      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={userData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={!userData?.currentUser?.hasSeenCollectionsPrompt}
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction="bookmark"
      />
    </>
  )
}

export default Courses
