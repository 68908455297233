import clsx from 'clsx'

export interface AvailableSeatAvatarProps {
  avatarSize: number
  avatarWrapperSize: number
  inSidePanel?: boolean
  className?: string
}

export function AvailableSeatAvatar({
  avatarSize = 48,
  avatarWrapperSize = avatarSize,
  inSidePanel,
  className
}: AvailableSeatAvatarProps) {
  return (
    <div
      className={`flex h-[${avatarWrapperSize}px] items-center ${className || ''}`}
      data-testid="seat-avatar"
    >
      <span
        className={clsx(
          'relative -ml-1.5 inline-block first:ml-0',
          'badgeable__avatar-wrapper',
          'mr-4'
        )}
        style={{ width: `${avatarWrapperSize}px`, height: `${avatarWrapperSize}px` }}
      >
        <div
          className={'mr-4 rounded-full bg-white ring ring-rb-gray-100'}
          style={{ width: `${avatarSize}px`, height: `${avatarSize}px` }}
        />
      </span>
      <div className="flex flex-col text-left">
        <div
          data-test="pending-invite-seat-text"
          className={`m-0 font-normal italic text-rb-gray-300 ${
            inSidePanel ? 'text-xl' : 'text-sm'
          }`}
        >
          Available Seat
        </div>
      </div>
    </div>
  )
}
