import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { debounce } from 'throttle-debounce'

import { ContentPercentComplete } from 'components/cards/Content/utils'

import { MergedContent, useTrackServerEventMutation } from 'gql'

import useConsumeProgress from 'hooks/useConsumeProgress'

import { CourseRelatedIdentifierProperties } from 'utils/contentTrackingUtils'
import { getAnonymousId } from 'utils/tracking/segment'

function useTrackProgress({
  currentConsumeProgress,
  contentBlock,
  courseRelatedIdentifiers
}: {
  currentConsumeProgress: number
  contentBlock: { id: string; header?: string | null | undefined } | undefined | null
  courseRelatedIdentifiers?: CourseRelatedIdentifierProperties
}) {
  const location = useLocation()

  const consumeTextProgress = useRef(0)
  const oneHundredPercentTextTracked = useRef(false)

  const [trackServerEvent] = useTrackServerEventMutation()

  const trackContentProgressRecorded = useCallback(
    (progressPercentage: number) => {
      trackServerEvent({
        variables: {
          input: {
            anonymousId: getAnonymousId(),
            event: 'Content Progress Recorded - Server',
            properties: {
              consumption_format: 'text',
              content_id: contentBlock?.id,
              content_mode: courseRelatedIdentifiers?.content_mode,
              content_name: contentBlock?.header,
              content_type: 'flex_content',
              path: location.pathname,
              progress_percentage: progressPercentage / 100,
              content_ccl_local_id: contentBlock?.id,
              related_identifiers: {
                ccl_course_local_id: courseRelatedIdentifiers?.ccl_course_id,
                ccl_course_title: courseRelatedIdentifiers?.course_title,
                course_session_id: courseRelatedIdentifiers?.course_session_id
              }
            }
          }
        }
      })
    },
    [
      contentBlock?.header,
      contentBlock?.id,
      courseRelatedIdentifiers?.ccl_course_id,
      courseRelatedIdentifiers?.content_mode,
      courseRelatedIdentifiers?.course_session_id,
      courseRelatedIdentifiers?.course_title,
      location.pathname,
      trackServerEvent
    ]
  )

  useEffect(() => {
    if (currentConsumeProgress > consumeTextProgress.current) {
      consumeTextProgress.current = currentConsumeProgress
    }

    const furthestConsumeProgress = Math.max(
      currentConsumeProgress,
      consumeTextProgress.current
    )

    if (furthestConsumeProgress === ContentPercentComplete.FirstQuartile) {
      trackContentProgressRecorded(ContentPercentComplete.FirstQuartile)
    }

    if (furthestConsumeProgress === ContentPercentComplete.SecondQuartile) {
      trackContentProgressRecorded(ContentPercentComplete.SecondQuartile)
    }

    if (furthestConsumeProgress === ContentPercentComplete.ThirdQuartile) {
      trackContentProgressRecorded(ContentPercentComplete.ThirdQuartile)
    }

    if (furthestConsumeProgress === ContentPercentComplete.NinetyPercent) {
      trackContentProgressRecorded(ContentPercentComplete.NinetyPercent)
    }

    if (
      furthestConsumeProgress === ContentPercentComplete.OneHundredPercent &&
      !oneHundredPercentTextTracked.current
    ) {
      trackContentProgressRecorded(ContentPercentComplete.OneHundredPercent)
      oneHundredPercentTextTracked.current = true
    }
  }, [currentConsumeProgress, trackContentProgressRecorded])
}

interface UnitProgressConsumerProps {
  contentBlock?: MergedContent | null
  courseRelatedIdentifiers?: CourseRelatedIdentifierProperties
}

export const CourseBlockContentBlockProgressConsumer = ({
  contentBlock,
  courseRelatedIdentifiers
}: UnitProgressConsumerProps) => {
  const [consumeProgress, setConsumeProgress] = useState(0)
  const [completeTracked, setCompleteTracked] = useState(false)

  const currentConsumeProgress = useConsumeProgress({
    id: 'content-block-container',
    scrollableId: 'page'
  })

  useTrackProgress({
    currentConsumeProgress,
    contentBlock,
    courseRelatedIdentifiers
  })

  const [trackServerEvent] = useTrackServerEventMutation()

  const TrackContentCompleted = (
    contentBlock: MergedContent,
    progressChangeType: string
  ) => {
    if (contentBlock?.id) {
      trackServerEvent({
        variables: {
          input: {
            anonymousId: getAnonymousId(),
            event: 'Content Completed - Server',
            properties: {
              content_type: 'flex_content',
              content_title: contentBlock?.header,
              ccl_entity_id: contentBlock?.id,
              completion_type: progressChangeType,
              related_identifiers: courseRelatedIdentifiers
            }
          }
        }
      })
    }
  }

  const debouncedTrackContentCompleted = useRef(
    debounce(750, TrackContentCompleted)
  ).current

  const handleConsumeProgressChange = (
    consumeProgressVal: number,
    progressChangeType: string
  ) => {
    if (consumeProgressVal > consumeProgress) {
      setConsumeProgress(consumeProgressVal)
    }

    if (consumeProgressVal > ContentPercentComplete.NinetyPercent) {
      if (!completeTracked) {
        debouncedTrackContentCompleted(contentBlock)
        debouncedTrackContentCompleted(contentBlock, progressChangeType)
        setCompleteTracked(true)
      }
    }
  }

  useEffect(() => {
    handleConsumeProgressChange(currentConsumeProgress, 'scroll')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConsumeProgress, contentBlock])

  return <></>
}

export default CourseBlockContentBlockProgressConsumer
