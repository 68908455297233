import { PendingInviteSeatAvatar } from 'components/SeatAvatar/PendingInviteSeatAvatar'

import { SubscriptionMember } from 'gql'

export interface PendingInviteProps {
  subscriptionMember: SubscriptionMember
}

export const PendingInvite = ({ subscriptionMember }: PendingInviteProps) => {
  return (
    <div className="p-3">
      <PendingInviteSeatAvatar
        email={subscriptionMember.email}
        avatarSize={24}
        emailStyles="text-xs"
        labelStyles="text-xs"
      />
    </div>
  )
}
