import { useParams } from 'react-router-dom'

import OldCourseDetailsPage from 'pages/CourseDetailsPage/OldCourseDetailsPage'

import FaqSection from 'domains/Sanity/FAQSection'
import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import { useLoggedOutCourseDetailPageQuery } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import CourseDetailsPage from './CourseDetailsPage'

interface LoggedOutCourseDetailsPageProps {
  courseDetailsForSeo?: GenericPageType | null
}

const LoggedOutCourseDetailsPage = ({
  courseDetailsForSeo
}: LoggedOutCourseDetailsPageProps) => {
  const { showCourseLandingPageUpdates } = useFeatureFlags()

  const { courseSlug } = useParams<{ courseSlug: string }>()

  const { data, loading } = useLoggedOutCourseDetailPageQuery({
    variables: {
      slug: courseSlug
    }
  })

  if (loading) return <Loading />

  return (
    <MarketingLayout page={courseDetailsForSeo as GenericPageType}>
      {showCourseLandingPageUpdates ? (
        <CourseDetailsPage courseSlug={courseSlug} />
      ) : (
        <OldCourseDetailsPage courseSlug={courseSlug} loggedOut />
      )}

      {/* Faq section */}
      {data?.marketingCourseDetails?.course?.courseFaq && (
        <FaqSection faqs={data.marketingCourseDetails.course.courseFaq} />
      )}
    </MarketingLayout>
  )
}

export default LoggedOutCourseDetailsPage
