import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { debounce } from 'throttle-debounce'

import useListeningModeProgressListener from 'components/ListeningMode/useListeningModeProgressListener'
import { ContentPercentComplete } from 'components/cards/Content/utils'

import {
  CourseDetailDocument,
  CourseOnDemandDetailDocument,
  ProgressConsumerCoursePartsFragment,
  ProgressConsumerCourseSessionPartsFragment,
  ProgressConsumerUnitPartsFragment,
  ProgressConsumerUserPartsFragment,
  useCreateUserCmsActivityMutation,
  useTrackServerEventMutation
} from 'gql'

import useConsumeProgress from 'hooks/useConsumeProgress'

import { useContentMode } from 'utils/contentTrackingUtils'
import { getAnonymousId } from 'utils/tracking/segment'

interface UnitProgressConsumerProps {
  user?: ProgressConsumerUserPartsFragment | null
  unit?: ProgressConsumerUnitPartsFragment | null
  courseSession?: ProgressConsumerCourseSessionPartsFragment | null
  course?: ProgressConsumerCoursePartsFragment | null
  preview?: boolean
}

export const UnitProgressConsumer = ({
  user,
  unit,
  courseSession,
  course,
  preview
}: UnitProgressConsumerProps) => {
  const location = useLocation()

  const [completeRecorded, setCompleteRecorded] = useState(false)

  const consumeTextProgress = useRef(0)
  const oneHundredPercentTextTracked = useRef(false)

  const firstQuartileAudioTracked = useRef(false)
  const secondQuartileAudioTracked = useRef(false)
  const thirdQuartileAudioTracked = useRef(false)
  const ninetyPercentAudioTracked = useRef(false)
  const oneHundredPercentAudioTracked = useRef(false)

  const currentConsumeProgress = useConsumeProgress({
    id: 'guide-content-container',
    scrollableId: 'page'
  })

  const contentMode = useContentMode({
    courseSession
  })

  const refetchQueriesAfterCmsActivity = []
  if (course?.slug && courseSession?.id && !preview) {
    refetchQueriesAfterCmsActivity.push({
      query: CourseDetailDocument,
      variables: {
        slug: course.slug,
        courseSessionId: courseSession.id
      }
    })
  }

  if (course?.slug && !courseSession?.id && !preview) {
    refetchQueriesAfterCmsActivity.push({
      query: CourseOnDemandDetailDocument,
      variables: {
        slug: course.slug
      }
    })
  }

  const [trackServerEvent] = useTrackServerEventMutation()

  const [createUserCmsActivity] = useCreateUserCmsActivityMutation({
    refetchQueries: refetchQueriesAfterCmsActivity,
    awaitRefetchQueries: true
  })

  const trackContentProgressRecorded = useCallback(
    (progressPercentage: number, consumptionFormat: string) => {
      trackServerEvent({
        variables: {
          input: {
            anonymousId: getAnonymousId(),
            event: 'Content Progress Recorded - Server',
            properties: {
              consumption_format: consumptionFormat,
              content_id: unit?.id,
              content_mode: contentMode,
              content_name: unit?.title,
              content_type: 'guide',
              path: location.pathname,
              progress_percentage: progressPercentage / 100,
              content_ccl_local_id: unit?.guide?.id,
              content_sanity_id: unit?.id,
              related_identifiers: {
                ccl_course_local_id: course?.id,
                ccl_course_title: course?.title,
                course_session_id: courseSession?.id
              }
            }
          }
        }
      })
    },
    [
      contentMode,
      course?.id,
      course?.title,
      courseSession?.id,
      location.pathname,
      trackServerEvent,
      unit?.guide?.id,
      unit?.id,
      unit?.title
    ]
  )

  const debouncedCreateUserCmsActivity = useRef(
    debounce(750, createUserCmsActivity)
  ).current

  const trackCompletionCmsActivity = useCallback(
    (consumeProgressVal: number) => {
      // track completion progress only when the user is logged in and the guide was not completed before
      if (user && consumeProgressVal > ContentPercentComplete.NinetyPercent) {
        if (!completeRecorded) {
          debouncedCreateUserCmsActivity({
            variables: {
              input: {
                sanityId: unit?.id,
                action: 'completed',
                contentType: 'Guide',
                courseId: course?.id,
                courseSessionId: courseSession?.id
              }
            }
          })
          setCompleteRecorded(true)
        }
      }
    },
    [
      completeRecorded,
      course?.id,
      courseSession?.id,
      debouncedCreateUserCmsActivity,
      unit?.id,
      user
    ]
  )

  const handleConsumeTextProgressChange = useCallback(
    (consumeProgressVal: number) => {
      if (consumeProgressVal > consumeTextProgress.current) {
        consumeTextProgress.current = consumeProgressVal
      }

      const furthestConsumeProgress = Math.max(
        consumeProgressVal,
        consumeTextProgress.current
      )

      if (furthestConsumeProgress === ContentPercentComplete.FirstQuartile) {
        trackContentProgressRecorded(ContentPercentComplete.FirstQuartile, 'text')
      }

      if (furthestConsumeProgress === ContentPercentComplete.SecondQuartile) {
        trackContentProgressRecorded(ContentPercentComplete.SecondQuartile, 'text')
      }

      if (furthestConsumeProgress === ContentPercentComplete.ThirdQuartile) {
        trackContentProgressRecorded(ContentPercentComplete.ThirdQuartile, 'text')
      }

      if (furthestConsumeProgress === ContentPercentComplete.NinetyPercent) {
        trackContentProgressRecorded(ContentPercentComplete.NinetyPercent, 'text')
      }

      if (
        furthestConsumeProgress === ContentPercentComplete.OneHundredPercent &&
        !oneHundredPercentTextTracked.current
      ) {
        trackContentProgressRecorded(ContentPercentComplete.OneHundredPercent, 'text')
        oneHundredPercentTextTracked.current = true
      }

      trackCompletionCmsActivity(consumeProgressVal)
    },
    [trackCompletionCmsActivity, trackContentProgressRecorded]
  )

  const handleConsumeAudioProgressChange = (consumeProgressVal: number) => {
    // Check ranges of progress because users can skip around

    // 0 - 24
    if (consumeProgressVal < ContentPercentComplete.FirstQuartile) {
      return
    }

    // 25 - 49
    if (
      consumeProgressVal >= ContentPercentComplete.FirstQuartile &&
      consumeProgressVal < ContentPercentComplete.SecondQuartile &&
      !firstQuartileAudioTracked.current
    ) {
      trackContentProgressRecorded(ContentPercentComplete.FirstQuartile, 'audio')
      firstQuartileAudioTracked.current = true
    }

    // 50 - 74
    if (
      consumeProgressVal >= ContentPercentComplete.SecondQuartile &&
      consumeProgressVal < ContentPercentComplete.ThirdQuartile &&
      !secondQuartileAudioTracked.current
    ) {
      trackContentProgressRecorded(ContentPercentComplete.SecondQuartile, 'audio')
      secondQuartileAudioTracked.current = true
    }

    // 75 - 89
    if (
      consumeProgressVal >= ContentPercentComplete.ThirdQuartile &&
      consumeProgressVal < ContentPercentComplete.NinetyPercent &&
      !thirdQuartileAudioTracked.current
    ) {
      trackContentProgressRecorded(ContentPercentComplete.ThirdQuartile, 'audio')
      thirdQuartileAudioTracked.current = true
    }

    // 90 - 99
    if (
      consumeProgressVal >= ContentPercentComplete.NinetyPercent &&
      consumeProgressVal < ContentPercentComplete.OneHundredPercent &&
      !ninetyPercentAudioTracked.current
    ) {
      trackContentProgressRecorded(ContentPercentComplete.NinetyPercent, 'audio')
      ninetyPercentAudioTracked.current = true
    }

    // 100
    if (
      consumeProgressVal === ContentPercentComplete.OneHundredPercent &&
      !oneHundredPercentAudioTracked.current
    ) {
      trackContentProgressRecorded(ContentPercentComplete.OneHundredPercent, 'audio')
      oneHundredPercentAudioTracked.current = true
    }

    trackCompletionCmsActivity(consumeProgressVal)
  }

  useListeningModeProgressListener({
    onTrackProgressed: (progressPercent) => {
      handleConsumeAudioProgressChange(progressPercent)
    }
  })

  useEffect(() => {
    handleConsumeTextProgressChange(currentConsumeProgress)
  }, [currentConsumeProgress, user, unit, handleConsumeTextProgressChange])

  useEffect(() => {
    if (unit?.id && user) {
      createUserCmsActivity({
        variables: {
          input: {
            sanityId: unit?.id,
            action: 'viewed',
            contentType: 'Guide',
            courseId: course?.id,
            courseSessionId: courseSession?.id
          }
        }
      })
    }
  }, [unit?.id, createUserCmsActivity, user, course?.id, courseSession?.id])

  return <></>
}

export default UnitProgressConsumer
