import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  COURSE_FILTER_ALL_COURSES,
  COURSE_FILTER_LIVE,
  COURSE_FILTER_ON_DEMAND
} from 'pages/CoursesPage/CoursesFilters'
import CustomPageTitle from 'pages/CustomPageTitle'

import { ErrorMessage } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'
import Loading from 'components/Loading'
import { usePage } from 'components/PageHeader/usePage'
import Tabs from 'components/Tabs'

import { COURSES_MY_COURSES_PATH, COURSES_PATH } from 'constants/courses'

import {
  CclCourseCourseCardPartsFragment,
  CoursesListFilters,
  useCoursesListFiltersQuery,
  useCoursesListLazyQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import {
  trackFilterApplied,
  trackNavigationClicked,
  trackSortSelected
} from 'utils/tracking/analytics'

import CourseListFiltersAndSort from './CourseListFiltersAndSort'
import Courses from './Courses'
import { COURSE_SORT_TYPE_POPULARITY } from './CoursesListSort'
import MyCoursesTab from './MyCoursesTab'
import {
  COURSES_TAB_MY_COURSES,
  FilterType,
  combinedFilterCount,
  getValidSelectedFilters
} from './helpers'

const PER_PAGE = 10

export const CoursesList = () => {
  const history = useHistory()
  const { isLoggedIn, currentUser } = useCurrentUser()
  const { setPageTitle, setPageAboveHeader, setPageSubtitle } = usePage()
  const location = useLocation()

  const { ref16263TopicBasedBrowsing } = useFeatureFlags()

  const locationSearchString = location.search
  const searchParams = useMemo(
    () => new URLSearchParams(locationSearchString),
    [locationSearchString]
  )
  const [tabSearchParam, setTabSearchParam] = useState(() => {
    const tabParam = searchParams.get('tab')
    if (tabParam === COURSE_FILTER_LIVE || tabParam === COURSE_FILTER_ON_DEMAND) {
      return COURSE_FILTER_ALL_COURSES
    }
    return tabParam || COURSE_FILTER_ALL_COURSES
  })

  const selectedFilters = useMemo(() => {
    return {
      topics: searchParams.get('topics')?.split(',').filter(Boolean) || [],
      functions: searchParams.get('functions')?.split(',').filter(Boolean) || [],
      onlyLive: searchParams.get('onlyLive') === 'true'
    }
  }, [searchParams])

  const [selectedSortType, setSelectedSortType] = useState(COURSE_SORT_TYPE_POPULARITY)

  const [filterTypeJustApplied, setFilterTypeJustApplied] = useState<null | FilterType>(
    null
  )

  useEffect(() => {
    if (!isLoggedIn || currentUser?.is.freeUser) {
      setPageTitle(<CustomPageTitle title="Courses" />)
    }

    if (isLoggedIn) {
      if (ref16263TopicBasedBrowsing) {
        setPageAboveHeader(
          <div className="mb-8">
            <BaseBreadcrumbs
              backIcon={
                <ChevronLeftIcon width={16} height={16} className="mr-4 fill-rb-black" />
              }
              oneLevel
              onClick={() => {
                trackNavigationClicked({
                  type: 'hyperlink text',
                  text: 'back to explore',
                  location: location.pathname,
                  destination: '/explore'
                })
              }}
              breadcrumbPages={[{ title: 'Explore', path: '/explore' }]}
            />
          </div>
        )
      }

      setPageSubtitle('Gain the expertise to unlock step-change career growth')
    }

    return () => {
      setPageTitle(null)
      setPageAboveHeader(null)
      setPageSubtitle(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoggedIn, location.pathname, ref16263TopicBasedBrowsing])

  const setFilterQueryParam = useCallback(
    (type: 'topics' | 'functions' | 'onlyLive', filters: string[]) => {
      const filterSlugs = filters.join(',')

      if (filterSlugs.length === 0) {
        searchParams.delete(type)
      } else if (type === 'onlyLive') {
        searchParams.set(type, 'true')
      } else {
        searchParams.set(type, filterSlugs)
      }

      history.replace({
        pathname: window.location.pathname,
        search: searchParams.toString()
      })

      setFilterTypeJustApplied(type)
    },
    [searchParams, history]
  )

  const { data, loading, error } = useCoursesListFiltersQuery({
    onCompleted: (data) => {
      const validSelectedTopics = getValidSelectedFilters(
        data.cclCoursesListFilters ||
          ({ topics: [], functions: [] } as unknown as CoursesListFilters),
        selectedFilters,
        'topics'
      )

      const validSelectedFunctions = getValidSelectedFilters(
        data.cclCoursesListFilters ||
          ({ topics: [], functions: [] } as unknown as CoursesListFilters),
        selectedFilters,
        'functions'
      )

      setFilterQueryParam('topics', validSelectedTopics)
      setFilterQueryParam('functions', validSelectedFunctions)
      setFilterQueryParam('onlyLive', selectedFilters.onlyLive ? ['onlyLive'] : [])
    }
  })

  const topicFilters = data?.cclCoursesListFilters?.topics || []
  const functionFilters = data?.cclCoursesListFilters?.functions || []

  const pageLocation = useMemo(() => {
    if (isLoggedIn) {
      if (currentUser?.is?.member) {
        return '/courses (paid)'
      }
      return '/courses (free)'
    }
    return '/courses (anon)'
  }, [isLoggedIn, currentUser])

  const handleFilterSelection = useCallback(
    (type: 'topics' | 'functions' | 'onlyLive') => (filters: string[]) => {
      setFilterQueryParam(type, filters)
    },
    [setFilterQueryParam]
  )

  const handleSelectSortType = useCallback(
    (sortType: string) => {
      if (sortType === selectedSortType) return

      trackSortSelected({
        location: pageLocation,
        sort_by: sortType.toLowerCase()
      })
      setSelectedSortType(sortType)
    },
    [selectedSortType, pageLocation]
  )

  const [isLoadingCourses, setIsLoadingCourses] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [courses, setCourses] = useState<CclCourseCourseCardPartsFragment[]>([])
  const [totalResultsCount, setTotalResultsCount] = useState(0)
  const [totalLiveCount, setTotalLiveCount] = useState(0)

  const [fetchCourses, { error: coursesError }] = useCoursesListLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      // tracking filterApplied here so can track the fetched result counts with this track
      if (filterTypeJustApplied && data) {
        trackFilterApplied({
          filter_location: pageLocation,
          filter_changed: filterTypeJustApplied,
          filters: selectedFilters,
          active_filter_count: combinedFilterCount(selectedFilters),
          results_count: data?.cclCourses?.totalCount || 0
        })

        setFilterTypeJustApplied(null)
      }
    }
  })

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingCourses(true)
      const response = await fetchCourses({
        variables: {
          topicSlugs: selectedFilters.topics,
          functionSlugs: selectedFilters.functions,
          availability: selectedFilters.onlyLive
            ? [COURSE_FILTER_LIVE]
            : [COURSE_FILTER_LIVE, COURSE_FILTER_ON_DEMAND],
          sortOrder: selectedSortType,
          start: 0,
          limit: PER_PAGE
        }
      })
      setCourses(response.data?.cclCourses?.courses || [])
      setTotalResultsCount(response.data?.cclCourses?.totalCount || 0)
      setTotalLiveCount(response.data?.cclCourses?.totalLiveCount || 0)
      setIsLoadingCourses(false)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, selectedSortType])

  const onShowMoreClick = async () => {
    setIsLoadingMore(true)
    const response = await fetchCourses({
      variables: {
        topicSlugs: selectedFilters.topics,
        functionSlugs: selectedFilters.functions,
        availability: selectedFilters.onlyLive
          ? [COURSE_FILTER_LIVE]
          : [COURSE_FILTER_LIVE, COURSE_FILTER_ON_DEMAND],
        sortOrder: selectedSortType,
        start: courses.length,
        limit: PER_PAGE
      },
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache'
    })

    setCourses([...courses, ...(response.data?.cclCourses?.courses || [])])
    setIsLoadingMore(false)
  }

  const additionalRelatedIdentifiers = {
    is_filtered_reference: combinedFilterCount(selectedFilters) > 0,
    is_empty_index_results: false
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (loading) {
    return <Loading />
  }

  const tabs = isLoggedIn
    ? [
        {
          label: 'All courses',
          onClick: () => {
            if (tabSearchParam === 'my-courses') {
              setTabSearchParam(COURSE_FILTER_ALL_COURSES)
              history.push(COURSES_PATH)
            }

            trackNavigationClicked({
              text: 'all courses',
              destination: COURSES_PATH,
              type: 'pill',
              location: '/courses'
            })
          },
          isActive: tabSearchParam === COURSE_FILTER_ALL_COURSES
        },
        {
          label: 'My courses',
          onClick: () => {
            if (tabSearchParam === 'all') {
              setTabSearchParam('my-courses')
              history.push(COURSES_MY_COURSES_PATH)
            }

            trackNavigationClicked({
              text: 'my courses',
              destination: COURSES_MY_COURSES_PATH,
              type: 'pill',
              location: '/courses'
            })
          },
          isActive: tabSearchParam === COURSES_TAB_MY_COURSES
        }
      ]
    : []

  const handleClearAll = () => {
    setFilterQueryParam('topics', [])
    setFilterQueryParam('functions', [])
    setFilterQueryParam('onlyLive', [])
  }

  return (
    <div className="flex w-full flex-col">
      {isLoggedIn ? (
        <div className="flex items-center space-x-4">
          <Tabs tabs={tabs} />
        </div>
      ) : (
        totalLiveCount > 0 && (
          <>
            <h3 className="text-rb-gray-400 text-[28px] leading-[1.2] font-medium mb-2 font-sans">
              All Reforge courses
            </h3>
            <p className="text-rb-gray-400 text-xl leading-[1.4] font-medium mb-0">
              Learn from experts on-demand, at your own pace, and on your schedule
            </p>
          </>
        )
      )}

      <CourseListFiltersAndSort
        topicFilters={topicFilters}
        functionFilters={functionFilters}
        selectedFilters={selectedFilters}
        handleFilterSelection={handleFilterSelection}
        tabSearchParam={tabSearchParam}
        totalResultsCount={totalResultsCount}
        selectedSortType={selectedSortType}
        handleSelectSortType={handleSelectSortType}
        handleClearAll={handleClearAll}
        isLoadingCourses={isLoadingCourses}
        totalLiveCount={totalLiveCount}
      />

      {tabSearchParam === COURSES_TAB_MY_COURSES ? (
        <MyCoursesTab />
      ) : (
        <Courses
          activeTab={tabSearchParam}
          courses={courses}
          isLoadingCourses={isLoadingCourses}
          isLoadingMore={isLoadingMore}
          onShowMore={onShowMoreClick}
          totalResultsCount={totalResultsCount}
          error={coursesError}
          pageLocation={pageLocation}
          additionalRelatedIdentifiers={additionalRelatedIdentifiers}
        />
      )}
    </div>
  )
}

export default CoursesList
