import {
  ArrowRightIcon,
  ColorCircleCheckIcon,
  EmptyCircleRadioIcon
} from 'components/icons'

export interface ChecklistRowProps {
  title: string
  subtitle?: string
  isCompleted?: boolean
  action: () => void
}

// , checklistTasks: ChecklistTask[]
export const ChecklistRow = ({
  title,
  subtitle,
  isCompleted,
  action
}: ChecklistRowProps) => {
  return (
    <button
      className="flex flex-row items-center gap-3 w-full p-4 hover:bg-rb-gray-50"
      onClick={action}
    >
      {isCompleted ? (
        <ColorCircleCheckIcon width={32} height={32} className="text-rb-info" />
      ) : (
        <EmptyCircleRadioIcon
          className="w-8 h-8"
          circleClassName="stroke-1 fill-rb-gray-20"
        />
      )}
      <div className="text-left">
        <div>{title}</div>
        <div className="text-xs text-rb-gray-300">{subtitle}</div>
      </div>
      <ArrowRightIcon className="ml-auto" />
    </button>
  )
}
