import dayjs from 'dayjs'
import { Link, useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { MIN_WIDTH_TAILWIND_LG } from 'constants/breakpoints'

import {
  CclCourseSessionPartsFragment,
  CourseDetailPartsFragment,
  PlanName,
  useCurrentUserMemberAccessQuery,
  useTrackCourseEnrollmentStartedMutation
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { MONTH_ABBREV_DATE_FORMAT, formatInTimezone } from 'utils/date'
import { onEnterKeyPress } from 'utils/keyboard'
import { trackCtaClicked } from 'utils/tracking/generated/events'

import CourseDetailUpcomingSession from './CourseDetailUpcomingSession'

interface CourseDetailsEnrollProps {
  courseSessions?: CclCourseSessionPartsFragment[] | null
  courseDetails: CourseDetailPartsFragment
  loggedOut?: boolean
  preview?: boolean
  showTitle?: boolean
}

const CourseDetailsEnroll = ({
  courseSessions,
  courseDetails,
  loggedOut = false,
  preview = false,
  showTitle = false
}: CourseDetailsEnrollProps) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { data: currentUserData } = useCurrentUserMemberAccessQuery({
    skip: loggedOut
  })
  const currentUser = currentUserData?.currentUser

  const history = useHistory()
  const [trackCourseEnrollmentStarted] = useTrackCourseEnrollmentStartedMutation()
  const isLargeScreen = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG})`)

  const onCtaClick = (e: any, courseSession: CclCourseSessionPartsFragment) => {
    if (!courseDetails?.slug || !courseSession.isDuringEnrollmentPeriod) {
      e.preventDefault()
      history.push('/courses-marketplace-waitlist/course-full')
    }

    if (!courseDetails?.slug || !courseSession.isDuringEnrollmentPeriod) return

    trackCourseEnrollmentStarted({
      variables: {
        input: {
          ctaLocation: 'course_details_page',
          courseSlug: courseDetails.slug,
          cclCourseSessionId: courseSession.id
        }
      }
    })

    const formattedStartDate = dayjs(courseSession.startsAt).format('YYYY-MM-DD')

    window.location.href = `/course-payment/${courseDetails.slug}/${formattedStartDate}`
  }

  const trackGetNotifiedClick = () => {
    trackCtaClicked({
      text: 'Get Notified',
      cta_location: 'course_details_page',
      cta_type: 'link',
      related_identifiers: {
        ccl_course_id: courseDetails?.id,
        course_sanity_id: courseDetails?.sourceId,
        course_title: courseDetails?.title
      }
    })
  }

  const onGetNotifiedClick = () => {
    trackGetNotifiedClick()
    history.push('/courses-marketplace-waitlist')
  }

  const enrollableSessions =
    courseSessions?.filter((session) => session.isDuringEnrollmentPeriod) || []

  const userHasUseableCohortCredits =
    (currentUser?.hasUseableCohortCredits ||
      (currentUser?.is.trialing &&
        currentUser?.subscriptions?.active?.planName === PlanName.INDIVIDUAL)) &&
    enrollableSessions.length > 0

  const enrollableSession = enrollableSessions[0]

  if (preview && !isLargeScreen) {
    return (
      <div className="absolute z-[500]">
        <div className="fixed bottom-0 left-0 flex h-[122px] w-full items-center justify-center bg-rb-black p-6">
          <div className="flex w-full max-w-[625px] flex-col justify-between gap-4 md:flex-row md:gap-8">
            <div className="flex flex-col items-start justify-center">
              <RfParagraphLarge color={ColorOptions.white}>
                {courseDetails.title}
              </RfParagraphLarge>
              {enrollableSession?.startsAt && (
                <RfParagraphSmall
                  color={ColorOptions.green100}
                  className="hidden md:block"
                >
                  Course begins{' '}
                  {formatInTimezone(
                    enrollableSession?.startsAt,
                    userTimeZone,
                    MONTH_ABBREV_DATE_FORMAT
                  )}
                </RfParagraphSmall>
              )}
            </div>
            <div className="flex flex-col items-center justify-center">
              {enrollableSession?.price && (
                <RfParagraphMediumSemiBold className="mb-2 hidden text-rb-white md:block">
                  ${enrollableSession.price}
                </RfParagraphMediumSemiBold>
              )}

              {enrollableSession && (
                <Button
                  variant="fill"
                  color="teal"
                  size="small"
                  className="w-full px-8 py-2 md:w-fit"
                  onClick={(e) => onCtaClick(e, enrollableSession)}
                >
                  Enroll Now
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (preview && isLargeScreen) {
    return (
      <div className="flex w-full max-w-[360px] flex-col gap-6 rounded-xl border bg-rb-black p-8 @container">
        <RfParagraphLarge>
          <span className="text-[24px] text-rb-white">{courseDetails.title}</span>
        </RfParagraphLarge>

        <div className="flex items-center">
          {enrollableSession?.price && (
            <RfParagraphMedium>
              <span
                className={twMerge(
                  'font-normal text-rb-white',
                  userHasUseableCohortCredits && 'line-through'
                )}
              >
                ${enrollableSession?.price}
              </span>
              <span className={twMerge('font-normal text-rb-white')}>
                {userHasUseableCohortCredits && ' Free'}
              </span>
            </RfParagraphMedium>
          )}

          {enrollableSession?.duration && (
            <>
              {enrollableSession?.price && (
                <span className="px-2 text-base text-rb-white"> · </span>
              )}
              <RfParagraphMedium>
                <span className="font-normal text-rb-white">
                  {enrollableSession.duration.replace('s', '')} course
                </span>
              </RfParagraphMedium>
            </>
          )}
        </div>

        <div className="flex flex-col gap-4">
          {enrollableSession && (
            <div
              className={twMerge(
                'flex w-full items-center justify-center rounded bg-rb-white px-4 py-2 hover:bg-rb-gray-50 hover:no-underline active:bg-rb-gray-100',
                enrollableSession ? 'cursor-pointer' : 'cursor-default'
              )}
              tabIndex={0}
              role="link"
              onKeyDown={onEnterKeyPress((e) => onCtaClick(e, enrollableSession))}
              onClick={(e) => onCtaClick(e, enrollableSession)}
            >
              <RfParagraphSmall className="text-center">
                {enrollableSession ? (
                  <>
                    {enrollableSession.isEnrollmentFull ? (
                      <span className="text-rb-gray-300">Waitlist</span>
                    ) : (
                      <span>Enroll</span>
                    )}
                  </>
                ) : (
                  <span className="text-rb-gray-300">Coming soon</span>
                )}
              </RfParagraphSmall>
            </div>
          )}

          {enrollableSessions?.length === 0 && (
            <Link
              className="flex w-full items-center justify-between rounded bg-rb-white px-4 py-2 hover:bg-rb-gray-50 hover:no-underline active:bg-rb-gray-100"
              to="/courses-marketplace-waitlist"
              onClick={trackGetNotifiedClick}
            >
              <RfParagraphMedium>
                <span className="text-rb-gray-200">None upcoming</span>
              </RfParagraphMedium>

              <RfParagraphSmall>Get notified</RfParagraphSmall>
            </Link>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="flex w-full max-w-[360px] flex-col gap-6 rounded-xl border p-8 @container">
      <RfParagraphLarge>
        <span className="text-[20px] font-semibold">Take this course live</span>
      </RfParagraphLarge>
      <RfParagraphMedium>
        <span className="text-[16px]">
          Get guidance in live sessions with top tech experts, real-world case studies,
          accountability, and chances to connect with a community of peers.
        </span>
      </RfParagraphMedium>

      <div className="flex flex-col gap-4">
        {enrollableSessions.length > 0 && (
          <CourseDetailUpcomingSession
            session={enrollableSessions[0]}
            courseDetails={courseDetails}
            showEvents={false}
            showTitle={showTitle}
            buttonColor="teal"
            pillPosition="top"
          />
        )}

        {enrollableSessions?.length === 0 && (
          <div
            className={twMerge(
              'mr-6 flex w-full max-w-[360px] flex-col gap-6 rounded-xl border p-6 bg-rb-white border-rb-gray-100 text-center'
            )}
          >
            <div>
              <RfParagraphMedium>
                <span className="text-rb-gray-200">No upcoming courses</span>
              </RfParagraphMedium>
            </div>
            <div>
              <Button
                className="!mt-auto normal-case"
                variant="fill"
                color="teal"
                fullWidth
                size="small"
                onClick={onGetNotifiedClick}
              >
                Get notified
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="flex w-full flex-col items-center justify-center gap-2">
        <div className="flex text-center">
          {enrollableSession?.price && (
            <RfParagraphMedium>
              <span
                className={twMerge(
                  'font-normal',
                  userHasUseableCohortCredits && 'line-through'
                )}
              >
                ${enrollableSession?.price}
              </span>
              <span className={twMerge('font-normal')}>
                {userHasUseableCohortCredits && ' Free'}
              </span>
            </RfParagraphMedium>
          )}

          {enrollableSession?.duration && (
            <>
              {enrollableSession?.price && <span className="px-2 text-base"> · </span>}
              <RfParagraphMedium>
                <span className="font-normal">{enrollableSession.duration}</span>
              </RfParagraphMedium>
            </>
          )}
        </div>

        {userHasUseableCohortCredits && (
          <RfParagraphSmall>
            <span className="text-xs text-rb-success-200">
              Free with your Reforge Live Course Pass
            </span>
          </RfParagraphSmall>
        )}
      </div>
    </div>
  )
}

export default CourseDetailsEnroll
