import { useParams } from 'react-router-dom'

import { useCohortViewerAccessQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { showCohortDashboardForCohortSeason } from 'utils/cohortUtils'

import useBlockCurrentCohortPage from './useBlockCurrentCohortPage'

export const useCohortViewerPage = () => {
  const { slug } = useParams<{ slug: string }>()

  const currentUser = useAssertCurrentUser()
  const { data, loading } = useCohortViewerAccessQuery({
    variables: { slug }
  })

  const shouldRedirect = useBlockCurrentCohortPage({
    slug,
    currentUser
  })

  return {
    slug,
    data,
    loading,
    shouldRedirect,
    showCohortDashboardForCohortSeason: showCohortDashboardForCohortSeason(
      data?.cohort.season
    )
  }
}
