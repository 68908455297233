import { Redirect, Route, Switch, useParams } from 'react-router-dom'

import ArtifactPage from 'pages/Artifacts/ArtifactPage'
import CourseBlockContentBlockLandingPage from 'pages/CourseBlockContentBlockLandingPage/CourseBlockContentBlockLandingPage'
import { UnitLandingPage } from 'pages/UnitLandingPage'

import CourseOnDemandPageDashboardHeader from 'domains/CourseOnDemand/CourseOnDemandPageDashboardHeader'

import { Loading } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'

import { COURSES_ON_DEMAND_PATH, COURSES_PATH } from 'constants/courses'

import { useCourseOnDemandDetailQuery } from 'gql'

import { CourseTrackingIdentifiersContextProvider } from 'hooks/useCourseTrackingIdentifiersContext'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useScrollToHashAfterDataIsLoaded } from 'hooks/useScrollToHashAfterDataIsLoaded'

import {
  CourseRelatedIdentifierProperties,
  useContentMode
} from 'utils/contentTrackingUtils'
import { CourseIdentifiersType } from 'utils/courseUtils'

import CourseOnDemandPageDashboard from './CourseOnDemandPageDashboard'

export const COURSE_DASHBOARD_HEADER_ID = 'course-dashboard-header'

export type CoursePageParams = {
  courseBlockIndex?: string
  contentBlockSlug?: string
  courseSlug: string
  courseSessionId: string
  guideSlug?: string
  artifactSlug?: string
  sanityEventId?: string
}

export const CourseOnDemandPage = () => {
  const {
    courseBlockIndex,
    contentBlockSlug,
    courseSlug,
    guideSlug,
    artifactSlug,
    sanityEventId
  } = useParams<CoursePageParams>()
  const { showOnDemandMarketplaceCourses, userIdentified, showCourseLandingPageUpdates } =
    useFeatureFlags()

  const { isLoggedIn } = useCurrentUser()

  const { loading, data } = useCourseOnDemandDetailQuery({
    variables: { slug: courseSlug }
  })

  // Future TODO: replace courseIdentifiers with using CourseTrackingIdentifiersContextProvider
  // Will likely need to update everywhere ArtifactsPage uses courseIdentifiers
  const courseIdentifiers: CourseIdentifiersType = {
    course: data?.course,
    cclCourse: data?.cclCourse
  }

  const contentMode = useContentMode(courseIdentifiers)

  const findCurrentCourseBlockHash = () => {
    if (window.location.hash) return window.location.hash

    return undefined
  }

  const currentCourseBlockHash = findCurrentCourseBlockHash()

  useScrollToHashAfterDataIsLoaded(!!data && !loading, currentCourseBlockHash)

  if (!userIdentified || loading) {
    return <Loading />
  }

  if (!courseSlug || !showOnDemandMarketplaceCourses) {
    return (
      <Redirect
        to={showCourseLandingPageUpdates ? COURSES_PATH : COURSES_ON_DEMAND_PATH}
      />
    )
  }

  if (!data?.course) {
    return (
      <Redirect
        to={showCourseLandingPageUpdates ? COURSES_PATH : COURSES_ON_DEMAND_PATH}
      />
    )
  }

  if (!data?.course.isAvailableOnDemand) {
    return <Redirect to={`/courses/${courseSlug}/details`} />
  }

  const { course } = data

  const trackingRelatedIdentifiers: CourseRelatedIdentifierProperties = {
    course_id: courseIdentifiers?.course?.id,
    ccl_course_id: courseIdentifiers?.cclCourse?.id,
    content_mode: contentMode,
    course_title: courseIdentifiers?.cclCourse?.title,
    ccl_course_static_id: courseIdentifiers?.cclCourse?.staticId
  }

  return (
    <CourseTrackingIdentifiersContextProvider value={{ cclCourse: data.cclCourse }}>
      <div className="relative flex h-full flex-col" id="course-page">
        <Switch>
          <Route
            exact
            path={[`/courses/${courseSlug}/on-demand`]}
            render={() =>
              isLoggedIn && (
                <BaseBreadcrumbs
                  oneLevel
                  className="mb-5"
                  breadcrumbPages={[
                    {
                      title: 'all courses',
                      path: showCourseLandingPageUpdates
                        ? COURSES_PATH
                        : COURSES_ON_DEMAND_PATH
                    }
                  ]}
                  backIcon={
                    <ChevronLeftIcon
                      width={16}
                      height={16}
                      className="mr-4 fill-rb-black"
                    />
                  }
                />
              )
            }
          />
        </Switch>
        {!guideSlug && !contentBlockSlug && !artifactSlug && course.creators && (
          <CourseOnDemandPageDashboardHeader
            showBackButton={!!sanityEventId}
            creators={course.creators}
            course={course}
            courseBlocks={course.courseBlocks}
            id={COURSE_DASHBOARD_HEADER_ID}
            cmsProgram={data.course?.cmsProgram}
          />
        )}
        <Switch>
          <Route
            exact
            path={[`/courses/${courseSlug}/on-demand`]}
            render={() => <CourseOnDemandPageDashboard slug={courseSlug} />}
          />
          <Route
            exact
            key={guideSlug}
            path={[`/courses/${courseSlug}/on-demand/guides/${guideSlug}`]}
            render={() => (
              <UnitLandingPage
                unitSlug={guideSlug}
                course={course}
                backToCourseLabel="Course Home"
                showCourseBreadcrumb
                backToCourseLink={`/courses/${courseSlug}/on-demand`}
              />
            )}
          />
          <Route
            exact
            path={[
              `/courses/${courseSlug}/on-demand/course-blocks/${courseBlockIndex}/content-blocks/${contentBlockSlug}`
            ]}
            render={() => (
              <CourseBlockContentBlockLandingPage
                backToCourseLink={`/courses/${courseSlug}/on-demand#${courseBlockIndex}-${contentBlockSlug}`}
                backToCourseLabel="Course Home"
                cclCourseBlock={
                  courseBlockIndex
                    ? data?.cclCourse?.blocks?.[Number(courseBlockIndex)]
                    : null
                }
                courseRelatedIdentifiers={trackingRelatedIdentifiers}
                contentBlockSlug={contentBlockSlug}
              />
            )}
          />
          <Route
            exact
            path={`/courses/${courseSlug}/on-demand/artifacts/${artifactSlug}`}
            render={() => (
              <ArtifactPage
                artifactSlug={artifactSlug}
                courseIdentifiers={courseIdentifiers}
              />
            )}
          />
        </Switch>
      </div>
    </CourseTrackingIdentifiersContextProvider>
  )
}
