import { useEffect, useState } from 'react'

import LoggedInHomepage from 'pages/HomePage/LoggedInHomepage/LoggedInHomepage'

import { Loading } from 'components'

import { useActivateSubscriptionMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { FIRST_TIME_USER_PARAM } from './LoggedInHomepage/HomePageLoader'

const HomePageContainer = () => {
  const { currentUser } = useCurrentUser()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const mtoken = urlParams.get('mtoken')
  const stoken = urlParams.get('stoken')
  const [activateSubscriptionMutation] = useActivateSubscriptionMutation()
  const [waitForSubscriptionActivation, setWaitForSubscriptionActivation] =
    useState(!!mtoken)

  useEffect(() => {
    async function run() {
      // mtoken is used to add a user to a team.
      if (currentUser && mtoken) {
        const result = await activateSubscriptionMutation({
          variables: { input: { mtoken } }
        })
        if (!result.data?.activateSubscription?.errors) {
          window.location.assign(`/?${FIRST_TIME_USER_PARAM}=true`)
        } else {
          setWaitForSubscriptionActivation(false)
        }
      }
      if (currentUser && stoken) {
        const result = await activateSubscriptionMutation({
          variables: { input: { stoken } }
        })
        if (!result.data?.activateSubscription?.errors) {
          window.location.assign('/?joined-team=true')
        } else {
          setWaitForSubscriptionActivation(false)
        }
      }
    }
    run()
  }, [activateSubscriptionMutation, mtoken, stoken, currentUser])

  if (!currentUser || waitForSubscriptionActivation) return <Loading />

  return <LoggedInHomepage />
}

export default HomePageContainer
