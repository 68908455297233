import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { MAX_WIDTH_TAILWIND_TL, MIN_WIDTH_TAILWIND_2XL } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { ReactComponent as ReforgeWordmark } from 'images/reforge-logo-black.svg'
import { ReactComponent as ReforgeSymbol } from 'images/reforge-logo-symbol-black.svg'

const Logo = () => {
  const mobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)
  const largeDesktopView = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_2XL})`)
  const showWordmarkLogo = mobileView || largeDesktopView

  return (
    <Link
      to="/"
      className={twMerge(
        'mt-1 ml-0 flex h-6 w-auto min-w-[2rem] items-center text-base font-semibold text-rb-black/85 hover:text-rb-black/85 hover:no-underline'
      )}
    >
      {showWordmarkLogo ? (
        <ReforgeWordmark className="h-[20px]" />
      ) : (
        <ReforgeSymbol className="h-[24px] pl-2" />
      )}
    </Link>
  )
}

export default Logo
