import { twMerge } from 'tailwind-merge'

const MoneyIcon = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('h-[12px] w-[12px]', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_1323_30406)">
      <path
        d="M0.5 8C0.5 9.98912 1.29018 11.8968 2.6967 13.3033C4.10322 14.7098 6.01088 15.5 8 15.5C9.98912 15.5 11.8968 14.7098 13.3033 13.3033C14.7098 11.8968 15.5 9.98912 15.5 8C15.5 6.01088 14.7098 4.10322 13.3033 2.6967C11.8968 1.29018 9.98912 0.5 8 0.5C6.01088 0.5 4.10322 1.29018 2.6967 2.6967C1.29018 4.10322 0.5 6.01088 0.5 8Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9.5C6.5 9.79667 6.58797 10.0867 6.7528 10.3334C6.91762 10.58 7.15189 10.7723 7.42598 10.8858C7.70007 10.9994 8.00166 11.0291 8.29264 10.9712C8.58361 10.9133 8.85088 10.7704 9.06066 10.5607C9.27044 10.3509 9.4133 10.0836 9.47118 9.79264C9.52906 9.50167 9.49935 9.20007 9.38582 8.92598C9.27229 8.65189 9.08003 8.41762 8.83336 8.2528C8.58668 8.08797 8.29667 8 8 8C7.70333 8 7.41332 7.91203 7.16665 7.74721C6.91997 7.58238 6.72771 7.34811 6.61418 7.07403C6.50065 6.79994 6.47094 6.49834 6.52882 6.20737C6.5867 5.91639 6.72956 5.64912 6.93934 5.43934C7.14912 5.22956 7.41639 5.0867 7.70737 5.02882C7.99834 4.97094 8.29994 5.00065 8.57403 5.11418C8.84811 5.22771 9.08238 5.41997 9.24721 5.66665C9.41203 5.91332 9.5 6.20333 9.5 6.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4V5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11V12"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1323_30406">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default MoneyIcon
