import { cn } from '@/lib/utils'

import CourseFeatureGrid from 'domains/Course/CourseFeatureGrid'
import LogoSection from 'domains/CoursesSeo/LogoSection'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'
import SeoPageHero from 'domains/Seo/SeoPageHero'

import { Loading } from 'components'

import { useCoursesIndexSeoQuery } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import CoursesList from './CoursesList'
import CoursesListOld from './CoursesListOld'
import UpcomingLiveCoursesCarousel from './UpcomingLiveCoursesCarousel'

export const CoursesIndexPage = () => {
  const { data, loading } = useCoursesIndexSeoQuery()
  const { showCourseLandingPageUpdates } = useFeatureFlags()

  if (!data || loading) {
    return <Loading />
  }

  return (
    <MarketingLayout
      page={data.marketingCoursesIndex.page}
      scrollToTop={false}
      transparentNav={showCourseLandingPageUpdates}
      contentClassName={cn(
        'flex flex-col pb-16 relative',
        !showCourseLandingPageUpdates && 'space-y-8'
      )}
    >
      {showCourseLandingPageUpdates && (
        <div className="absolute -z-1 left-0 w-full h-[1000px] bg-[linear-gradient(to_top,transparent_0%,rgba(255,162,207,0.50)_28%,rgba(255,234,222,0.50)_88%)]" />
      )}

      {showCourseLandingPageUpdates ? (
        <section
          className={cn(
            'px-4 md:px-[4vw] overflow-hidden text-center pt-[120px] md:pt-[136px]'
          )}
        >
          <h1 className="text-rb-gray-400 text-4xl lg:text-[48px] leading-[1] lg:leading-[1.2] font-normal tracking-[-0.72px] lg:tracking-[0.48px] mb-2">
            Online courses taught by tech experts
          </h1>
          <h2 className="text-rb-gray-400 text-[20px] leading-[1.4] font-medium mb-0">
            Reforge courses provide the product, growth, and marketing expertise you need
            to unlock step-change growth in your career.
          </h2>
        </section>
      ) : (
        <SeoPageHero
          title="Online courses taught by tech experts"
          subtitle="Reforge courses provide the product, growth, and marketing expertise you need to unlock step-change growth in your career."
          ctaText="Explore all courses"
        />
      )}

      <div
        className={cn('px-4 pt-4 md:px-[4vw]', showCourseLandingPageUpdates && 'pt-0')}
      >
        <div
          className={cn(
            'max-w-[1150px] mx-auto',
            showCourseLandingPageUpdates && 'max-w-[1280px]'
          )}
        >
          {showCourseLandingPageUpdates && (
            <>
              <UpcomingLiveCoursesCarousel pageLocation="logged_out_courses_index" />
              {/* move this as a margin/padding once all the page is built */}
              <div className="h-[60px]" />
              <CoursesList />
            </>
          )}

          {!showCourseLandingPageUpdates && <CoursesListOld />}

          <LogoSection />
          <CourseFeatureGrid
            className="md:p-10 p-4 bg-rb-orange-25 mt-8"
            tableClassName="md:m-10 m-4 mr-0 md:mr-0"
            theme="black"
          />
        </div>
      </div>
    </MarketingLayout>
  )
}

export default CoursesIndexPage
