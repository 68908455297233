import Image from 'domains/Sanity/Image'
import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import MarketingHero from 'domains/Sanity/marketingSite/MarketingHero'
import TextSwitcher from 'domains/Sanity/marketingSite/TextSwitcher'

import { ClientLogos } from 'components/MarketingSite/ClientLogos/ClientLogos'
import ColumnsOfCheckboxesAndContent from 'components/MarketingSite/ColumnsOfCheckboxesAndContent'
import PreFooterCTA from 'components/MarketingSite/PreFooterCTA'
import ThreeColumnSectionWithIcons from 'components/MarketingSite/ThreeColumnSectionWithIcons'

import BetaTagIcon from 'images/BetaTag.png'
import QuoteIcon from 'images/marketing-round-icons/Quote.png'
import LearningIcon from 'images/marketing-round-icons/teams/learning-icon.png'
import LibraryIcon from 'images/marketing-round-icons/teams/library-icon.png'
import CoursesIcon from 'images/marketing-round-icons/teams/live-courses-icon.png'
import CollectionsImg from 'images/r4t-collections.png'
import WhiteBlueGradient from 'images/white-blue-gradient.jpg'
import YellowOrangeGradientHeader from 'images/yellow-orange-gradient-header.jpg'

export const TeamsMarketingPageContent = () => {
  return (
    <>
      <section>
        <MarketingHero
          headerText={
            <>
              How{' '}
              <TextSwitcher
                intervalMilliseconds={4000}
                items={['Product', 'Growth', 'Marketing']}
                className="underline decoration-2"
              />
              {' teams level up'}
            </>
          }
          description={
            'Reforge enables teams to operate more efficiently and drive powerful business outcomes.'
          }
          bgImage={{
            url: YellowOrangeGradientHeader,
            alt: 'gradient background image'
          }}
          contentClassName="max-w-[100%]"
          displayDemoForm
        />
      </section>
      <section className="mt-[120px] bg-rb-background-beige px-[16px] pb-[6.6vmax] md:px-[4vw]">
        <ClientLogos />
        <ThreeColumnSectionWithIcons
          headerText="How Team Membership Works"
          description="Reforge for Teams membership offers vetted on-demand learning and facilitated experiences to help your team develop their skills and keep up with the pace of change."
          cta={
            <ButtonMarketingCTA
              href="/courses"
              variant="text"
              underline={true}
              className="my-[15px] text-xl font-semibold"
            >
              Learn More ↗
            </ButtonMarketingCTA>
          }
          icon1={{
            url: LibraryIcon,
            alt: 'library icon'
          }}
          column1Header="Access the Content Library"
          column1Text="Team membership equips your whole team with access to all available on-demand Reforge programs, content, tools, frameworks, processes, etc."
          icon2={{
            url: CoursesIcon,
            alt: 'live courses icon'
          }}
          column2Header="Dive Deep with Live Courses"
          column2Text="Live courses are led by some of the top executives in tech. They drive accountability and create a community for top performers on your team."
          icon3={{
            url: LearningIcon,
            alt: 'learning icon'
          }}
          column3Header="Customize Your Team's Learning"
          column3Text="Curate custom learning tracks for your team, set team learning goals, and track progress. Also, you can invest in private workshops custom-built for your team."
        />
      </section>
      <section className="relative px-[16px] py-[6.6vmax] md:px-[4vw]">
        <Image
          alt="background gradient"
          src={WhiteBlueGradient}
          fill
          className="-z-1 object-cover opacity-50"
        />
        <div>
          <h2 className="mb-[40px] font-normal leading-[5px] tracking-tight fluid-text-6xl">
            How Teams Use Reforge
          </h2>
          <ColumnsOfCheckboxesAndContent
            column1Content={
              <>
                <span className="font-semibold">Onboarding</span> new hires into any level
                product or growth role
              </>
            }
            column2Content={
              <>
                Driving business impact through{' '}
                <span className="font-semibold">professional development</span>
              </>
            }
            column3Content={
              <>
                <span className="font-semibold">Engaging, retaining, and promoting</span>{' '}
                top talent
              </>
            }
            column4Content={
              <>
                Developing a <span className="font-semibold">shared language</span> for
                cross-functional teams
              </>
            }
          />
          <p className="pb-[50px] text-center fluid-text-lg md:pb-[10px] lg:pb-[50px]">
            Read more on how other companies are using their Reforge Team Membership.
          </p>
          <ButtonMarketingCTA
            href="/case-studies"
            variant="outline"
            className="mx-auto my-[20px] md:my-0"
          >
            Case Studies ↗
          </ButtonMarketingCTA>
        </div>
      </section>
      <section className="relative bg-white px-[16px] py-[6.6vmax] md:px-[4vw]">
        <h2 className="mb-[40px] font-normal leading-[5px] tracking-tight fluid-text-6xl">
          Team Features
        </h2>
        <p className="pb-[80px] !leading-tight tracking-tight fluid-text-lg">
          The Reforge for Teams experience will feel familiar to seasoned Reforge members,
          with a few improvements.
        </p>
        <div className="grid auto-rows-min grid-cols-1 gap-x-[40px] md:grid-cols-2 md:grid-rows-1 lg:gap-x-[80px] lg:px-[20px]">
          <Image
            alt="Reforge for teams collections example image"
            src={CollectionsImg}
            width={2414}
            height={1905}
            className="mx-auto mb-[40px] h-auto w-full max-w-[340px] md:max-w-none"
          />
          <ul className="pl-0 lg:pr-[40px]">
            <li className="mb-[50px] list-none">
              <h3 className="my-[10px] font-semibold tracking-tight fluid-text-xl">
                Collaborative Learning Features
              </h3>
              <p className="!leading-tight tracking-tight fluid-text-lg">
                Make learning collaborative with same-team tagging and commenting to flag
                key insights that you come across.
              </p>
            </li>
            <li className="mb-[50px] list-none">
              <h3 className="my-[10px] font-semibold tracking-tight fluid-text-xl">
                Curate Custom Collections
              </h3>
              <p className="!leading-tight tracking-tight fluid-text-lg">
                Build custom learning paths for you and your team by organizing Reforge
                content into collections.
              </p>
            </li>
            <li className="mb-[50px] list-none">
              <Image
                alt="beta tag"
                src={BetaTagIcon}
                width={140}
                height={56}
                className="h-[25px] w-auto"
              />
              <h3 className="my-[10px] font-semibold tracking-tight fluid-text-xl">
                Engagement Reporting
              </h3>
              <p className="!leading-tight tracking-tight fluid-text-lg">
                Track your team&apos;s learning progress with engagement reporting.
              </p>
            </li>
            <li className="list-none">
              <ButtonMarketingCTA href="/teams/get-in-touch" variant="outline">
                Request a Demo ↗
              </ButtonMarketingCTA>
            </li>
          </ul>
        </div>
        <div className="my-[50px] mx-auto border-y px-[20px] text-center md:w-3/5">
          <div className="flex justify-center">
            <Image
              alt="quote icon"
              src={QuoteIcon}
              width={330}
              height={330}
              className="h-auto w-[60px] pt-[20px] md:w-[50px] xl:w-[60px]"
            />
          </div>
          <h4 className="my-[10px] font-normal tracking-tight fluid-text-2xl lg:text-4xl">
            “Reforge is the best tool I have as a leader to level up the thinking of my
            teams.”
          </h4>
          <p className="pt-[30px] pb-[50px] font-serif font-normal tracking-tight fluid-text-xl md:pt-0 md:pb-[20px] lg:pt-[10px] lg:pb-[40px]">
            Casey Winters, <em>Former CPO @ Eventbrite</em>
          </p>
        </div>
      </section>
      <PreFooterCTA
        headerText="Interested in Reforge for your team?"
        description="Connect with our team to learn about pricing, use cases, or a product demo."
        cta={{
          href: '/teams/get-in-touch',
          text: 'Get in Touch ↗'
        }}
      />
    </>
  )
}

export default TeamsMarketingPageContent
