import { ArrowRightIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom'

import { SuggestedPromptsWidget } from 'domains/Ai/SuggestedPrompts/SuggestedPromptsWidget'
import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import ContentBookmark from 'domains/Cms/ContentBookmark'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'
import { useCourseContentGate } from 'domains/CourseDetail/CourseContentGateModal'
import CourseDetailsEnroll from 'domains/CourseDetail/CourseDetailsEnroll'
import { buildSrc } from 'domains/Sanity/lib/helpers'
import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import RelatedGuidesCarouselSection from 'domains/Seo/RelatedGuidesCarouselSection'
import { guideThumbnailSrc } from 'domains/Unit/helpers'

import { Loading } from 'components'
import { Breadcrumb } from 'components/Breadcrumb'
import Button from 'components/Button'
import ContentGate from 'components/ContentGate'
import { useGlobalModal } from 'components/GlobalModal'
import ListeningMode from 'components/ListeningMode/ListeningMode'
import ListeningModeCTA from 'components/ListeningMode/ListeningModeCTA'
import ListeningModeContextProvider from 'components/ListeningMode/ListeningModeContextProvider'
import PremiumBadge from 'components/PremiumBadge'
import RfHeader1 from 'components/typography/RfHeader/RfHeader1'

import { MAX_WIDTH_TAILWIND_LG } from 'constants/breakpoints'

import {
  ArtifactAuthor,
  CclCourseBlock,
  CclCourseSessionPartsFragment,
  ContentBlock,
  CourseDetailEntityPreviewPartsFragment,
  CoursePageAccessPartsFragment,
  CourseSessionAccessPartsFragment,
  InferableAction,
  PageSeo,
  PageTypeEnum,
  SanityUnitQuery,
  Unit,
  useBookmarkFoldersQuery,
  useCourseNavigationOutlineQuery,
  useCourseOnDemandDetailQuery,
  useCoursesIncludingGuideQuery,
  useGenerateAiInferenceMutation,
  useRelatedGuidesQuery,
  useSanityUnitQuery,
  useTrackServerEventMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useFirstSigninSource from 'hooks/useFirstSigninSource'
import useMediaQuery from 'hooks/useMediaQuery'
import { useQueryParams } from 'hooks/useQueryParams'
import { useScrollToHashAfterDataIsLoaded } from 'hooks/useScrollToHashAfterDataIsLoaded'
import useURLParams from 'hooks/useURLParams'

import { useContentMode } from 'utils/contentTrackingUtils'
import { onEnterKeyPress } from 'utils/keyboard'
import { isSanityUrl } from 'utils/sanity/sanityUtils'
import { alignSubLists } from 'utils/sublistAligner'
import { cn } from 'utils/tailwind'
import { trackCtaClicked } from 'utils/tracking/generated/events'
import { getAnonymousId } from 'utils/tracking/segment'

import { ReactComponent as PremiumIcon } from 'images/icon--diamond-premium.svg'
import { ReactComponent as DiamondIcon } from 'images/p-diamond.svg'
import { ReactComponent as PInfoIcon } from 'images/p-info.svg'

import LiveCoursesIncludingGuide from './LiveCoursesIncludingGuide'
import ModuleProgress from './ModuleProgress'
import { SwimlaneBreadcrumb } from './SwimlaneBreadcrumb'
import { UnitArtifacts } from './UnitArtifacts'
import { UnitContentBlock } from './UnitContentBlock'
import { UnitContentBlockNavigation } from './UnitContentBlockNavigation'
import { UnitExpertCollaboratorsPreview } from './UnitExpertCollaboratorsPreview'
import UnitExpertSummary from './UnitExpertSummary'
import { UnitOverviewSection } from './UnitOverviewSection'
import UnitProgressConsumer from './UnitProgressConsumer'
import { UnitReadInfo } from './UnitReadInfo'
import { UnitRecap } from './UnitRecap'

export type BackToParams = {
  label: string
  path: string
}[]

export interface UnitLandingPageProps {
  unitSlug?: string
  course?: CoursePageAccessPartsFragment | null
  courseSession?: CourseSessionAccessPartsFragment | null
  showCourseBreadcrumb?: boolean
  backToCourseLabel?: string
  backToCourseLink?: string
  preview?: boolean
  courseDetails?: CourseDetailEntityPreviewPartsFragment | null
  upcomingCourseSessions?: CclCourseSessionPartsFragment[] | null
  swimlaneSlug?: string
  setPage?: (page: GenericPageType) => void
}

export const UnitLandingPage = ({
  unitSlug,
  course,
  courseSession,
  showCourseBreadcrumb = false,
  backToCourseLabel,
  backToCourseLink,
  preview = false,
  courseDetails,
  upcomingCourseSessions,
  swimlaneSlug,
  setPage
}: UnitLandingPageProps) => {
  const { slug } = useParams<{ slug: string }>()
  const slugToUse = unitSlug || slug
  const contentMode = useContentMode({
    courseSession
  })
  const { currentUser } = useCurrentUser()
  const { queryParams } = useQueryParams()

  const { loading, data } = useSanityUnitQuery({
    variables: {
      slug: slugToUse,
      courseId: course?.id
    }
  })

  const { loading: loadingCourseDetails, data: courseDetailsData } =
    useCourseOnDemandDetailQuery({
      variables: { slug: course?.slug || '' },
      skip: !course || preview || !currentUser?.is.freeUser
    })
  const courseContentBlocks = courseDetailsData?.cclCourse?.blocks || []

  const unit = data?.sanityUnit as Unit

  if (loading || loadingCourseDetails) {
    return <Loading />
  }

  if (!data || !unit) {
    return <Redirect to="/guides" />
  }

  const openListeningMode = queryParams.get('openListeningMode') === 'true'

  return (
    <ListeningModeContextProvider
      initialState={{ isOpen: openListeningMode }}
      initialTrackingMetadata={{
        accessPolicyKind: currentUser?.accessPolicyKind || '',
        objectType: 'guide',
        objectId: unit.id,
        ctaLocation: 'guide_page',
        relatedIdentifiers: {
          ccl_entity_id: unit.guide?.id,
          ccl_entity_static_id: unit.guide?.staticId,
          ccl_course_id: course?.cclCourse?.id,
          course_session_id: courseSession?.id,
          course_title: course?.title,
          content_mode: contentMode,
          location: 'guide_page'
        }
      }}
    >
      <UnitLandingPageContent
        unitSlug={slugToUse}
        loading={loading}
        data={data}
        unit={unit}
        course={course}
        courseSession={courseSession}
        showCourseBreadcrumb={showCourseBreadcrumb}
        backToCourseLabel={backToCourseLabel}
        backToCourseLink={backToCourseLink}
        preview={preview}
        courseDetails={courseDetails}
        upcomingCourseSessions={upcomingCourseSessions}
        swimlaneSlug={swimlaneSlug}
        setPage={setPage}
        courseContentBlocks={courseContentBlocks as CclCourseBlock[]}
      />
    </ListeningModeContextProvider>
  )
}

export interface UnitLandingPageContentProps extends UnitLandingPageProps {
  unit: Unit
  loading: boolean
  data: SanityUnitQuery
  courseContentBlocks: CclCourseBlock[]
}

export const UnitLandingPageContent = ({
  unitSlug,
  unit,
  data,
  loading,
  course,
  courseSession,
  showCourseBreadcrumb = false,
  backToCourseLabel,
  backToCourseLink,
  preview = false,
  courseDetails,
  upcomingCourseSessions,
  swimlaneSlug,
  setPage,
  courseContentBlocks
}: UnitLandingPageContentProps) => {
  const history = useHistory()
  const { queryParams } = useQueryParams()

  const { pathname } = useLocation()
  const { getParam } = useURLParams()
  const swimlaneSlugToUse = swimlaneSlug || getParam('swimlane')
  const isConversionPage = queryParams.get('convertible') === 'true'

  const [moduleProgressOpen, setModuleProgressOpen] = useState(false)

  const isOnDemand = backToCourseLink?.includes('on-demand')
  const { currentUser, isLoggedIn } = useCurrentUser()
  const [trackServerEvent] = useTrackServerEventMutation()

  const contentMode = useContentMode({
    courseSession
  })

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({ skip: !currentUser })

  const bookmarkId = unit?.contentBookmarkId
  const [isInSavedItems, setIsInSavedItems] = useState(!!bookmarkId)
  const bookmarkRelatedIdentifiers =
    (course && {
      courseSessionId: courseSession?.id as string,
      courseId: course.id as string,
      courseTitle: course.title as string
    }) ||
    undefined

  const subtopicIds = unit?.subtopicIds
  const { data: relatedGuidesData } = useRelatedGuidesQuery({
    variables: {
      guideId: unit?.id,
      subtopicIds: subtopicIds || []
    },
    skip: !subtopicIds || contentMode !== 'async'
  })
  const relatedGuides = relatedGuidesData?.relatedGuides || []

  const { setCustomSigninSource } = useFirstSigninSource()
  setCustomSigninSource(unit ? `guides (${unit.previewable ? 'free' : 'paid'})` : null)

  const CONTENT_GATE_CTA_TEXT = 'Become a member'
  const FREE_TRIAL_GATE_CTA_TEXT = 'Start 14-day free trial'

  const [generateAiInference] = useGenerateAiInferenceMutation({
    variables: {
      input: {
        actionType: InferableAction.CONTENT_VIEW,
        path: pathname
      }
    }
  })

  const { openCourseContentGate } = useCourseContentGate({
    courseTitle: course?.title || '',
    courseId: course?.id || '',
    contentTitle: unit.title || '',
    contentId: unit.id || '',
    contentType: 'guide'
  })

  useEffect(() => {
    if (!currentUser || !pathname) return
    generateAiInference()
  }, [currentUser, pathname, generateAiInference])

  useEffect(() => {
    setIsInSavedItems(!!bookmarkId)
  }, [bookmarkId])

  useEffect(alignSubLists, [loading])

  const { loading: loadingNavigationOutline, data: dataNavigationOutline } =
    useCourseNavigationOutlineQuery({
      variables: {
        slug: course?.slug || ''
      },
      skip: !course?.slug || preview
    })

  useScrollToHashAfterDataIsLoaded(!!data && !loading)

  useEffect(() => {
    if (!currentUser && unit && setPage) {
      const guideThumbnail = guideThumbnailSrc(unit)
      const shareGraphic = isSanityUrl(guideThumbnail)
        ? buildSrc(guideThumbnail, { width: 1200 })
        : guideThumbnail

      const page: GenericPageType = {
        title: unit.seo?.metaTitle || unit.title || '',
        seo: {
          metaTitle: unit.seo?.metaTitle || unit.title,
          metaDesc: unit.seo?.metaDesc || unit.shortDescription,
          shareTitle: unit.seo?.shareTitle || unit.title,
          shareDesc: unit.seo?.shareDesc || unit.shortDescription,
          shareGraphic: unit.seo?.shareGraphic
            ? unit.seo?.shareGraphic
            : { asset: shareGraphic },
          shareGraphicAlt:
            unit.seo?.shareGraphicAlt ||
            `An image of ${unit.seo?.metaTitle || unit.title}`,
          type: unit.seo?.type || ('article' as PageTypeEnum),
          publishDate: unit.publishDate,
          authors:
            unit.seo?.authors ||
            unit.authors?.map((author: ArtifactAuthor) => author.name),
          structuredDataSchema: unit.seo?.structuredDataSchema
        } as PageSeo
      }

      setPage(page)
    }
  }, [setPage, currentUser, unit])

  useEffect(() => {
    if (unit?.showContentGate || isConversionPage) {
      trackServerEvent({
        variables: {
          input: {
            event: 'Conversion Page Viewed - Server',
            anonymousId: getAnonymousId(),
            properties: {
              access_policy_kind: currentUser?.accessPolicyKind,
              page_type: 'guide',
              path: window.location.pathname,
              referrer: document.referrer,
              url: window.location.href,
              is_conversion_eligible: !unit.previewable,
              logged_in: !!currentUser,
              related_identifiers: {
                content_sanity_id: unit.id,
                content_title: unit.title
              },
              trial_status: currentUser?.trialStatus
            }
          }
        }
      })
    }
  }, [currentUser, trackServerEvent, unit, isConversionPage])

  useEffect(() => {
    if (unit?.id) {
      trackServerEvent({
        variables: {
          input: {
            event: 'Content Viewed - Server',
            anonymousId: getAnonymousId(),
            properties: {
              content_type: 'guide',
              content_title: unit.title,
              content_sanity_id: unit.id,
              content_ccl_local_id: unit.guide?.id,
              access_policy_kind: currentUser?.accessPolicyKind,
              is_previewable: unit.previewable,
              path: pathname,
              related_identifiers: {
                content_mode: contentMode,
                course_id: course?.id,
                course_title: course?.title,
                course_session_id: courseSession?.id
              },
              trial_status: currentUser?.trialStatus
            }
          }
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit?.id])

  const unitAuthors = unit?.authors || []

  const onBackToCourseHome = () =>
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'course_page',
      text: 'Course Home',
      related_identifiers: {
        course_id: course?.id,
        course_session_id: courseSession?.id
      }
    })

  const trackAuthorClick = (author: ArtifactAuthor) => {
    if (!author) return
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'guide',
      text: author.name || '',
      related_identifiers: {
        content_type: 'guide',
        content_sanity_id: unit?.id,
        content_title: unit?.title,
        author_id: author.id,
        author_name: author.name,
        content_mode: 'async'
      }
    })
  }
  const onNextGuideClick = (ctaLocation: string) => {
    if (!nextUnit || !course || (!courseSession && !isOnDemand)) return

    trackCtaClicked({
      cta_type: 'button',
      cta_location: ctaLocation || 'course_page',
      text: `Next ${nextUnit.title}`,
      related_identifiers: {
        course_id: course?.id,
        course_session_id: courseSession?.id
      }
    })

    const url = isOnDemand
      ? `/courses/${course?.slug}/on-demand/guides/${nextUnit.slug}`
      : `/courses/${course?.slug}/${courseSession?.id}/guides/${nextUnit.slug}`

    if (
      currentUser?.is.freeUser &&
      !nextUnit.previewable &&
      showNewCourseGating &&
      !courseSession
    ) {
      return openCourseContentGate({
        destination: url
      })
    }

    history.push(url)
  }

  const loginUrl = '/login?reforgeLogin=true'

  const onNextCourseBlock = (ctaLocation: string) => {
    if (!course || (!courseSession && !isOnDemand)) return

    trackCtaClicked({
      cta_type: 'button',
      cta_location: ctaLocation || 'course_page',
      text: 'Back to Course Dashboard',
      related_identifiers: {
        course_id: course?.id,
        course_session_id: courseSession?.id
      }
    })

    const nextCourseBlock = getNextCourseBlock()

    let url = isOnDemand
      ? `/courses/${course?.slug}/on-demand`
      : `/courses/${course?.slug}/sessions/${courseSession?.id}`

    if (nextCourseBlock && nextCourseBlock.id) {
      url += `#${nextCourseBlock.id}`
    }

    history.push(url)
  }

  const getNextUnit = () => {
    if (dataNavigationOutline?.course?.courseBlocks) {
      const courseBlock = dataNavigationOutline.course.courseBlocks.find((courseBlock) =>
        courseBlock.units?.some((unit) => unit.slug === unitSlug)
      )

      if (courseBlock) {
        const unitIndex = courseBlock.units?.findIndex((unit) => unit.slug === unitSlug)

        if (unitIndex !== undefined && unitIndex !== -1) {
          const nextUnit = courseBlock.units?.[unitIndex + 1]

          if (nextUnit) {
            return nextUnit
          }
        }
      }
    }

    return null
  }

  const getNextCourseBlock = () => {
    const courseBlockIndex = dataNavigationOutline?.course?.courseBlocks?.findIndex(
      (courseBlock) => courseBlock.units?.some((unit) => unit.slug === unitSlug)
    )

    if (courseBlockIndex !== undefined && courseBlockIndex !== -1) {
      const nextCourseBlock =
        dataNavigationOutline?.course?.courseBlocks?.[courseBlockIndex + 1]

      if (nextCourseBlock) {
        return nextCourseBlock
      }
    }

    return null
  }

  const getCurrentCourseBlock = () => {
    const courseBlockIndex = dataNavigationOutline?.course?.courseBlocks?.findIndex(
      (courseBlock) => courseBlock.units?.some((unit) => unit.slug === unitSlug)
    )

    if (courseBlockIndex !== undefined && courseBlockIndex !== -1) {
      return dataNavigationOutline?.course?.courseBlocks?.[courseBlockIndex]
    }

    return null
  }

  const nextUnit = getNextUnit()

  const listeningModeAutoAdvanceUrl = () => {
    // do not return a unit url to advance to if not within the courses domain
    if (!window.location.pathname.includes('/courses')) {
      return null
    }

    // only return a URL to advance if within the course dashboard)
    if (
      !window.location.pathname.includes('/on-demand') &&
      !window.location.pathname.includes('/sessions')
    ) {
      return null
    }

    let url = isOnDemand
      ? `/courses/${course?.slug}/on-demand`
      : `/courses/${course?.slug}/sessions/${courseSession?.id}`
    const nextUnit = getNextUnit()

    if (nextUnit) {
      url += `/guides/${nextUnit.slug}?openListeningMode=true`
    } else {
      const nextCourseBlock = getNextCourseBlock()
      if (nextCourseBlock?.id) {
        url += `#${nextCourseBlock.id}`
      }
    }

    return url
  }

  const createBackTo = (): BackToParams | undefined => {
    // When clicking on the breadcrumb, we want to take the user back to the course dashboard with
    // the course block where the unit is located in view.
    const currentCourseBlock = getCurrentCourseBlock()

    let url = backToCourseLink || `/courses/${course?.slug}/sessions/${courseSession?.id}`
    if (currentCourseBlock && currentCourseBlock.id) {
      url += `#${currentCourseBlock.id}`
    }

    return backToCourseLabel && backToCourseLink
      ? [{ label: backToCourseLabel, path: url }]
      : undefined
  }

  const backTo = createBackTo()
  const { showNewCourseGating } = useFeatureFlags()

  const UNIT_LANDING_PAGE_BUTTON_TEXT =
    currentUser?.is.eligibleForTrial || !isLoggedIn
      ? FREE_TRIAL_GATE_CTA_TEXT
      : CONTENT_GATE_CTA_TEXT

  const { loading: coursesIncludingGuideLoading, data: coursesIncludingGuideData } =
    useCoursesIncludingGuideQuery({
      variables: {
        guideSlug: unitSlug || ''
      }
    })

  const coursesIncludingGuide = coursesIncludingGuideData?.coursesIncludingGuide || []

  const isEnrolledInCourseIncludingGuide = coursesIncludingGuide.some(
    (course) => course.currentUserIsEnrolled
  )

  const showListeningMode =
    !!unit.narrationFileUrl &&
    (isEnrolledInCourseIncludingGuide || currentUser?.is?.paidMember)
  const showNextButton = !preview && !loadingNavigationOutline && dataNavigationOutline

  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_LG})`)

  const { isGlobalModalOpen } = useGlobalModal()

  const showModuleProgress =
    currentUser?.is.freeUser &&
    showNewCourseGating &&
    course?.id &&
    courseContentBlocks.length &&
    !courseSession

  const renderModuleProgress = ({ forMobileView }: { forMobileView: boolean }) => {
    if (currentUser?.is.freeUser) {
      return (
        <div className="flex flex-col gap-6">
          {!isMobileView || moduleProgressOpen ? (
            <div className="absolute xl:static right-0 top-6 z-1 w-80 xl:w-full rounded-xl shadow-[0_4px_9px_0_rgba(15,15,15,0.10)] xl:shadow-none">
              <ModuleProgress
                unit={unit}
                course={course || undefined}
                courseContentBlocks={courseContentBlocks}
                onClose={() => setModuleProgressOpen(false)}
              />
            </div>
          ) : null}

          {showNextButton ? (
            <div
              className={cn(
                forMobileView
                  ? 'fixed bottom-0 w-full bg-white z-[10000] right-0 max-w-[930px] py-6 px-4 flex flex-col md:flex-row items-center gap-4 shadow-[0_4px_9px_0_rgba(15,15,15,0.10)]'
                  : 'mt-0',
                forMobileView && isGlobalModalOpen ? 'z-[1005]' : ''
              )}
            >
              {forMobileView && (
                <div className="w-full">
                  <p className="text-sm leading-[1.5] line-clamp-1 mb-1">{unit?.title}</p>

                  <p className="text-sm leading-[1.5] line-clamp-1 font-semibold text-rb-gray-300 mb-0">
                    {course?.title}
                  </p>
                </div>
              )}
              <NextLessonButton
                nextUnit={nextUnit || undefined}
                onNextGuideClick={() => onNextGuideClick('course_page__right_sidebar')}
                onNextCourseBlock={() => onNextCourseBlock('course_page__right_sidebar')}
                variant="small"
                className="w-full shrink-0 mt-0 md:w-auto"
              />
            </div>
          ) : undefined}
        </div>
      )
    }

    return null
  }
  return (
    <div
      className="flex w-full flex-col items-center scroll-smooth pb-[122px]"
      id="guide-content-container"
    >
      <div
        className={cn(
          'relative flex w-full flex-row',
          !currentUser && 'lg:justify-center'
        )}
      >
        <div
          className={cn(
            'w-full max-w-[780px] flex-col lg:w-3/4',
            currentUser?.is.freeUser && 'lg:w-full max-w-full xl:max-w-[780px]'
          )}
        >
          {showCourseBreadcrumb && backTo && (
            <Breadcrumb
              id="guide-page-header"
              backTo={backTo}
              title={unit?.title || ''}
              onClick={onBackToCourseHome}
            />
          )}
          {!showCourseBreadcrumb && !!swimlaneSlugToUse && (
            <SwimlaneBreadcrumb
              unitTitle={unit?.title || ''}
              swimlaneSlug={swimlaneSlugToUse}
            />
          )}
          <div className="mb-5 flex flex-row items-start justify-between">
            <div className="flex flex-col xs:flex-row xs:items-center gap-2">
              <div className="flex flex-row space-x-2">
                <UnitReadInfo
                  numberOfSteps={unit.stepsCount || 0}
                  readTime={unit.estimatedTimeInMinutes || 0}
                />
                {showListeningMode && <ListeningModeCTA />}
              </div>
              {unit.showContentGate && <PremiumBadge />}
            </div>

            {currentUser && isMobileView && (
              <div className="mt-2.5 flex items-center gap-4">
                {showModuleProgress && (
                  <div className="relative">
                    <PInfoIcon
                      className="w-4 h-4 cursor-pointer"
                      onClick={() => {
                        setModuleProgressOpen(!moduleProgressOpen)
                      }}
                      onKeyDown={onEnterKeyPress(() => {
                        setModuleProgressOpen(!moduleProgressOpen)
                      })}
                      role="button"
                      tabIndex={0}
                    />

                    {renderModuleProgress({ forMobileView: true })}
                  </div>
                )}

                <ContentBookmark
                  contentType="Guide"
                  sanityId={unit.id}
                  bookmarkId={bookmarkId}
                  isInSavedItems={isInSavedItems}
                  setIsInSavedItems={setIsInSavedItems}
                  openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                  relatedIdentifiers={bookmarkRelatedIdentifiers}
                  className=""
                />
              </div>
            )}
          </div>
          <RfHeader1 className="py-1 !font-polysans !font-light">
            <span className="font-light">{unit.title}</span>
          </RfHeader1>
          <UnitExpertCollaboratorsPreview
            linkToAuthProfiles
            experts={unit.authors}
            trackAuthorClick={trackAuthorClick}
            mainClassName="mt-5"
          />

          <div className="space-y-12">
            <UnitOverviewSection
              overview={unit.overview}
              whatYouWillDo={unit.whatYouWillDo}
            />

            {/*
              While the content is not truly gated here if the unit is previewable
              the experience is very similar to when we use a gate
              so we are using the same component for both cases
            */}
            {!currentUser && unit.previewable ? (
              <ContentGate
                title="Sign up to see more free resources"
                description="Create a free account to access artifacts, events, and more."
                ctaText={CONTENT_GATE_CTA_TEXT}
                ctaHref={loginUrl}
                onClick={() => {
                  trackCtaClicked({
                    cta_location: 'guide_signup_gate',
                    cta_type: 'button',
                    text: CONTENT_GATE_CTA_TEXT.toLowerCase(),
                    related_identifiers: {
                      content_sanity_id: unit.id,
                      content_title: unit.title
                    }
                  })
                }}
                IconComponent={
                  ArrowRightIcon as React.ComponentType<{ className: string }>
                }
                divClassName="gap-x-16 rounded-2xl"
                buttonClassName="mt-6 mb-6 w-[205px]"
              >
                <p className="mb-2 text-sm text-rb-gray-300">
                  Have an account?
                  <Link
                    to={loginUrl}
                    className="font-bold text-rb-teal-200 no-underline hover:underline"
                    onClick={() =>
                      trackCtaClicked({
                        cta_location: 'guide_signup_gate',
                        cta_type: 'button',
                        text: 'log in',
                        related_identifiers: {
                          content_sanity_id: unit.id,
                          content_title: unit.title
                        }
                      })
                    }
                  >
                    {' '}
                    Log in
                  </Link>
                </p>
                <p className="mb-0 text-xs text-rb-gray-300">
                  By creating an account, you agree to Reforge‘s{' '}
                  <Link
                    to="/terms-of-service"
                    className="text-rb-teal-200 no-underline hover:underline"
                  >
                    Terms & Conditions
                  </Link>
                </p>
                <p className="text-xs text-rb-gray-300">
                  For more information, visit our{' '}
                  <Link
                    to="/faq"
                    className="text-rb-teal-200 no-underline hover:underline"
                  >
                    FAQs.
                  </Link>
                </p>
              </ContentGate>
            ) : (
              unit?.showContentGate &&
              unit.stepsCount &&
              unit.contentBlocks?.[0] && (
                <>
                  {unit.contentBlocks.length > 1 &&
                    unit.contentBlocks
                      .slice(0, 1)
                      .map((contentBlock: ContentBlock) => (
                        <UnitContentBlock
                          className="relative h-[400px] overflow-hidden after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-b after:from-transparent after:to-[white_88%]"
                          contentBlock={contentBlock}
                          course={course}
                          courseSession={courseSession}
                          unit={unit}
                          key={contentBlock.id}
                        />
                      ))}
                  <ContentGate
                    title={
                      <>
                        Access this guide with a membership
                        <PremiumIcon className="w-6 h-6 inline-block -mt-3 ml-0.5" />
                      </>
                    }
                    description={
                      currentUser?.is?.eligibleForTrial
                        ? 'Get unlimited access to artifacts, guides, on-demand courses and more with our 14-day free trial.'
                        : 'Get unlimited access to guides, on-demand courses and more with one of our membership options.'
                    }
                    ctaText={UNIT_LANDING_PAGE_BUTTON_TEXT}
                    ctaHref={
                      currentUser
                        ? currentUser.is.eligibleForTrial
                          ? `/try-reforge?referer=${window.location.pathname}`
                          : `/subscribe/choose-plan?referer=${window.location.pathname}`
                        : `/login?reforgeLogin=true&referer=${encodeURIComponent(
                            `/subscribe/choose-plan?referer=${window.location.pathname}`
                          )}`
                    }
                    onClick={() => {
                      trackCtaClicked({
                        cta_location: 'guide_paywall',
                        cta_type: 'button',
                        text: UNIT_LANDING_PAGE_BUTTON_TEXT.toLowerCase(),
                        logged_in: !!currentUser,
                        related_identifiers: {
                          content_sanity_id: unit.id,
                          content_title: unit.title
                        }
                      })
                    }}
                    IconComponent={
                      DiamondIcon as React.ComponentType<{ className: string }>
                    }
                    descriptionClassName="px-6"
                    divClassName="bg-rb-blue-50 rounded-2xl border-none"
                    buttonClassName="mt-8 w-[205px] whitespace-nowrap"
                    buttonColor="premium"
                  />
                </>
              )
            )}

            {!unit?.showContentGate && unit.stepsCount && unit.contentBlocks?.[0] && (
              <>
                <SuggestedPromptsWidget
                  premiumUser={!!currentUser && !currentUser?.is?.premember}
                />
                <UnitContentBlock
                  contentBlock={unit.contentBlocks?.[0]}
                  course={course}
                  courseSession={courseSession}
                  unit={unit}
                />
                {unit.contentBlocks.length > 1 &&
                  unit.contentBlocks
                    .slice(1)
                    .map((contentBlock: ContentBlock) => (
                      <UnitContentBlock
                        contentBlock={contentBlock}
                        course={course}
                        courseSession={courseSession}
                        unit={unit}
                        key={contentBlock.id}
                      />
                    ))}
              </>
            )}

            {!unit?.showContentGate && unit.recap && (
              <div className="scroll-mt-16" id="recap">
                <UnitRecap recap={unit.recap} />
              </div>
            )}

            {showNextButton && (
              <NextLessonButton
                nextUnit={nextUnit || undefined}
                onNextGuideClick={() => onNextGuideClick('course_page__end')}
                onNextCourseBlock={() => onNextCourseBlock('course_page__end')}
              />
            )}

            {unit.artifacts && (
              <div className="scroll-mt-16" id="artifacts-section">
                <UnitArtifacts
                  unit={unit}
                  course={course}
                  courseSession={courseSession}
                  backTo={backTo}
                />
              </div>
            )}

            {unitAuthors.map((unitAuthor) => (
              <div className="scroll-mt-16" key={unitAuthor.id}>
                <UnitExpertSummary
                  expert={unitAuthor}
                  avatarImageClassName="w-[94px] h-[94px] mr-6"
                  trackAuthorClick={trackAuthorClick}
                />
              </div>
            ))}

            {unit.title &&
              coursesIncludingGuide.length > 0 &&
              coursesIncludingGuideData?.currentUser &&
              !coursesIncludingGuideLoading && (
                <LiveCoursesIncludingGuide
                  openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                  guideTitle={unit.title}
                  currentUser={coursesIncludingGuideData?.currentUser}
                  coursesIncludingGuide={coursesIncludingGuide}
                />
              )}
          </div>

          {relatedGuides.length > 0 && contentMode === 'async' && (
            <div className="mt-16">
              <RelatedGuidesCarouselSection
                guides={relatedGuides}
                title="Related guides"
                visibleSlides={2}
                navigationLocation="guides__related_content_section"
              />
            </div>
          )}

          {showListeningMode && unit.narrationFileUrl && (
            <ListeningMode
              autoAdvanceUrl={listeningModeAutoAdvanceUrl()}
              src={unit.narrationFileUrl}
            />
          )}
        </div>

        {showModuleProgress ? (
          !isMobileView ? (
            <div className="sticky top-5 xl:flex h-screen flex-col gap-11 p-8 pt-0 pr-0 xl:w-full xl:max-w-[360px]">
              {renderModuleProgress({ forMobileView: false })}
            </div>
          ) : null
        ) : (
          <div
            className={cn(
              'sticky top-8 hidden sm:flex h-screen flex-col gap-11 p-8 pr-0 lg:w-full lg:max-w-[360px]',
              {
                'top-[84px]': !currentUser
              }
            )}
          >
            {preview && courseDetails && (
              <CourseDetailsEnroll
                courseDetails={courseDetails}
                courseSessions={upcomingCourseSessions}
                preview
              />
            )}

            <div className="hidden lg:block">
              <UnitContentBlockNavigation unit={unit} />
            </div>
          </div>
        )}
      </div>

      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={
          !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
        }
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction={'bookmark'}
      />
      <UnitProgressConsumer
        user={currentUser}
        unit={unit}
        courseSession={courseSession}
        course={course}
        preview={preview}
      />
    </div>
  )
}

const NextLessonButton = ({
  nextUnit,
  onNextGuideClick,
  onNextCourseBlock,
  variant = 'large',
  className
}: {
  nextUnit?: Unit
  onNextGuideClick: () => void
  onNextCourseBlock: () => void
  variant?: 'large' | 'small'
  className?: string
}) => {
  return (
    <div className={cn('mt-16', className)}>
      {nextUnit ? (
        <Button
          fullWidth
          variant="fill"
          size={variant === 'large' ? 'x-large' : 'small'}
          onClick={onNextGuideClick}
        >
          Next: {nextUnit.title}
        </Button>
      ) : (
        <>
          <Button
            fullWidth
            variant="fill"
            size={variant === 'large' ? 'x-large' : 'small'}
            onClick={onNextCourseBlock}
            className="normal-case"
          >
            🎉 Great job! You’ve read all the content for this block
          </Button>
        </>
      )}
    </div>
  )
}

export default UnitLandingPage
