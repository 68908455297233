import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { useGlobalModal } from 'components/GlobalModal'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { MultiInviteModalContent } from './MultiInviteModalContent'

export const useMultiInviteModal = () => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const [emails, setEmails] = useState<string[]>([])
  const emailsRef = useRef<string[]>(emails)
  const navigate = useNavigate()

  const { teamInvitationInvites } = useFeatureFlags()

  const handleEmailsChange = (newEmails: string[]) => {
    setEmails(newEmails)
    emailsRef.current = newEmails
  }

  const openMultiInviteModal = (
    currentUserId: string,
    hidePendingInviteList: boolean = false,
    onInvitesSent?: (successes: string[]) => void
  ) => {
    if (!teamInvitationInvites) {
      // this feature is not enabled by a flag
      return
    }
    openGlobalModal(
      <MultiInviteModalContent
        currentUserId={currentUserId}
        handleEmailsChange={handleEmailsChange}
        onUpgradePlanClick={() => {
          closeGlobalModal()
          navigate('/manage-plan')
        }}
        hidePendingInviteList={hidePendingInviteList}
        onInvitesSent={onInvitesSent}
      />,
      {
        className: 'mx-2 max-w-[750px] rounded-lg'
      }
    )
  }

  return {
    openMultiInviteModal
  }
}
