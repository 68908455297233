import { useGlobalModal } from 'components/GlobalModal'

import PersonalizeModal from './PersonalizeModal'

export const usePersonalizeModal = () => {
  const { openGlobalModal } = useGlobalModal()

  const openPersonalizeModal = () => {
    openGlobalModal(<PersonalizeModal />, {
      className: 'overflow-y-scroll pb-0 rounded-xl',
      scrollContent: false,
      fixedHeight: true
    })
  }
  return {
    openPersonalizeModal
  }
}
