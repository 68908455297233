import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import {
  PURCHASE_FLOW_BASEPATH,
  usePurchaseFlowContext
} from 'pages/PurchaseFlow/contexts/PurchaseFlowContext'

import {
  BreadcrumbStep,
  NumberedBreadcrumbs
} from 'components/Breadcrumbs/NumberedBreadcrumbs/NumberedBreadcrumbs'
import Button from 'components/Button'
import { CloseIcon } from 'components/icons'

import { PlanName } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { cn } from 'utils/tailwind'
import getRefererUrl from 'utils/url/getRefererUrl'

import { ReactComponent as Logo } from 'images/reforge-logo-black.svg'

export const BREADCRUMB_STEPS: BreadcrumbStep[] = [
  { title: 'Choose Plan', url: `${PURCHASE_FLOW_BASEPATH}/choose-plan` },
  { title: 'Add-ons', url: `${PURCHASE_FLOW_BASEPATH}/select-add-ons` },
  { title: 'Payment Info', url: `${PURCHASE_FLOW_BASEPATH}/payment-details` },
  { title: 'Review', url: `${PURCHASE_FLOW_BASEPATH}/confirm` }
]

/**
 * PurchaseFlowHeader
 * @returns numbered breadcrumbs and cancel button for the new purchase flow
 */
export const PurchaseFlowHeader = () => {
  const { referer, returnOnClose } = usePurchaseFlowContext()
  const location = useLocation()
  // @ts-ignore - remove after upgrade to react-router-6
  const prevInAppPath = location?.state?.from
  const navigate = useNavigate()
  const refererUrl = getRefererUrl(referer, { purchaseSuccessful: false })
  const cancelUrl = prevInAppPath || returnOnClose || refererUrl || '/upgrade'
  const { premiumUpdatePaymentFlow } = useFeatureFlags()
  const { planName } = usePurchaseFlowContext()
  const hideStepper = location.pathname.includes('/choose-plan')

  useEffect(() => {
    if (!premiumUpdatePaymentFlow) return

    if (planName === PlanName.INDIVIDUAL && BREADCRUMB_STEPS.length === 4) {
      BREADCRUMB_STEPS.splice(1, 1)
    } else if (
      planName &&
      planName !== PlanName.INDIVIDUAL &&
      BREADCRUMB_STEPS.length === 3
    ) {
      BREADCRUMB_STEPS.splice(1, 0, {
        title: 'Add-ons',
        url: `${PURCHASE_FLOW_BASEPATH}/select-add-ons`
      })
    }
  }, [planName, premiumUpdatePaymentFlow])

  return (
    <nav
      aria-label="main navigation menu"
      className="relative left-0 right-0 top-0 z-1 flex flex-col bg-rb-white px-4 py-4 shadow-purchase-flow-header sm:fixed sm:mx-0 md:flex-row md:items-center md:justify-between md:px-6"
    >
      <Logo
        className={cn(
          'h-6 self-start sm:mr-6 sm:self-center md:mb-0 md:justify-self-start',
          !hideStepper && 'sm:mb-4'
        )}
      />

      {!hideStepper && (
        <NumberedBreadcrumbs
          steps={BREADCRUMB_STEPS}
          data-testid="purchase-flow-breadcrumbs"
        />
      )}

      <Button
        variant="text-only"
        className="absolute top-[14px] right-2.5 p-0 transition-background sm:right-[18px] md:top-1/2 md:right-5 md:-translate-y-1/2 lg:right-6"
        size="x-small"
        onClick={() => navigate(cancelUrl)}
      >
        <CloseIcon className="w-6" />
      </Button>
      <div />
    </nav>
  )
}

export default PurchaseFlowHeader
