import { KeyboardEvent, MouseEvent, PropsWithChildren, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'

import { MAX_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import { ReactComponent as ChevronDown } from 'images/p-chevron-down.svg'
import { ReactComponent as ChevronRight } from 'images/p-chevron-right.svg'

interface NavItemProps {
  item: NavItemType
  children?: ReactNode
  onClick?: (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => void
  skipTab?: boolean
  linkClassName?: string
  containerClassName?: string
}

export type DropdownColumnType = {
  columns: {
    title: string
    items: Omit<NavItemType[], 'dropdown'>
  }[]
}

export type NavItemType = {
  title?: string
  type?: 'link' | 'button' | 'separator' | 'search'
  align?: 'left' | 'right'
  url?: string | null
  dropdown?: Omit<NavItemType[], 'dropdown'> | DropdownColumnType
}

interface ComponentProps {
  to?: string | null
  onClick?: (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void
  isMobileView?: boolean
  buttonType?: boolean
  linkClassName?: string
  tabIndex?: number
}

const Component = ({
  to,
  buttonType,
  linkClassName,
  isMobileView,
  ...props
}: PropsWithChildren<ComponentProps>) => {
  if (buttonType && !isMobileView) {
    return (
      <Button
        href={to || undefined}
        variant="outline"
        className={cn(
          'text-base font-semibold text-black border-black leading-[1.6] px-4 h-10 hover:border-black',
          linkClassName
        )}
        iconAfter={<ChevronDown />}
        {...props}
      >
        {props.children}
      </Button>
    )
  }

  const className = cn(
    'flex w-full items-center text-xl !leading-[1.4] text-black font-medium no-underline hover:no-underline lg:text-sm lg:font-normal lg:leading-[1.5] lg:text-rb-gray-400 lg:px-4 lg:py-2.5 lg:hover:text-rb-gray-300'
  )

  if (to) {
    return <Link to={to} className={cn(className, linkClassName)} {...props} />
  }

  return (
    <span
      {...props}
      className={cn(className, linkClassName, 'cursor-pointer hover:text-rb-teal-600')}
    />
  )
}

const NavItem = ({
  item,
  children,
  onClick,
  skipTab,
  linkClassName,
  containerClassName
}: NavItemProps) => {
  const pathname = window.location.pathname
  const isActive = item.url === pathname || hasActiveChild(item)
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)

  const onClickHandler = (
    e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>
  ) => {
    if (item.url && !(item.dropdown && isMobileView)) {
      trackNavigationClicked({
        location: 'marketing_site__top_nav',
        type: 'top navigation',
        destination: item.url,
        text: item.title
      })
    }

    onClick?.(e)
  }

  if (item.type === 'separator') {
    return isMobileView ? null : <hr className="my-4 w-full border-rb-gray-100" />
  }

  return (
    <li
      className={twMerge(
        'my-4 flex w-full lg:w-auto whitespace-nowrap lg:relative lg:my-0 lg:mx-0 lg:gap-2',
        containerClassName
      )}
    >
      <Component
        to={item.url}
        linkClassName={linkClassName}
        onClick={onClickHandler}
        tabIndex={skipTab ? -1 : 0}
        buttonType={item.type === 'button'}
        isMobileView={isMobileView}
      >
        <span
          className={`flex h-full items-center ${
            isActive && item.type !== 'button'
              ? 'bg-gradient-to-r from-current to-current bg-[length:1px_1px] bg-[0_100%] bg-repeat-x'
              : ''
          }`}
        >
          {item.title}
        </span>
        {item.dropdown && <ChevronRight height={24} className="ml-2.5 lg:hidden" />}
      </Component>

      {children}
    </li>
  )
}

const hasActiveChild = (item: NavItemType) => {
  if (!item.dropdown) {
    return false
  }

  if (Array.isArray(item.dropdown)) {
    return item.dropdown.some((child) => child.url === window.location.pathname)
  }

  if (item.dropdown.columns) {
    return item.dropdown.columns.some((column) =>
      column.items.some((child) => child.url === window.location.pathname)
    )
  }
}

export default NavItem
