import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import pluralize from 'pluralize'
import { useState } from 'react'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import DropdownMultiSelect from 'components/dropdowns/DropdownMultiSelect/DropdownMultiSelect'
import DropdownSelect from 'components/dropdowns/DropdownSelect/DropdownSelect'
import { CloseIcon } from 'components/icons'

import { CclFilterTag } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'

import { ReactComponent as FilterSettingIcon } from 'images/p-filter-setting.svg'

import {
  COURSE_SORT_TYPE_DURATION,
  COURSE_SORT_TYPE_POPULARITY,
  COURSE_SORT_TYPE_START_DATE,
  SORT_TYPE_LABEL_MAP
} from './CoursesListSort'
import {
  COURSES_TAB_MY_COURSES,
  CombinedFiltersType,
  FilterType,
  combinedFilterCount
} from './helpers'

interface CourseListFiltersAndSortProps {
  topicFilters: CclFilterTag[]
  functionFilters: CclFilterTag[]
  tabSearchParam: string
  selectedFilters: CombinedFiltersType
  handleFilterSelection: (filter: FilterType) => (value: string[]) => void
  totalResultsCount: number
  selectedSortType: string
  handleSelectSortType: (value: string) => void
  handleClearAll: () => void
  isLoadingCourses: boolean
  totalLiveCount: number
}

const CourseListFiltersAndSort = ({
  topicFilters,
  functionFilters,
  tabSearchParam,
  selectedFilters,
  handleFilterSelection,
  totalResultsCount,
  selectedSortType,
  handleSelectSortType,
  handleClearAll,
  isLoadingCourses,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  totalLiveCount // To be used for hiding the "Live start date" sorting option when there are no live courses - Ticket REF-17185
}: CourseListFiltersAndSortProps) => {
  const { isLoggedIn } = useCurrentUser()
  const [showMobileFilterMenu, setShowMobileFilterMenu] = useState(false)

  if (tabSearchParam === COURSES_TAB_MY_COURSES) {
    return null
  }

  return (
    <>
      <div
        className={cn('justify-between my-8 gap-4 hidden md:flex', isLoggedIn && 'mt-6')}
      >
        {topicFilters.length > 0 && (
          <DropdownMultiSelect
            displayText="Topics"
            data={topicFilters.map((topic) => ({
              label: topic.title,
              value: topic.slug
            }))}
            className="h-[40px] w-full"
            dropdownClassName="min-w-[220px] w-full"
            containerClassName="md:w-full"
            selectedItems={selectedFilters.topics}
            onSelection={handleFilterSelection('topics')}
          />
        )}
        {functionFilters.length > 0 && (
          <DropdownMultiSelect
            displayText="Functions"
            data={functionFilters.map((functionFilter) => ({
              label: functionFilter.title,
              value: functionFilter.slug
            }))}
            className="h-[40px] w-full"
            dropdownClassName="min-w-[220px] w-full"
            containerClassName="md:w-full"
            selectedItems={selectedFilters.functions}
            onSelection={handleFilterSelection('functions')}
          />
        )}

        <DropdownMultiSelect
          displayText="Course types"
          data={[
            {
              label: 'Live course available',
              value: 'onlyLive'
            }
          ]}
          className="h-[40px] w-full"
          dropdownClassName="min-w-[220px] w-full"
          containerClassName="md:w-full"
          selectedItems={selectedFilters.onlyLive ? ['onlyLive'] : []}
          onSelection={handleFilterSelection('onlyLive')}
        />
      </div>

      <div
        className={cn(
          'items-center justify-between mb-8 hidden md:flex',
          isLoggedIn && 'mb-12'
        )}
      >
        <div className="text-rb-black text-xl leading-[1.4] font-semibold">
          {totalResultsCount} {pluralize('result', totalResultsCount)}
        </div>
        <DropdownSelect
          data={[
            {
              value: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_POPULARITY], // the value and label are backwards because the dropdown renders the value as the option text
              label: COURSE_SORT_TYPE_POPULARITY
            },
            {
              value: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_START_DATE],
              label: COURSE_SORT_TYPE_START_DATE
            },
            {
              value: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_DURATION],
              label: COURSE_SORT_TYPE_DURATION
            }
          ]}
          value={selectedSortType}
          onChange={handleSelectSortType}
          label={'Sort By'}
          className={
            'hidden text-sm sm:flex w-[350px] shrink-0 md:w-[300px] flex-row items-center justify-end gap-2'
          }
          labelClassName="text-rb-gray-300"
          dropdownClassName="right-1 sm:w-[160px]"
          noBorder
        />
      </div>

      <button
        className="my-6 flex h-10 w-full cursor-pointer items-center justify-center rounded-full border border-rb-teal-600 md:hidden gap-2"
        onClick={() => setShowMobileFilterMenu(true)}
      >
        {!!combinedFilterCount(selectedFilters) && (
          <span className="flex h-5 w-5 items-center justify-center rounded-full bg-rb-teal-600 font-medium text-white text-xs">
            {combinedFilterCount(selectedFilters)}
          </span>
        )}
        <span className="font-sans text-sm font-medium leading-[1.5] text-rb-teal-600">
          Sort & filter
        </span>

        <FilterSettingIcon className="text-rb-teal-600 w-4 h-4" />
      </button>

      {showMobileFilterMenu && (
        <div
          className={cn(
            'fixed left-0 top-0 z-[1011] h-[56px] w-full bg-rb-black opacity-30 lg:h-[70px] md:hidden',
            !isLoggedIn && 'h-[56px] lg:h-[76px]'
          )}
        />
      )}
      {showMobileFilterMenu && (
        <div
          className={cn(
            'fixed left-0 top-[56px] z-2 h-[100dvh] w-full overflow-x-hidden bg-white px-4 pb-[150px] lg:top-[72px] lg:z-[201] md:hidden',
            !isLoggedIn && 'top-[56px] z-[201] lg:top-[76px]'
          )}
        >
          <div className={cn('mb-6 flex items-center justify-between pl-4 pr-2 pt-4')}>
            <span className="font-sans text-xl font-semibold text-rb-gray-300">
              Sort & filter
            </span>
            <button
              className={cn(
                'flex h-8 w-8 shrink-0 grow-0 items-center justify-end text-black'
              )}
              onClick={() => setShowMobileFilterMenu(false)}
            >
              <CloseIcon className={cn('h-11 w-11 text-rb-gray-300')} />
            </button>
          </div>

          <div className="relative flex flex-col px-4 md:hidden">
            <button
              className="flex cursor-pointer items-center py-1 font-sans text-sm text-rb-black"
              onClick={handleClearAll}
            >
              <span className="font-sans text-sm font-semibold text-rb-teal-200 underline">
                Clear all
              </span>
            </button>
            <hr className="my-4 w-full" />

            <DropdownSelect
              mobile
              mobileDefaultOpen={true}
              data={[
                {
                  label: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_POPULARITY],
                  value: COURSE_SORT_TYPE_POPULARITY
                },
                {
                  label: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_START_DATE],
                  value: COURSE_SORT_TYPE_START_DATE
                },
                {
                  label: SORT_TYPE_LABEL_MAP[COURSE_SORT_TYPE_DURATION],
                  value: COURSE_SORT_TYPE_DURATION
                }
              ]}
              value={selectedSortType}
              label={'Sort By'}
              onChange={handleSelectSortType}
              className={'flex flex-row items-center gap-2 justify-between w-full'}
            />

            <hr className="my-4 w-full" />
            <Disclosure defaultOpen={selectedFilters.topics.length > 0}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex h-[45px] w-full cursor-pointer items-center justify-between bg-white text-rb-black">
                    <span className="text-base font-semibold leading-relaxed">
                      Topics
                    </span>
                    <ChevronDownIcon
                      className={cn(
                        'h-4 w-4 text-rb-black',
                        open && 'rotate-180 transform'
                      )}
                    />
                  </Disclosure.Button>

                  <Disclosure.Panel>
                    {topicFilters.map((topicFilter) => (
                      <Option
                        key={topicFilter.slug}
                        obj={{ label: topicFilter.title, value: topicFilter.slug }}
                        isChecked={selectedFilters.topics.includes(topicFilter.slug)}
                        onChange={(value, isChecked) =>
                          handleFilterSelection('topics')(
                            isChecked
                              ? [...selectedFilters.topics, value]
                              : selectedFilters.topics.filter((topic) => topic !== value)
                          )
                        }
                      />
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <hr className="my-4 w-full" />
            <Disclosure defaultOpen={selectedFilters.functions.length > 0}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex h-[45px] w-full cursor-pointer items-center justify-between bg-white text-rb-black">
                    <span className="text-base font-semibold leading-relaxed">
                      Functions
                    </span>
                    <ChevronDownIcon
                      className={cn(
                        'h-4 w-4 text-rb-black',
                        open && 'rotate-180 transform'
                      )}
                    />
                  </Disclosure.Button>

                  <Disclosure.Panel>
                    {functionFilters.map((functionFilter) => (
                      <Option
                        key={functionFilter.slug}
                        obj={{
                          label: functionFilter.title,
                          value: functionFilter.slug
                        }}
                        isChecked={selectedFilters.functions.includes(
                          functionFilter.slug
                        )}
                        onChange={(value, isChecked) =>
                          handleFilterSelection('functions')(
                            isChecked
                              ? [...selectedFilters.functions, value]
                              : selectedFilters.functions.filter(
                                  (functionFilter) => functionFilter !== value
                                )
                          )
                        }
                      />
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <hr className="my-4 w-full" />
            <Disclosure defaultOpen={selectedFilters.onlyLive}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex h-[45px] w-full cursor-pointer items-center justify-between bg-white text-rb-black">
                    <span className="text-base font-semibold leading-relaxed">
                      Course types
                    </span>
                    <ChevronDownIcon
                      className={cn(
                        'h-4 w-4 text-rb-black',
                        open && 'rotate-180 transform'
                      )}
                    />
                  </Disclosure.Button>

                  <Disclosure.Panel>
                    <Option
                      key="onlyLive"
                      obj={{
                        label: 'Live course available',
                        value: 'onlyLive'
                      }}
                      isChecked={selectedFilters.onlyLive}
                      onChange={(value, isChecked) =>
                        handleFilterSelection('onlyLive')(isChecked ? ['true'] : [])
                      }
                    />
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <hr className="my-4 w-full" />

            <div className="fixed bottom-[2%] right-0 z-10 flex w-full items-center justify-center overflow-hidden px-4">
              <Button
                className="h-[54px] w-full rounded-full bg-rb-black text-white"
                onClick={() => setShowMobileFilterMenu(false)}
                isLoadingSpinner={isLoadingCourses}
              >
                {totalResultsCount
                  ? `Show ${totalResultsCount} ${pluralize('result', totalResultsCount)}`
                  : 'No matches :('}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

interface OptionProps {
  obj: {
    label: string
    value: string
  }
  isChecked: boolean
  onChange: (value: string, isChecked: boolean) => void
}

export const Option = ({ obj, isChecked, onChange }: OptionProps) => {
  const UncheckedBox = (
    <SVGIcon name="checkbox-empty" fill="#d3d2d3" width="20" height="20" />
  )
  const CheckedBox = (
    <SVGIcon name="checkbox-ticked" fill="#1A6C69" width="20" height="20" />
  )

  return (
    <div
      key={obj.value}
      className="mb-4 flex cursor-pointer items-center"
      role="checkbox"
      tabIndex={0}
      aria-checked={isChecked}
      onClick={() => onChange(obj.value, !isChecked)}
      onKeyDown={onEnterKeyPress(() => onChange(obj.value, !isChecked))}
    >
      <span className="mr-2">{isChecked ? CheckedBox : UncheckedBox}</span>
      <div className="text-sans text-sm font-normal text-rb-gray-400">{obj.label}</div>
    </div>
  )
}

export default CourseListFiltersAndSort
