import { useLocation } from 'react-router-dom'

import { ARTIFACTS_HOST } from 'pages/UnitLandingPage/UnitArtifacts'

import RfHeader3 from 'components/typography/RfHeader/RfHeader3'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { CourseSnapshotArtifactPartsFragment, useTrackServerEventMutation } from 'gql'

import { getAnonymousId } from 'utils/tracking/segment'

interface CourseSnapshotArtifactCardProps {
  artifact: CourseSnapshotArtifactPartsFragment
}

const CourseSnapshotArtifactCard = ({ artifact }: CourseSnapshotArtifactCardProps) => {
  const [trackServerEvent] = useTrackServerEventMutation()
  const { pathname } = useLocation()

  const author = artifact?.authors && artifact?.authors[0]

  const onArtifactClick = (e: any) => {
    e.stopPropagation() // Prevent double tracking

    const destination = `${ARTIFACTS_HOST}/artifacts/${artifact.slug}`

    trackServerEvent({
      variables: {
        input: {
          event: 'Content Clicked - Server',
          anonymousId: getAnonymousId(),
          properties: {
            content_type: 'artifact',
            content_title: artifact.title,
            content_ccl_local_id: artifact.cclArtifact?.id,
            content_sanity_id: artifact.id,
            destination,
            referrer: pathname,
            relatedIdentifiers: {
              content_mode: 'async'
            }
          }
        }
      }
    })

    window.open(destination, '_blank')
  }

  return (
    <div
      className="rmin-w-[200px] flex max-w-[350px] cursor-pointer flex-col justify-center rounded-[5px] bg-rb-white p-4 shadow-[0_0_5px_rgba(0,0,0,.15)]"
      role="button"
      onClick={onArtifactClick}
      tabIndex={0}
      onKeyUp={onArtifactClick}
    >
      <img
        className="h-auto w-full rounded object-cover"
        alt={JSON.parse(artifact.thumbnailPhoto)?.alt}
        src={JSON.parse(artifact.thumbnailPhoto)?.asset?.url}
      />
      <div className="mt-6 flex items-center gap-2">
        <img
          className="h-12 w-12 rounded"
          alt={author?.company?.logo?.imageAlt || 'company logo'}
          src={author?.company?.logo?.imageUrl || ''}
        />
        <RfHeader3 className="!mb-0 !font-medium">{artifact?.title}</RfHeader3>
      </div>

      <RfParagraphSmall className="mt-3 flex-1 !text-rb-gray-300">
        {author?.name} Created as {author?.position} at {author?.company?.name}
      </RfParagraphSmall>
    </div>
  )
}

export default CourseSnapshotArtifactCard
