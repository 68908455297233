import React, { useEffect, useRef } from 'react'

import Button from 'components/Button'
import { CloseIcon } from 'components/icons'

import { MIN_WIDTH_TAILWIND_MD } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

interface BulkActionBarProps {
  selectedCount: number
  selectedRemoveUserCount: number
  selectedInviteUserCount: number
  selectedRemindUserCount: number
  onRemoveUsers: () => void
  onSendInviteReminders: () => void
  onInviteToSeats: () => void
  onClose: () => void
}

const BulkActionBar: React.FC<BulkActionBarProps> = ({
  selectedCount,
  onRemoveUsers,
  onSendInviteReminders,
  onInviteToSeats,
  onClose,
  selectedRemoveUserCount,
  selectedInviteUserCount,
  selectedRemindUserCount
}) => {
  const usersNumberText = selectedCount === 1 ? 'user' : 'users'
  const removeUserText =
    selectedRemoveUserCount === 1
      ? 'Remove 1 user'
      : `Remove ${selectedRemoveUserCount} users`
  const inviteReminderText =
    selectedRemindUserCount === 1
      ? 'Send 1 invite reminder'
      : `Send ${selectedRemindUserCount} invite reminders`
  const inviteToSeatText =
    selectedInviteUserCount === 1
      ? 'Invite 1 to seat'
      : `Invite ${selectedInviteUserCount} to seats`

  const isSmallScreen = useMediaQuery(`(max-width: ${MIN_WIDTH_TAILWIND_MD})`)
  const barRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const hideBeaconContainer = () => {
      const beaconContainer = document.getElementById('beacon-container')
      if (beaconContainer) {
        beaconContainer.style.display = 'none'
      }
    }

    /* We need to hide the AI question mark icon that blocks this bar when it is open on mobile. */
    const showBeaconContainer = () => {
      const beaconContainer = document.getElementById('beacon-container')
      if (beaconContainer) {
        beaconContainer.style.display = ''
      }
    }

    /* We need to adjust the padding of the page a little so that this bar does not block or cover any of the team members' accordion
    table in mobile view. */
    const adjustPadding = () => {
      const pageContent = document.getElementById('page-content')
      if (pageContent) {
        if (isSmallScreen && barRef.current) {
          const barHeight = barRef.current.getBoundingClientRect().height
          pageContent.style.paddingBottom = `${barHeight}px`
        } else {
          pageContent.style.removeProperty('padding-bottom')
        }
      }
    }

    if (isSmallScreen) {
      hideBeaconContainer()
      adjustPadding()
      window.addEventListener('resize', adjustPadding)
    }

    return () => {
      if (isSmallScreen) {
        showBeaconContainer()
        const pageContent = document.getElementById('page-content')
        if (pageContent) {
          pageContent.style.removeProperty('padding-bottom')
        }
        window.removeEventListener('resize', adjustPadding)
      }
    }
  }, [isSmallScreen])

  const commonActionButtonClasses =
    'flex items-center justify-center px-4 py-3 md:px-3 md:py-1 text-base md:text-sm bg-transparent border border-white rounded-md text-white hover:bg-white hover:text-black transition-colors duration-200 w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black'

  return (
    <div
      ref={barRef}
      data-testid="bulk-action-bar"
      className="fixed bottom-0 md:bottom-4 left-0 right-0 flex flex-col md:flex-row items-center md:gap-4 w-full md:w-max mx-auto bg-black text-white p-4 rounded-t-lg md:rounded-lg"
    >
      <div className="text-base md:text-sm font-semibold md:font-normal mb-3 md:mb-0 md:ml-2">
        {selectedCount} {usersNumberText} selected:
      </div>
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 w-full md:w-auto">
        {selectedRemoveUserCount !== 0 && (
          <Button
            className={commonActionButtonClasses}
            onClick={onRemoveUsers}
            variant="outline"
          >
            {removeUserText}
          </Button>
        )}
        {selectedRemindUserCount !== 0 && (
          <Button
            onClick={onSendInviteReminders}
            variant="outline"
            className={commonActionButtonClasses}
          >
            {inviteReminderText}
          </Button>
        )}
        {selectedInviteUserCount !== 0 && (
          <Button
            variant="outline"
            onClick={onInviteToSeats}
            className={commonActionButtonClasses}
          >
            {inviteToSeatText}
          </Button>
        )}
      </div>
      <Button
        dataTest="close"
        variant="outline"
        onClick={onClose}
        className="bg-transparent absolute top-2 right-2 md:static md:mr-2 text-white hover:bg-white hover:text-black rounded-full p-1 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black"
      >
        <div className="sr-only">Close</div>
        <CloseIcon className="w-6 h-6" />
      </Button>
    </div>
  )
}

export default BulkActionBar
