import { Link } from 'react-router-dom'

import FreePill from 'components/FreePill'

import { formatTitle } from '../utils'
import { ContentResultItemProps } from './SimilaritySearchResultItem'

const CourseItem = ({
  searchDocument,
  searchQuery,
  onResultClick,
  impressionRef,
  currentUser
}: ContentResultItemProps) => {
  const { author, highlight, title, thumbnail, url, isFree } = searchDocument

  return (
    <Link to={url} onClick={onResultClick}>
      {(!currentUser || currentUser?.is?.premember) && isFree && (
        <div className="absolute top-3 right-3 z-1">
          <FreePill />
        </div>
      )}
      <div
        ref={impressionRef}
        className="flex h-56 rounded border border-rb-gray-100 p-4 hover:shadow-card-hover xs:p-6"
      >
        <div className="flex w-full flex-col gap-y-2 pt-2">
          <h1
            className="mb-0 text-ellipsis text-[20px] font-medium leading-[24px] text-rb-gray-500 line-clamp-2"
            dangerouslySetInnerHTML={{
              __html: formatTitle(searchQuery, title, 200)
            }}
          />

          {author && (
            <p
              className="m-0 text-sm text-rb-gray-400"
              dangerouslySetInnerHTML={{
                __html: formatTitle(searchQuery, `Created by ${author}`, 250)
              }}
            />
          )}

          {highlight && (
            <p
              className="m-0 text-ellipsis p-0 text-[13px] leading-[19.5px] text-rb-gray-300 line-clamp-2 sm:text-[14px] sm:leading-[21px]"
              dangerouslySetInnerHTML={{
                __html: formatTitle(searchQuery, highlight, 250)
              }}
            />
          )}
        </div>

        <div className="relative hidden h-full w-60 flex-none pl-6 sm:block">
          {thumbnail && (
            <img
              src={thumbnail}
              className="h-full w-full rounded-xl object-cover"
              alt=""
            />
          )}
        </div>
      </div>
    </Link>
  )
}

export default CourseItem
