import { ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import SkeletonContentCard from 'components/skeletons/cards/SkeletonContentCard'
import SkeletonRfHeader3 from 'components/skeletons/typography/SkeletonRfHeader'
import RfCardTitle from 'components/typography/RfCard/RfCardTitle'

import {
  ProgramBookmarkPartsFragment,
  TagGroup,
  useGuidesBySanityTagGroupQuery,
  useGuidesForDiscoveryQuery
} from 'gql'
import { SectionPartsFragment, Unit } from 'gql/index'

import GuideCardCarousel from '../GuideCardCarousel'
import { SWIMLANE_ALL } from '../constants'

export interface ContentCardSwimlaneProps {
  isLoading: boolean
  className?: string
  slug: string
  tagGroup?: TagGroup
  category?: string
  contents: SectionPartsFragment[] | undefined | null
  ctaButton?: ReactElement<HTMLAnchorElement>
  hideArrowButtons?: boolean
  title: string
  rowIdx: number
  pageLocation: string
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

/**
 * ContentCardSwimlane
 * Encapsulates all required logic to render a list of ContentCards on the Guides Page
 * */
export const ContentCardSwimlane = ({
  className,
  slug,
  ctaButton,
  title,
  openAddToBookmarkFolderModal,
  tagGroup,
  category,
  rowIdx,
  pageLocation
}: ContentCardSwimlaneProps) => {
  const isSwimlaneAll = slug === SWIMLANE_ALL
  const { data: guidesData, loading: guidesLoading } = useGuidesBySanityTagGroupQuery({
    variables: {
      limit: 4,
      slug: slug
    },
    skip: isSwimlaneAll
  })
  const { data: allGuidesData, loading: allGuidesLoading } = useGuidesForDiscoveryQuery({
    variables: {
      limit: 4
    },
    skip: !isSwimlaneAll
  })

  const isLoading = guidesLoading || allGuidesLoading

  const guides =
    ((isSwimlaneAll
      ? allGuidesData?.guidesForDiscovery
      : guidesData?.guidesBySanityTagGroup) as Unit[]) || []

  if (isLoading) {
    return (
      <>
        <SkeletonRfHeader3 width="1/4" className="mb-6" />
        <div
          className={twMerge(
            'grid list-none grid-cols-[repeat(auto-fill,minmax(272px,1fr))] gap-6 p-0',
            className
          )}
          data-testid="content-card-swim-lane-loading"
        >
          {Array.from({ length: 4 }).map((_, i) => (
            <SkeletonContentCard key={i} />
          ))}
        </div>
      </>
    )
  }

  return (
    <div className={className}>
      <GuideCardCarousel
        title={
          <div className="flex">
            <RfCardTitle>{title}</RfCardTitle>
            {ctaButton}
          </div>
        }
        guides={guides}
        swimlaneSlug={slug}
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        tagGroup={tagGroup}
        category={category}
        isSwimlaneAll={isSwimlaneAll}
        rowIdx={rowIdx}
        pageLocation={pageLocation}
      />
    </div>
  )
}

export default ContentCardSwimlane
