import { useEffect } from 'react'

import Button from 'components/Button'

import { trackBannerDisplayed } from 'utils/tracking/analytics'

import { ReactComponent as CogIcon } from 'images/p-cog.svg'

import { usePersonalizeModal } from '../../PersonalizeModal/usePersonalizeModal'

interface PersonalizeRecommendationsBannerProps {
  setIsFocusUpdate: (isFocusUpdate: boolean) => void
}

/**
 * @returns a styled component for member users without focus areas to create them
 */
export const PersonalizeRecommendationsBanner = ({
  setIsFocusUpdate
}: PersonalizeRecommendationsBannerProps) => {
  const { openPersonalizeModal } = usePersonalizeModal()
  const onCustomizeClick = () => {
    openPersonalizeModal()
    setIsFocusUpdate(true)
  }

  const bannerName = 'premium_user_home__missing_focus_banner'

  useEffect(() => {
    trackBannerDisplayed({
      banner_name: bannerName,
      category: 'app'
    })
  }, [])

  return (
    <div className="mb-8">
      <p className="text-[22px] leading-[1.2] mb-6 lg:mb-8 font-medium lg:text-xl lg:font-semibold lg:leading-[1.4]">
        Recommended for you
      </p>
      <div className="flex flex-col lg:flex-row px-6 md:px-12 py-8 gap-6 rounded-xl bg-rb-orange-25 lg:items-center justify-between">
        <div className="flex flex-col gap-2">
          <p className="text-xl leading-[1.4] font-semibold mb-0">
            Personalize your recommendations
          </p>

          <p className="text-base leading-[1.6] mb-0">
            Choose your focus areas to see the latest artifacts, guides and more, specific
            to what you’re interested in.
          </p>
        </div>

        <Button
          size="small"
          variant="outline"
          className="h-10 px-4"
          iconAfter={<CogIcon width={16} />}
          onClick={onCustomizeClick}
        >
          Customize
        </Button>
      </div>
    </div>
  )
}

export default PersonalizeRecommendationsBanner
