import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import ReactivationFlow from 'domains/Subscription/ReactivationFlow'

import { ErrorMessage } from 'components'
import Button from 'components/Button'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import {
  UserSubscriptionReactivationQuery,
  useUserSubscriptionReactivationQuery
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { trackCtaClicked } from 'utils/tracking/analytics'

const ReactivateSubscriptionButtonContainer = ({ className }: { className?: string }) => {
  const { loading, error, data } = useUserSubscriptionReactivationQuery()

  if (loading || !data) return null
  if (error) return <ErrorMessage error={error} />

  const { currentUser: user } = data

  // is this supposed to send the user to login page?
  if (!user) return null

  return <ReactivateSubscriptionButton user={user} className={className} />
}

const ReactivateSubscriptionButton = ({
  user,
  className
}: {
  user: NonNullable<UserSubscriptionReactivationQuery['currentUser']>
  className?: string
}) => {
  const isSmallScreen = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const [isOpenReactivationFlow, setIsOpenReactivationFlow] = useState(false)

  const { subscriptions, paymentMethods } = user

  const activeSubscription = subscriptions?.active

  // user should definitely not get here without a subscription
  // making typescript happy
  if (!activeSubscription) return null

  const buttonText = 'Restart Subscription'

  const handleClick = () => {
    trackCtaClicked({
      cta_location: 'subscription_card',
      cta_type: 'button',
      text: buttonText.toLowerCase()
    })
    setIsOpenReactivationFlow(true)
  }

  return (
    <>
      <Button
        onClick={handleClick}
        size="x-small"
        shape="rounded"
        className={twMerge('h-7.5', className)}
      >
        {buttonText}
      </Button>

      <ReactivationFlow
        shouldInitiate={isOpenReactivationFlow}
        setShouldInitiateReactivationFlow={setIsOpenReactivationFlow}
        hasPaymentMethod={!!paymentMethods?.length}
        isSmallScreen={isSmallScreen}
        subscription={activeSubscription}
      />
    </>
  )
}

export default ReactivateSubscriptionButtonContainer
