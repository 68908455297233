import { useBookmarkCard } from 'domains/Bookmarks/hooks/useBookmarkCard'

import Tooltip from 'components/Tooltip/Tooltip'
import CloseIcon from 'components/icons/CloseIcon'

import { ProgramBookmarkPartsFragment } from 'gql'

import { trackBookmarkAction } from 'utils/tracking/analytics'

interface BookmarkNoteControlsProps {
  bookmark: ProgramBookmarkPartsFragment
  hideNote: () => void
  shouldDeleteBookmark?: boolean
}

const track = () => trackBookmarkAction({ action: 'delete_note', component: 'hover' })

const BookmarkNoteControls = ({
  bookmark,
  shouldDeleteBookmark = false,
  hideNote
}: BookmarkNoteControlsProps) => {
  const { deleteBookmark, updateBookmarkNote } = useBookmarkCard({
    bookmark,
    onDeleteBookmark: track,
    onUpdateBookmarkNote: track
  })
  const handleClick = () => {
    if (shouldDeleteBookmark) {
      // BookmarkableWistiaPlayer (Video) delete bookmark
      deleteBookmark()
    } else {
      // BookmarkableWistiaPlayer (Video) delete bookmark
      updateBookmarkNote({ note: '' })
    }
    hideNote()
  }

  const trashElement = (
    <div
      className="cursor-pointer opacity-0 transition-opacity duration-500 group-hover:opacity-100"
      onClick={handleClick}
    >
      <Tooltip tooltipBody={shouldDeleteBookmark ? 'Delete Bookmark' : 'Delete Note'}>
        <CloseIcon className="h-6 w-5" />
      </Tooltip>
    </div>
  )

  return trashElement
}

export default BookmarkNoteControls
