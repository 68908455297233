import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { useCourseContentGate } from 'domains/CourseDetail/CourseContentGateModal'

import { CclCourseBlock, CclGuide, CoursePageAccessPartsFragment, Unit } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'

import { ReactComponent as PCancelIcon } from 'images/p-cancel.svg'

interface ModuleProgressProps {
  unit: Unit
  course?: CoursePageAccessPartsFragment
  courseContentBlocks: CclCourseBlock[]
  nextButton?: ReactNode
  onClose: () => void
}

const ModuleProgress = ({
  unit,
  course,
  courseContentBlocks,
  onClose
}: ModuleProgressProps) => {
  const navigate = useNavigate()
  const courseBlock = courseContentBlocks.find((block) =>
    block.guides?.some((guide) => guide.slug === unit.slug)
  )
  const moduleNumber = courseBlock ? courseContentBlocks.indexOf(courseBlock) + 1 : null
  const moduleTitle = courseBlock?.title || ''
  const guides = courseBlock?.guides
    ? [...courseBlock.guides].sort((a, b) => a.displayOrder - b.displayOrder)
    : []

  const { openCourseContentGate } = useCourseContentGate({
    courseTitle: course?.title || '',
    courseId: course?.id || '',
    contentTitle: unit.title || '',
    contentId: unit.id || '',
    contentType: 'guide'
  })

  const onGuideClick = (guide: CclGuide) => {
    if (guide.displayOrder === 0 || guide.freePreview) {
      return navigate(`/courses/${course?.slug}/on-demand/guides/${guide.slug}`)
    }

    openCourseContentGate({
      destination: `/courses/${course?.slug}/on-demand/guides/${guide.slug}`
    })
  }

  return (
    <div className="bg-rb-gray-50 p-6 rounded-xl relative">
      <p className="text-rb-gray-400 text-xl font-medium leading-[1.4] mb-6 pr-10 lg:pr-0">
        {course?.title}
      </p>

      <div
        className="absolute top-6 right-6 xl:hidden"
        onClick={onClose}
        role="button"
        tabIndex={0}
        onKeyDown={onEnterKeyPress(onClose)}
      >
        <PCancelIcon className="text-rb-gray-400 w-4 h-4" />
      </div>

      <p className="text-rb-gray-300 text-xs uppercase font-medium leading-[1] tracking-[1.2px] mb-0">
        Module {moduleNumber}
      </p>

      <p className="text-base text-black font-semibold leading-[1.6] mb-4">
        {moduleTitle}
      </p>

      <div className="flex flex-col gap-4">
        {guides.map((guide) => (
          <div
            key={guide.id}
            className={cn(
              guide.slug === unit.slug && 'py-1 bg-rb-gray-100 rounded-[4px]'
            )}
            onClick={() => onGuideClick(guide)}
            onKeyDown={onEnterKeyPress(() => onGuideClick(guide))}
            role="button"
            tabIndex={0}
          >
            <p className="text-base text-black leading-[1.6] mb-0 flex items-start gap-4">
              <span className="w-4 h-4 border-2 border-rb-gray-300 rounded-[3px] mx-[1px] my-1 shrink-0 bg-white" />
              {guide.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ModuleProgress
