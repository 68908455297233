import { useEffect, useState } from 'react'

import TabBar from 'components/TabBar'
import { TabKey } from 'components/TabBar/TabBar'

import InteractiveContentSectionHero from './InteractiveContentSectionHero'
import PopularContent from './PopularContent'
import { Topic } from './PopularContent/PopularContent'

interface InteractiveContentSectionProps {
  onTabChange: () => void
}

interface InteractiveContentSectionProps {
  onTabChange: () => void
}

export const InteractiveContentSection = ({
  onTabChange
}: InteractiveContentSectionProps) => {
  const trackingLocation = 'marketing_home__browse_content_section'
  const TAB_STORAGE_KEY = 'activeTab'

  const getInitialTab = (): TabKey => {
    // Fetch the tab from sessionStorage if available, otherwise use a default
    return (sessionStorage.getItem(TAB_STORAGE_KEY) as TabKey) || 'productManagement'
  }

  const [activeTab, setActiveTab] = useState<TabKey>(getInitialTab())

  const handleTabChange = (key: TabKey) => {
    setActiveTab(key)
    sessionStorage.setItem(TAB_STORAGE_KEY, key) // Store the active tab in sessionStorage
    if (onTabChange) {
      onTabChange()
    }
  }

  const topics: Topic[] = [
    {
      label: 'Product Management',
      slug: 'product-development',
      key: 'productManagement'
    },
    { label: 'Growth', slug: 'growth', key: 'growth' },
    { label: 'Marketing', slug: 'marketing', key: 'marketing' },
    { label: 'Artificial Intelligence (AI)', slug: 'ai', key: 'artificialIntelligence' }
  ]

  const activeTopic = topics.find((topic) => topic.key === activeTab)!

  useEffect(() => {
    // Optionally, synchronize the session storage with other effects here
  }, [activeTab])

  return (
    <div className="pb-12 sm:pb-0 2xl:pb-20">
      <InteractiveContentSectionHero />
      <div className="px-4 md:px-[4vw] mb-6 md:mb-12">
        <TabBar
          initialTab={activeTab}
          tabs={topics}
          onTabChange={(key) => {
            handleTabChange(key)
          }}
          trackingLocation={trackingLocation}
        />
      </div>
      <PopularContent topic={activeTopic} trackingLocation={trackingLocation} />
    </div>
  )
}

export default InteractiveContentSection
