import bg from 'logged-out-homepage-hero-gradient.svg'

import Image from 'domains/Sanity/Image'
import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'
import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import { MarketingH1, MarketingH3 } from 'domains/Sanity/marketingSite/textHelpers'

import { HomepageContent } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import ExpertsCarousel from './ExpertsCarousel'

const LoggedOutHomepageHero = ({ content }: { content: HomepageContent }) => {
  const over4KWidth = useMediaQuery('(min-width: 2560px)')

  return (
    <div
      className={cn(
        'relative pt-14 lg:pt-[76px] overflow-hidden',
        over4KWidth && 'overflow-visible'
      )}
    >
      <Image
        src={bg}
        alt=""
        className="absolute top-0 left-0 -z-50 w-full object-cover"
      />
      <div className="flex flex-col text-center pt-[70px] lg:pt-[150px] px-4 md:px-[4vw]">
        <MarketingH1 className="mb-3 lg:mb-6">{content.title}</MarketingH1>
        <MarketingH3
          className="mb-[50px] lg:mb-[80px] w-full sm:max-w-[450px] lg:max-w-[650px] mx-auto"
          as="h2"
        >
          {content.subtitle}
        </MarketingH3>
        <div className="flex flex-col sm:flex-row items-center sm:justify-center mb-6">
          <ButtonMarketingCTA
            className="mb-6 sm:mb-0 min-w-[240px]"
            href={content.mainCta.ctaLink}
            location="marketing_homepage__hero_section"
          >
            {content.mainCta.ctaText}
          </ButtonMarketingCTA>
          <ButtonMarketingCTA
            variant="outline"
            className="min-w-[240px] sm:ml-4"
            href={content.secondaryCta.ctaLink}
            location="marketing_homepage__hero_section"
          >
            {content.secondaryCta.ctaText}
          </ButtonMarketingCTA>
        </div>
        <div className="text-center mb-[70px] lg:mb-[100px]">
          {content.editableContent && (
            <ComplexPortableText content={content.editableContent} />
          )}
        </div>
      </div>

      <ExpertsCarousel />
    </div>
  )
}

export default LoggedOutHomepageHero
