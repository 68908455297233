import { Link, useLocation } from 'react-router-dom'

import { createCreatorsLandingPageUrl } from 'domains/Artifact/utils'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as ReforgeWordmark } from 'images/ReforgeWordmark.svg'

const Footer = () => {
  const { isLoggedIn } = useCurrentUser()
  const location = useLocation()

  const linkClasses =
    'text-white no-underline hover:text-rb-gray-300 visited:text-white font-light'

  const becomeACreatorUrl = createCreatorsLandingPageUrl(location)

  return (
    <footer
      className="grid grid-flow-row auto-rows-max grid-cols-2 gap-y-[20px] gap-x-[50px] bg-black px-[16px] py-[6vmax] text-white md:grid-cols-4 md:px-[4vw]"
      role="contentinfo"
    >
      <div className="col-span-2 row-span-1 md:col-span-1">
        <ReforgeWordmark className="h-auto w-1/2 min-w-[100px] max-w-[200px] fill-white md:w-9/12" />
      </div>
      <div className="col-span-2 row-span-1 mb-[20px] md:col-span-1 md:row-start-2 md:mb-[0px] max-w-[290px]">
        <p className="text-lg leading-6">
          Reforge membership gives you access to vetted product, growth, and marketing
          expertise.
        </p>
      </div>
      <div className="col-span-1 row-span-1 md:row-start-2">
        <ul className="list-none p-[0px]">
          <li>
            <Link className={linkClasses} to="/extension">
              Extension
            </Link>
          </li>
          <li>
            <Link className={linkClasses} to="/podcast/unsolicited-feedback">
              Podcast
            </Link>
          </li>
          <li>
            <Link className={linkClasses} to="/careers">
              Careers
            </Link>
          </li>
          <li>
            <Link className={linkClasses} to="/pricing">
              Pricing
            </Link>
          </li>
          <li>
            <Link className={linkClasses} to="/reimbursement">
              Reimbursements
            </Link>
          </li>
          <li>
            <Link className={linkClasses} to="/faq">
              FAQs
            </Link>
          </li>
        </ul>
      </div>
      <div className="col-span-1 row-span-1 md:row-start-2">
        <ul className="list-none p-[0px]">
          <li>
            <Link className={linkClasses} to="/terms-of-service">
              Terms of Service
            </Link>
          </li>
          <li>
            <Link className={linkClasses} to="/copyright-dispute">
              Copyright Policy
            </Link>
          </li>
          <li>
            <Link className={linkClasses} to="/privacy-policy">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link className={linkClasses} to="/contact">
              Contact Us
            </Link>
          </li>
          <li>
            <Link
              className={linkClasses}
              onClick={() => {
                trackCtaClicked({
                  cta_location: 'marketing_site__footer',
                  cta_type: 'button',
                  destination: becomeACreatorUrl,
                  logged_in: isLoggedIn,
                  text: 'become a creator'
                })
              }}
              to={becomeACreatorUrl}
            >
              Become a Creator
            </Link>
          </li>
          <li>
            <a
              className={linkClasses}
              onClick={() => {
                trackCtaClicked({
                  cta_location: 'marketing_site__footer',
                  cta_type: 'button',
                  destination: 'https://www.info.reforge.com/pm-skills-grader',
                  logged_in: isLoggedIn,
                  text: 'Product Manager Skills Grader'
                })
              }}
              target="_blank"
              href="https://info.reforge.com/pm-skills-grader"
              rel="noreferrer"
            >
              Product Manager Skills Grader
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
