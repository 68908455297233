import { CclCourseSessionPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { getFormattedEventDate, isBeforeDate } from 'utils/date'

interface CourseDetailEventDetailsProps {
  session: CclCourseSessionPartsFragment
}
const CourseDetailEventDetails = ({ session }: CourseDetailEventDetailsProps) => {
  const { currentUser } = useCurrentUser()

  let orderedSessionEvents: any[] = []
  if (session.events) {
    orderedSessionEvents = [...session.events].sort((a: any, b: any) => {
      return isBeforeDate(b.startsAt, a.startsAt) ? -1 : 1
    })
  }
  const userTimeZone =
    currentUser?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <div className="text-2xl !font-light" id="event-details">
      <div className="font-polysans">Event details</div>
      <div className="pt-4">
        {orderedSessionEvents.map((event) => (
          <div className="text-base font-normal leading-7" key={event.id}>
            {getFormattedEventDate(event, userTimeZone)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CourseDetailEventDetails
