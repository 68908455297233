import React from 'react'

import Button from 'components/Button'

interface AccordionItemProps {
  children: React.ReactNode
}
export const AccordionItem: React.FC<AccordionItemProps> = ({ children }) => (
  <div className="mb-2 border rounded-lg overflow-hidden">{children}</div>
)

interface AccordionHeaderProps {
  children: React.ReactNode
  isOpen: boolean
  onToggle: () => void
  dataTestId?: string
  id: string
}

export const AccordionItemHeader: React.FC<AccordionHeaderProps> = ({
  dataTestId,
  children,
  isOpen,
  onToggle,
  id
}) => {
  return (
    <div
      className="grid grid-cols-[1fr_auto] items-center pr-1 bg-gray-100"
      data-testid={dataTestId || `accordion-header-${id}`}
    >
      <div className="col-span-1">{children}</div>
      <Button
        dataTest={dataTestId ? `${dataTestId}-toggle-button` : `toggle-button-${id}`}
        className="col-span-1 flex-shrink-0 p-1 ml-auto max-w-fit"
        onClick={onToggle}
        variant="text-only"
        aria-expanded={isOpen}
        aria-controls={`accordion-content-${id}`}
      >
        <span className="sr-only">{isOpen ? 'Collapse' : 'Expand'}</span>
        <svg
          className={`w-6 h-6 transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </Button>
    </div>
  )
}

interface AccordionContentProps {
  children: React.ReactNode
  isOpen: boolean
  id: string
}

export const AccordionItemContent: React.FC<AccordionContentProps> = ({
  children,
  isOpen,
  id
}) => (
  <div
    id={`accordion-content-${id}`}
    role="region"
    aria-labelledby={`accordion-header-${id}`}
    hidden={!isOpen}
  >
    {isOpen && children}
  </div>
)
