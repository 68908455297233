import { useHistory } from 'react-router-dom'

import ReactivateSubscriptionButton from 'domains/Subscription/ReactivateSubscriptionButton'
import { useSubscriptionCancel } from 'domains/Subscription/useSubscriptionCancel'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import { LearnMoreAboutPricingAndPackagingLink } from 'components/LearnMoreAboutPricingAndPackagingLink'
import PlanOptions from 'components/PlanOptionCard/PlanOptions'

import { useManagePlanQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { getPrettyFullDate } from 'utils/date'
import { titleize } from 'utils/stringUtils'

export const ManagePlanPage = () => {
  const history = useHistory()
  const { id: currentUserId } = useAssertCurrentUser()
  const { loading, error, data } = useManagePlanQuery({
    variables: { id: currentUserId }
  })
  const { handleCancelSubscriptionButtonClick } = useSubscriptionCancel()

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (!data?.user) return <ErrorMessage error={'No user data available.'} />

  const {
    user: { is, subscriptions },
    userPaymentMethods,
    plansForSale
  } = data

  const activeSubscription = subscriptions?.active

  const isCanceled = activeSubscription?.isCanceled
  const isDunning = activeSubscription?.isDunning
  const isTrialing = is.trialing

  if (!activeSubscription || isDunning) {
    history.push('/account')
    return null
  }

  const isLegacySubscription = activeSubscription.planName === 'unlimited'

  const prettyExpirationDate = getPrettyFullDate(activeSubscription.expiresAt)
  const prettyTrialEndDate = getPrettyFullDate(activeSubscription.trialEndDate)

  const getMainMessage = () => {
    if (isDunning) {
      // Dunning & no payment method available (team or individual)
      if (!userPaymentMethods || userPaymentMethods.length < 1) {
        return `We couldn't process your payment. Update your payment method for continued access to Reforge. Your subscription will expire in ${activeSubscription.dunningDaysLeft} days.`
      }

      return `We couldn’t process your payment. Your subscription will expire in ${activeSubscription.dunningDaysLeft} days.`
    }

    // Subscription is canceled / set to expire
    if (isCanceled) {
      if (isTrialing) {
        return `Your free trial is set to expire on ${prettyTrialEndDate}. Restart your subscription to maintain access to Reforge after your free trial.`
      }

      if (!activeSubscription.userIsOwner) {
        return `Your subscription is set to expire on ${prettyExpirationDate}. Be sure to tell your subscription owner to select a plan before it expires.`
      }

      if (!isLegacySubscription) {
        return `Your subscription is set to expire on ${prettyExpirationDate}. Reactivate your subscription or select a new plan below to maintain access to Reforge.`
      }

      return `Your subscription is set to expire on ${prettyExpirationDate}. Restart your subscription to maintain access to Reforge.`
    }

    if (isLegacySubscription) {
      return 'Choose the plan that’s right for you or your team.'
    }

    if (isTrialing) {
      return `Your free trial ends on ${prettyTrialEndDate}. Your subscription will be charged on ${prettyExpirationDate}.`
    }

    return `Your plan will renew into the ${titleize(
      activeSubscription.stripeUpcomingInvoice?.planName ||
        activeSubscription.planName ||
        ''
    )} plan on ${prettyExpirationDate}.`
  }

  const getCta = () => {
    if (!activeSubscription.userIsOwner) {
      return null
    }

    if (isCanceled && !isLegacySubscription) {
      return <ReactivateSubscriptionButton />
    } else if (!isCanceled) {
      return (
        <Button
          size="x-small"
          variant="outline"
          onClick={() => handleCancelSubscriptionButtonClick('button')}
        >
          Cancel Subscription
        </Button>
      )
    }
  }

  return (
    <div className="mb-5 px-0">
      {activeSubscription.userIsOwner ? (
        <h3 className="font-medium">Manage Plan</h3>
      ) : (
        <h3 className="font-medium">View Plans</h3>
      )}

      <div className="mb-8">
        <p>
          {getMainMessage()} {!isTrialing && <LearnMoreAboutPricingAndPackagingLink />}
        </p>
        {getCta()}
      </div>

      <PlanOptions
        currentPlanName={activeSubscription.planName}
        currentNumSeats={activeSubscription.numSeats}
        currentPlanPrice={activeSubscription.stripeUpcomingInvoice?.subtotal}
        isSubscriptionOwner={activeSubscription.userIsOwner}
        plansForSale={plansForSale}
        upcomingPlanName={activeSubscription.stripeUpcomingInvoice?.planName}
        upcomingNumCohortPasses={
          activeSubscription.stripeUpcomingInvoice?.numCohortPasses || 0
        }
        isCanceled={isCanceled}
        isTrialing={isTrialing}
      />
    </div>
  )
}
