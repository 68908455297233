import { useEffect, useMemo, useState } from 'react'
import { Redirect, matchPath, useHistory, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { useCurrentScheduledWeekId } from 'domains/CohortDashboard'
import CohortDashboardWeeklyBlock, {
  CohortDashboardWeeklyBlockSkeleton
} from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/CohortDashboardWeeklyBlock'
import { CohortEvent, combineSameEvents } from 'domains/CohortViewer/utils'

import { ErrorMessage } from 'components'

import {
  CohortDashboardCmsSectionPartsFragment,
  CohortDashboardScheduledWeekPartsFragment,
  useCohortDashboardQuery
} from 'gql'

import {
  filterForScheduledWeeksWithEvents,
  scheduledWeekIdToDisplay
} from 'utils/cohortUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { useCohortDashboardTrackingContext } from '../hooks/useCohortDashboardTrackingContext'

const CohortDashboardProgramSkeleton = () => (
  <div className="flex w-full animate-pulse flex-col rounded-2xl py-8 px-6 pb-96 shadow-card md:px-8 lg:px-12 2xl:px-16">
    <div className="mb-16 flex w-full flex-row space-x-2 md:space-x-5 lg:w-3/4">
      <div className="h-8 w-[80px] rounded-3xl bg-rb-gray-100 xl:w-1/3" />
      <div className="h-8 w-[80px] rounded-3xl bg-rb-gray-100 xl:w-1/3" />
      <div className="h-8 w-[80px] rounded-3xl bg-rb-gray-100 xl:w-1/3" />
      <div className="h-8 w-[80px] rounded-3xl bg-rb-gray-100 xl:w-1/3" />
      <div className="h-8 w-[80px] rounded-3xl bg-rb-gray-100 xl:w-1/3" />
    </div>
    <CohortDashboardWeeklyBlockSkeleton />
  </div>
)

export interface CohortDashboardProgramProps {
  slug: string
}

export const CohortDashboardProgram = ({ slug }: CohortDashboardProgramProps) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const [pathContainsEnd, setPathContainsEnd] = useState<boolean | undefined>(false)
  const { data, loading, error, refetch } = useCohortDashboardQuery({
    variables: { slug }
  })

  const { cohort: cohortContext, userCohortId } = useCohortDashboardTrackingContext()

  const cohort = data?.cohort
  const templates = cohort?.cmsProgram?.templates

  const onWeekClick = (id: string, index: number) => {
    if (id === 'end') {
      history.push(`/cohorts/${slug}/end`)
    } else {
      trackCtaClicked({
        cta_type: 'menu_select',
        cta_location: 'cohort_dashboard_week_tab',
        text: index === 0 ? 'Kickoff' : `Week ${index}`,
        related_identifiers: {
          cohort_id: cohortContext.id,
          cohort_name: cohortContext.slug,
          user_cohort_id: userCohortId
        }
      })
      setWeekId(id)
      history.push(`/cohorts/${slug}?week=${index}`)
    }
  }

  const { setWeekId, weekId, isSettingWeekId } = useCurrentScheduledWeekId({
    cohort,
    loaded: !loading,
    hasCohortOffboardingStarted: !!cohort?.hasCohortOffboardingStarted
  })

  const getTemplates = useMemo(() => {
    if (templates?.cmsSections?.[0]?.contentType === 'Section') {
      const templatesSection =
        templates?.cmsSections[0] || ({} as CohortDashboardCmsSectionPartsFragment)
      const mockLessons = templatesSection.children.map((lesson) => ({
        ...lesson,
        children: []
      }))

      // create a mock CmsModule to return
      return {
        cmsSections: mockLessons,
        estimatedTime: templatesSection.estimatedTime,
        href: templatesSection.href,
        id: `mock-module-${templatesSection.id}`,
        name: templatesSection.name,
        slug: templatesSection.slug,
        __typename: 'CmsModule' as const
      }
    } else {
      return templates
    }
  }, [templates])

  useEffect(() => {
    setPathContainsEnd(
      matchPath(pathname, {
        path: '/cohorts/:slug/end'
      })?.isExact
    )
  }, [pathname])

  useEffect(() => {
    if (!search.includes('week=')) {
      setWeekId(
        scheduledWeekIdToDisplay(cohort, !loading, !!cohort?.hasCohortOffboardingStarted)
      )
    }
  }, [search, refetch, setWeekId, cohort, loading])

  if (loading || !data || !data?.currentUser || isSettingWeekId) {
    return <CohortDashboardProgramSkeleton />
  }

  if (error || !cohort) {
    return <ErrorMessage error="Something went wrong while loading the course modules" />
  }

  if (pathContainsEnd && !cohort.hasCohortOffboardingStarted) {
    return <Redirect to={`/cohorts/${slug}`} />
  }

  if (
    cohort.hasCohortOffboardingStarted &&
    !pathContainsEnd && // no need to redirect if they are already at /end
    weekId === 'end'
  ) {
    return <Redirect to={`/cohorts/${slug}/end`} />
  }

  const scheduledWeeksWithEvents = filterForScheduledWeeksWithEvents(
    cohort
  ) as CohortDashboardScheduledWeekPartsFragment[]

  if (!scheduledWeeksWithEvents.length) {
    return <ErrorMessage error="Something went wrong while loading the course modules" />
  }

  // pass down all ask an expert event IDs for the cohort to include in the batch RSVP
  // The batch rsvp mutation checks whether they are aleady RSVPd before RSVP'ing
  const askAnExpertEventsForRsvp: CohortEvent[] = []
  data.cohort.scheduledWeeks.forEach((week) => {
    const combinedEvents = combineSameEvents(week.events)
    const aeEvent = combinedEvents.find(
      (combinedEvent) =>
        combinedEvent.name.toLowerCase().includes('ask an expert') ||
        combinedEvent.slug === 'ask-an-expert'
    )
    if (aeEvent) {
      askAnExpertEventsForRsvp.push(aeEvent)
    }
  })

  return (
    <div
      className={twMerge(
        'flex w-full flex-col space-y-10 overflow-scroll',
        pathContainsEnd ? 'pb-0 md:pb-28 xl:pb-96' : ' pb-28 xl:pb-96'
      )}
    >
      <CohortDashboardWeeklyBlock
        currentUser={data?.currentUser}
        onWeekClick={onWeekClick}
        scheduledWeeksWithLessons={scheduledWeeksWithEvents}
        slug={slug}
        weekId={weekId || scheduledWeeksWithEvents[0]?.id}
        weekIndex={scheduledWeeksWithEvents.findIndex(
          (scheduledWeek) => scheduledWeek.id === weekId
        )}
        askAnExpertEventsForRsvp={askAnExpertEventsForRsvp}
        templates={getTemplates}
        cohort={cohort}
        gettingStartedModule={cohort.gettingStartedModuleForSeason}
        pathContainsEnd={pathContainsEnd || false}
      />
    </div>
  )
}

export default CohortDashboardProgram
