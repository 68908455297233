import { InfoIcon, Trash2Icon } from 'lucide-react'
import { useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { Seat } from 'gql'

import { PendingInvite } from './PendingInvite'

export interface PendingInviteListRowProps {
  pendingSeat: Seat
  handleUnassignSeat: (id: string) => void
  handleResendInvitation: (pendingSeat: Seat) => void
}

export const PendingInviteListRow = ({
  pendingSeat,
  handleUnassignSeat,
  handleResendInvitation
}: PendingInviteListRowProps) => {
  const pendingMember = pendingSeat.subscriptionMember!
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [resendingInvitationState, setResendingInvitation] = useState(false)

  const handleShowConfirmation = () => {
    setShowConfirmation(true)
  }

  const handleHideConfirmation = () => {
    setShowConfirmation(false)
  }

  const handleConfirmUnassign = () => {
    handleUnassignSeat(pendingSeat.id)
    setShowConfirmation(false)
  }

  const handleResendInvitationClick = (pendingSeat: Seat) => {
    setResendingInvitation(true)
    handleResendInvitation(pendingSeat)
    setTimeout(() => {
      // Simulate a delay, purely for UX purposes
      setResendingInvitation(false)
    }, 1000)
  }

  return (
    <>
      {showConfirmation ? (
        <div className="flex justify-between items-center border border-red-500">
          <div className="flex-l text-[12px]">
            <div className="p-3">
              Remove this member ({pendingMember.email})?
              <InfoIcon
                width={16}
                height={16}
                className="inline-block ml-1"
                data-tip
                data-for={`tooltip-${pendingSeat.id}`}
              />
            </div>
          </div>
          <div className="flex-l flex items-center justify-end space-x-2 text-[12px] mr-4">
            <button
              onClick={handleConfirmUnassign}
              className="font-semibold text-red-500"
              data-tip="Unassign"
            >
              Yes
            </button>
            <button
              onClick={handleHideConfirmation}
              className="font-semibold text-gray-500"
            >
              No
            </button>
          </div>
          <ReactTooltip
            id={`tooltip-${pendingSeat.id}`}
            place="top"
            type="dark"
            effect="solid"
            multiline={true}
          >
            This seat will be empty but it&apos;ll <br />
            still be a part of your subscription. You
            <br />
            can invite a new member to this seat.
            <br />
          </ReactTooltip>
        </div>
      ) : (
        <div
          key={pendingMember.email}
          className="flex justify-between items-center border-b"
        >
          <div className="flex-l">
            <PendingInvite subscriptionMember={pendingMember} />
          </div>
          {resendingInvitationState ? (
            <div className="flex-l flex items-center justify-end space-x-2 text-[12px] text-green-500">
              Resending invitation...{pendingMember.email}
            </div>
          ) : (
            <>
              <div className="flex-l flex items-center justify-end space-x-2">
                <button
                  className="w-full p-1 sm:w-auto sm:p-2.5 sm:pt-1 sm:pb-1 text-[12px] text-gray-500 border border-black rounded-none sm:rounded-sm"
                  onClick={() => {
                    handleResendInvitationClick(pendingSeat)
                  }}
                >
                  Resend<span className="hidden-400">&nbsp;invitation</span>
                </button>
                <button onClick={handleShowConfirmation}>
                  <Trash2Icon
                    width={24}
                    height={24}
                    strokeWidth={1}
                    className="text-red-500"
                  />
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default PendingInviteListRow
