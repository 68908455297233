import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

import RadioButtonGroup from 'components/forms/RadioButtonGroup/RadioButtonGroup'

import { cn } from 'utils/tailwind'

import { Select } from '../../forms/Select'

export type DropdownSelectProps = {
  data: {
    label: string
    value: any
  }[]
  label?: string
  value?: any
  onChange?: (value: any) => void
  className?: string
  mobile?: boolean
  mobileDefaultOpen?: boolean
  optionsClassName?: string
  labelClassName?: string
  disabled?: boolean
  selectContainerClass?: string
  noBorder?: boolean
  dropdownClassName?: string
}

function DropdownSelect({
  data,
  mobile = false,
  mobileDefaultOpen = false,
  className,
  label,
  value = data[0].value,
  onChange,
  optionsClassName = '',
  labelClassName = '',
  disabled,
  selectContainerClass,
  noBorder,
  dropdownClassName
}: DropdownSelectProps) {
  if (mobile) {
    return (
      <Disclosure defaultOpen={mobileDefaultOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={cn(
                'flex h-[45px] w-full cursor-pointer items-center justify-between bg-white text-rb-black',
                className
              )}
            >
              <span className="text-base font-semibold leading-relaxed">{label}</span>
              <ChevronDownIcon
                className={cn(
                  'h-[16px] w-[16px] text-rb-black',
                  open && 'rotate-180 transform'
                )}
              />
            </Disclosure.Button>

            <Disclosure.Panel>
              <RadioButtonGroup onChange={onChange} defaultValue={value} data={data} />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )
  }

  const labelValue = label ? `${label}:` : ''
  return (
    <div className={className}>
      <div className={cn('font-sans text-sm font-normal', labelClassName)}>
        {`${labelValue}`}
      </div>{' '}
      <div className={cn('w-[160px]', noBorder && 'w-auto', selectContainerClass)}>
        <Select
          name="dropdownSelect"
          classNameAdditions={cn(
            'border border-rb-gray rounded w-full max-w-[160px]',
            noBorder && 'border-none w-max text-sm'
          )}
          className=""
          value={value}
          onChange={onChange}
          options={data.map((d) => {
            return { id: d.label, option: d.value }
          })}
          optionsClassName={optionsClassName}
          iconClassName={cn(noBorder && 'w-3 h-3 ml-2')}
          dropdownClassName={dropdownClassName}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default DropdownSelect
