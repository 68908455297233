import { CopyIcon } from 'lucide-react'
import { useState } from 'react'

import { getCanonicalOrigin } from 'utils/url'

export const InviteLink = ({
  subscriptionToken,
  canonicalUrl
}: {
  subscriptionToken: string
  canonicalUrl?: string
}) => {
  const canonicalOrigin = canonicalUrl || getCanonicalOrigin()
  const inviteLinkText = `${canonicalOrigin}?stoken=${subscriptionToken}`
  const [showStatus, setShowStatus] = useState(false)
  const [success, setSuccess] = useState(true)
  const handleCopy = () => {
    navigator.clipboard
      .writeText(inviteLinkText)
      .then(() => {
        setSuccess(true)
        setShowStatus(true)
        setTimeout(() => {
          setShowStatus(false)
          setSuccess(true)
        }, 1000)
      })
      .catch((err) => {
        setSuccess(false)
        setShowStatus(true)
        console.log(err)
        setTimeout(() => {
          setShowStatus(false)
          setSuccess(true)
        }, 1000)
      })
  }
  return (
    <>
      <h4 className="font-polysans text-rb-gray-400 font-semibold mb-0">
        Share invite link
      </h4>
      <p className="mt-0 mb-1 font-light text-[12px]">
        This link is only valid for users that have already been added to your team
      </p>
      {showStatus &&
        (success ? (
          <div
            className="flex justify-end text-green-500 text-[12px]"
            style={{ width: '100%' }}
          >
            Link copied to clipboard
          </div>
        ) : (
          <div
            className="flex justify-end text-red-500 text-[12px]"
            style={{ width: '100%' }}
          >
            Failed to copy link to clipboard
          </div>
        ))}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4 sm:mb-0">
        <div className="flex justify-between items-center text-gray-400 text-[12px] p-1 border border-gray-400 rounded overflow-x-auto sm:w-full sm:mr-4 mb-2 sm:mb-0">
          {inviteLinkText}
          <button onClick={handleCopy}>
            <CopyIcon strokeWidth={2} height={14} width={24} />
          </button>
        </div>
        <button
          className="w-full p-2 sm:w-auto sm:p-2.5 sm:pt-1.5 sm:pb-1.5 text-[12px] border border-black rounded-none sm:rounded-sm"
          onClick={handleCopy}
        >
          Copy&nbsp;link
        </button>
      </div>
    </>
  )
}

export default InviteLink
