import MuxPlayer from '@mux/mux-player-react'
import { ReactComponent as PlayIcon } from 'icon--play-rounded.svg'
import { Key, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import ExpertPreview from 'domains/Expert/ExpertPreview'

import BadgeableAvatar from 'components/BadgeableAvatar'
import Tag from 'components/Tag/Tag'
import RfHeader1 from 'components/typography/RfHeader/RfHeader1'

import {
  ArtifactAuthor,
  CclCourseSessionPartsFragment,
  CclExpert,
  CourseDetailPartsFragment
} from 'gql'

import { getCreatorInstructors, getNonCreatorInstructors } from 'utils/courseSessionUtils'
import { trackVideoPlayed } from 'utils/tracking/generated/events'

interface CourseDetailsPageTopSectionProps {
  courseDetails?: CourseDetailPartsFragment
  upcomingCourseSessions?: CclCourseSessionPartsFragment[] | null
}

const CourseDetailsPageTopSection = ({
  courseDetails,
  upcomingCourseSessions
}: CourseDetailsPageTopSectionProps) => {
  const [showTrailerOverlay, setShowTrailerOverlay] = useState(true)
  const playerRef = useRef<any>()

  const onPlay = () => {
    setShowTrailerOverlay(false)
    playerRef.current?.play()

    trackVideoPlayed({
      object_type: 'preview',
      related_identifiers: {
        ccl_course_id: courseDetails?.id,
        course_title: courseDetails?.title,
        course_sanity_id: courseDetails?.sourceId,
        location: 'course_details_page'
      }
    })
  }
  const onPause = () => {
    setShowTrailerOverlay(true)
  }

  const instructors = (upcomingCourseSessions && upcomingCourseSessions[0]?.experts) || []

  const creators = courseDetails?.creators || []
  const creatorInstructors = getCreatorInstructors(instructors, creators)

  const nonCreatorInstructors = getNonCreatorInstructors(instructors, creators)

  const creatorIsInstructor = (creatorId: string) => {
    return creatorInstructors.some((instructor) => instructor.id === creatorId)
  }

  const formatCourseFocus = (focus: string) => {
    const cleanedItems = focus
      .replace(/[[\]"]/g, '')
      .replace(/\\u0026/g, '&')
      .split(',')
    return cleanedItems.map((item) => item.trim()).join(', ')
  }

  return (
    <>
      {/* Hero Image / Video */}
      {(courseDetails?.trailerUrl || courseDetails?.heroImageUrl) && (
        <div className="max-w-[360px] xl:max-w-[440px] relative flex items-center justify-center">
          {courseDetails.trailerUrl && (
            <div>
              <MuxPlayer
                poster={`${courseDetails?.heroImageUrl}?auto=format&w=760` || undefined}
                ref={playerRef}
                playbackId={courseDetails.trailerUrl}
                streamType="on-demand"
                onPause={onPause}
                className={twMerge(
                  'h-auto w-full',
                  showTrailerOverlay && '[--controls:none]'
                )}
                onPlay={() => setShowTrailerOverlay(false)}
              />

              {showTrailerOverlay && (
                <button
                  onClick={onPlay}
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50"
                >
                  <PlayIcon className="h-16 w-16 text-white" />
                </button>
              )}
            </div>
          )}

          {!courseDetails.trailerUrl && courseDetails?.heroImageUrl && (
            <img
              src={`${courseDetails?.heroImageUrl}?auto=format&w=760`}
              alt={courseDetails?.title || ''}
              className="h-full w-full object-cover"
            />
          )}
        </div>
      )}

      <RfHeader1 className="!mb-0 font-polysans !font-light">
        <div className="text-[32px] font-light">{courseDetails?.title}</div>
      </RfHeader1>

      {/* Course description */}
      {courseDetails?.shortDescription && (
        <div className="font-polysans !font-light text-rb-gray-400">
          {courseDetails?.shortDescription}
        </div>
      )}

      {/* Skills gained */}
      {courseDetails?.focus && courseDetails?.focus !== 'null' && (
        <div className="text-rb-gray-400">
          <span className="font-semibold">Skills you’ll learn:</span>{' '}
          {formatCourseFocus(courseDetails.focus)}
        </div>
      )}

      {/* Noncreator Instructors */}
      {nonCreatorInstructors.map(
        (instructor: CclExpert, index: Key | null | undefined) => (
          <Link to={`/profiles/${instructor.slug}`} rel="noreferrer" key={index}>
            <div className="flex gap-3 text-sm items-center">
              <BadgeableAvatar
                fullName={instructor.name || ''}
                avatarUrl={instructor.avatarUrl || ''}
                width={20}
                height={20}
              />
              <div className="text-rb-gray-400">{instructor.name}</div>
              <div className="text-rb-gray-300">
                {instructor.position} @ {instructor.companyName}
              </div>
              <Tag variant="light">Instructor</Tag>
            </div>
          </Link>
        )
      )}

      {/* Course Creators */}
      {courseDetails?.creators && courseDetails.creators.length > 0 && (
        <div className="flex flex-col items-start gap-4 sm:flex-row">
          {courseDetails?.creators?.map(
            (creator: ArtifactAuthor | CclExpert, index: Key | null | undefined) => {
              if (!creator) return null
              const isInstructor = creatorIsInstructor(creator.id)
              return nonCreatorInstructors.length > 0 ? (
                <div className="flex flex-col">
                  <div className="text-rb-gray-300 text-sm pb-1.5">
                    Course created by:
                  </div>
                  <Link to={`/profiles/${creator.slug}`} rel="noreferrer" key={index}>
                    <ExpertPreview
                      expert={creator}
                      containerClassName={twMerge(
                        'min-w-[250px] max-w-[250px] w-full',
                        isInstructor && 'max-w-[275px]'
                      )}
                      isInstructor={isInstructor}
                    />
                  </Link>
                </div>
              ) : (
                <Link to={`/profiles/${creator.slug}`} rel="noreferrer" key={index}>
                  <ExpertPreview
                    expert={creator}
                    containerClassName={twMerge(
                      'min-w-[250px] max-w-[250px] w-full',
                      isInstructor && 'max-w-[275px]'
                    )}
                    isInstructor={isInstructor}
                  />
                </Link>
              )
            }
          )}
        </div>
      )}
    </>
  )
}

export default CourseDetailsPageTopSection
