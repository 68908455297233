import { useLocation } from 'react-router-dom'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import BookmarkCard from 'domains/Bookmarks/BookmarkCard/BookmarkCard'
import { useUserBookmarks } from 'domains/Bookmarks/hooks'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { useSideDrawer } from 'components/SideDrawer/SideDrawer'

import { BookmarkType, ProgramBookmarkPartsFragment, useBookmarkFoldersQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { track } from 'utils/tracking/analytics'

import { EmptyStateCard } from '../EmptyStateCard'

export interface DiscoveryDrawerBookmarksPanelProps {
  cmsProgramId: string
  cmsProgramName: string
  cmsModuleId: string
  cmsSectionId?: string
  cmsContent?: any
  isActive?: boolean
}

export const DiscoveryDrawerBookmarksPanel = ({
  cmsModuleId,
  cmsProgramId,
  cmsSectionId = '',
  cmsContent,
  isActive = false
}: DiscoveryDrawerBookmarksPanelProps) => {
  const { id: userId } = useAssertCurrentUser() || {}
  const {
    bookmarks: { items },
    isLoading
  } = useUserBookmarks({ userId, cmsModuleId, cmsProgramId, cmsSectionId })
  const { pathname } = useLocation()

  const { bookmarkId, isDrawerOpen } = useSideDrawer()
  const { data: bookmarkFolderData } = useBookmarkFoldersQuery()
  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const trackingEvent = (id: string) => {
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      user_id: userId,
      cms_program_id: cmsProgramId,
      module_id: cmsModuleId,
      lesson_id: cmsContent?.id,
      anchor: id,
      action: 'click_bookmark_content_viewer_drawer'
    })
  }

  // When showing bookmarks on a concept or lesson, filter usebookmark by content href because content href filter is not yet available on gql query
  const itemsSinLessonLevel = items?.filter(
    (bookmark: ProgramBookmarkPartsFragment) =>
      (bookmark?.content?.href === pathname &&
        ![
          BookmarkType.LESSONBOOKMARK,
          BookmarkType.SECTIONBOOKMARK,
          BookmarkType.PROJECTBOOKMARK
        ].includes(bookmark.type)) ||
      []
  )
  const bookmarks = cmsContent
    ? itemsSinLessonLevel?.filter(
        (bookmark: ProgramBookmarkPartsFragment) => bookmark?.content?.href === pathname
      )
    : itemsSinLessonLevel
  return isActive ? (
    <>
      <div
        data-testid="discovery-drawer-bookmarks-panel"
        className="w-screen overflow-auto px-6 md:w-auto md:min-w-[350px]"
      >
        <div className="mt-6 text-xs font-medium uppercase">
          <span>Highlights</span>
          <span className="pl-4 text-rb-gray-300">{bookmarks?.length || ''}</span>
        </div>
        {!!bookmarks?.length &&
          bookmarks.map((bookmark: ProgramBookmarkPartsFragment, idx: number) => (
            <BookmarkCard
              key={bookmark.id}
              bookmark={bookmark}
              className="mt-6 last:mb-3"
              track={() => trackingEvent(bookmark.id)}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              isActiveBookmark={bookmarkId === bookmark.id}
              hasProductTour={idx === 0 && isDrawerOpen('discoveryDrawer')}
            />
          ))}
        {!bookmarks?.length && !isLoading && (
          <EmptyStateCard
            title="Create your first highlight"
            body="Drag some text or click an image to bring up the highlighting button and add it to your library!"
            show={true}
          />
        )}
      </div>
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction={'bookmark_panel'}
      />
      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={() => {
          openCreateBookmarkFolderModal(currentBookmarkForDropdown)
          closeAddToBookmarkFolderModal()
        }}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={
          !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
        }
      />
    </>
  ) : null
}

export default DiscoveryDrawerBookmarksPanel
