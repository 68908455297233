import { useEffect, useState } from 'react'

import {
  Seat,
  TeamMembersPageDocument,
  useResendInvitationToSubscriptionMemberMutation,
  useUnassignSeatMutation
} from 'gql'

import { displayErrorToast, displaySuccessToast } from 'utils/toastService'

import PendingInviteListRow from './PendingInviteListRow'

export interface PendingInviteListProps {
  pendingSeats: Seat[]
  currentUserId: string
  refetchSubscription: () => void
  onMaxEmailsChange: (count: number) => void
}

export const PendingInviteList = ({
  pendingSeats,
  currentUserId,
  refetchSubscription,
  onMaxEmailsChange
}: PendingInviteListProps) => {
  const [pendingMemberSeats, setPendingMemberSeats] = useState(pendingSeats || [])
  const [unassignSeat] = useUnassignSeatMutation()
  const [resendInvitationToSubscriptionMember] =
    useResendInvitationToSubscriptionMemberMutation()
  const [loadingState, setLoadingState] = useState(false)
  const [currentPendingMember, setCurrentPendingMember] = useState<Seat | null>(null)

  useEffect(() => {
    setPendingMemberSeats(pendingSeats)
  }, [pendingSeats])

  const resendInvitationToAllPendingSeats = async (): Promise<void> => {
    setLoadingState(true)
    for (let index = 0; index < pendingMemberSeats.length; index++) {
      setCurrentPendingMember(pendingMemberSeats[index])
      await resendInvitation(pendingMemberSeats[index])
    }
    setLoadingState(false)
    setCurrentPendingMember(null)
  }

  const resendInvitation = async (pendingSeat: Seat): Promise<void> => {
    await resendInvitationToSubscriptionMember({
      variables: {
        input: {
          subscriptionMemberId: pendingSeat.subscriptionMember!.id
        }
      }
    })
  }

  const unassignPendingSeat = async (id: string): Promise<void> => {
    try {
      const result = await unassignSeat({
        variables: {
          input: {
            id
          }
        },
        refetchQueries: [
          {
            query: TeamMembersPageDocument,
            variables: { id: currentUserId }
          }
        ]
      })
      if (result.data?.unassignSeat?.errors || !result.data?.unassignSeat?.seat) {
        displayErrorToast({
          message: result.data?.unassignSeat?.errors
            ? result.data.unassignSeat.errors[0]
            : 'Unable to remove member'
        })
      } else {
        displaySuccessToast({ message: "You've successfully removed this member" })
        setPendingMemberSeats(pendingMemberSeats.filter((seat) => seat.id !== id))
        refetchSubscription() // Refetch the pending seats
        updateSeatCount(1) // Update the seat count
      }
    } catch (error) {
      displayErrorToast({
        message: 'An error occurred while trying to remove the member'
      })
    }
  }

  const updateSeatCount = (count: number) => {
    onMaxEmailsChange(count)
  }
  return (
    <div className="p-4 pt-0 w-full mb-2">
      <h4 className="font-polysans text-rb-gray-400 font-semibold mb-0">
        Manage pending invitations
      </h4>
      {pendingMemberSeats.length !== 0 && (
        <>
          <button
            className="mt-0 mb-1 font-light text-[12px] underline"
            onClick={() => resendInvitationToAllPendingSeats()}
            disabled={loadingState}
          >
            Resend invitations to all
          </button>
          {loadingState && (
            <div className="text-[12px] text-green-500">
              Resending invitations...
              {currentPendingMember?.subscriptionMember?.email || ''}
            </div>
          )}
        </>
      )}
      <div className="flex flex-col overflow-y-auto h-40 max-h-40">
        {pendingMemberSeats.length > 0 ? (
          pendingMemberSeats
            .filter((seat) => seat.subscriptionMember !== undefined)
            .map((seat) => (
              <PendingInviteListRow
                key={seat.subscriptionMember!.email}
                pendingSeat={seat}
                handleUnassignSeat={unassignPendingSeat}
                handleResendInvitation={resendInvitation}
              />
            ))
        ) : (
          <div className="text-gray-500 text-[12px] italic">No pending invitations</div>
        )}
      </div>
    </div>
  )
}

export default PendingInviteList
