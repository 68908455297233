export interface ProgressBarProps {
  totalCount: number
  usedCount: number
  countNoun: 'seat' | 'pass'
}

const nounPluralMap: Record<ProgressBarProps['countNoun'], string> = {
  seat: 'seats',
  pass: 'passes'
}

export const ProgressBar = ({ totalCount, usedCount, countNoun }: ProgressBarProps) => {
  const getProgress = () => {
    const progressPercent = totalCount === 0 ? 0 : (usedCount / totalCount) * 100
    return progressPercent
  }

  const classNames =
    countNoun === 'seat'
      ? 'bg-rb-teal-200 border-rb-teal-200'
      : 'bg-rb-blue-100 border-rb-blue-100'
  const availableSeatsClassName =
    totalCount === usedCount ? 'rounded-lg' : 'rounded-l-lg '

  const pluralizedNoun = nounPluralMap[countNoun]

  const progressDescriptor = (() => {
    if (totalCount === 0) {
      return `No ${pluralizedNoun} available`
    } else if (usedCount === totalCount && totalCount > 0) {
      return `${usedCount} of ${totalCount} ${totalCount === 1 ? countNoun : pluralizedNoun} assigned`
    } else if (usedCount === 0 && totalCount > 0) {
      return `No ${pluralizedNoun} assigned`
    } else {
      return `${usedCount} of ${totalCount} ${totalCount === 1 ? countNoun : pluralizedNoun} assigned`
    }
  })()

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <span className="mb-1 text-sm font-medium text-rb-gray-300">
          {progressDescriptor}
        </span>

        <div className="mr-4 flex h-3 w-full justify-start rounded-lg border bg-rb-gray-100">
          <div
            style={{ width: `${getProgress()}%` }}
            className={`${classNames} ${availableSeatsClassName}`}
          ></div>
        </div>
      </div>
    </div>
  )
}
