import Button from 'components/Button'

export interface MultiInviteStatusModalProps {
  emailErrors: { email: string; errors: string }[]
  successes: boolean
  close: () => void
}

export const MultiInviteStatusModal = ({
  emailErrors,
  successes,
  close
}: MultiInviteStatusModalProps) => {
  return (
    <div className="mb-2">
      <div className="text-center border p-2 text-[12px]">
        {successes ? (
          <>
            <h2 className="mb-0 font-polysans leading-[1.2] tracking-[-4%] text-rb-gray-400 font-medium text-[20px] sm:text-2xl">
              Success! Invitations have been sent
            </h2>
            <p className="text-sm text-gray-600">
              You can also share the link below directly to your invited team members via
              the channel of your choice.
            </p>
          </>
        ) : (
          <>
            <h2 className="mb-0 font-polysans leading-[1.2] tracking-[-4%] text-rb-gray-400 font-medium text-[20px] sm:text-2xl">
              No invitations have been sent
            </h2>
          </>
        )}
        {emailErrors && emailErrors.length > 0 && (
          <>
            <p className="text-[12px] text-red-600">
              {successes
                ? 'However, there were a few invites that failed to send. This may mean that the member has already ' +
                  'been invited or you ran out of seats. Please check the emails below and try again.'
                : 'Unfortunately no invites were sent. This may mean that the member has already been invited or ' +
                  'you have no seats remaining to fill. Please check the emails below and try again.'}
            </p>
            <p className="text-sm text-gray-600">
              We were unable to send the following invites:
            </p>
            <div className="mt-4 overflow-y-auto h-40 max-h-40 border">
              {emailErrors?.map((error, index) => (
                <div
                  key={index + error.email}
                  className="flex items-center justify-between border-b"
                >
                  <span className="ml-4">{error.email},</span>
                  {console.log('invite error', error)}
                </div>
              ))}
            </div>
          </>
        )}

        <div className="mt-2 flex justify-end">
          <Button className="p-1" onClick={() => close()}>
            Got it
          </Button>
        </div>
      </div>
    </div>
  )
}
