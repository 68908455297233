import { Link } from 'react-router-dom'

import FreePill from 'components/FreePill'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

import { formatTitle } from '../utils'
import { ContentResultItemProps } from './SimilaritySearchResultItem'

const ArtifactItem = ({
  searchDocument,
  searchQuery,
  onResultClick,
  impressionRef,
  currentUser
}: ContentResultItemProps) => {
  const { author, companyName, tags, title, thumbnail, url, isFree } = searchDocument

  return (
    <Link to={url} onClick={onResultClick}>
      {(!currentUser || currentUser?.is?.premember) && isFree && (
        <div className="absolute top-3 right-3 z-1">
          <FreePill />
        </div>
      )}
      <div
        ref={impressionRef}
        className="flex rounded border border-rb-gray-100 p-4 hover:shadow-card-hover xs:p-6"
      >
        <div className="relative flex-1">
          <RfParagraphLarge className="mb-0 font-medium">
            <span dangerouslySetInnerHTML={{ __html: formatTitle(searchQuery, title) }} />
          </RfParagraphLarge>
          <div className="mt-1 text-rb-black">
            <div>
              <span className="font-medium">{author}</span>
              {!!companyName && (
                <span className="hidden lg:inline"> @ {companyName}</span>
              )}
            </div>
            <div className="mt-4 flex items-center justify-center space-x-2 sm:items-start sm:justify-start">
              {tags.map((tag, idx) => {
                return (
                  <div
                    key={`tag-${idx}`}
                    className="text-ellipsis rounded-full bg-rb-gray-100 py-1 px-3 text-sm line-clamp-1 sm:text-xs"
                  >
                    {tag}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="relative hidden pl-6 sm:block">
          {thumbnail && (
            <img
              src={thumbnail}
              className="h-fit max-h-28 w-28 object-right lg:w-44"
              alt=""
            />
          )}
        </div>
      </div>
    </Link>
  )
}

export default ArtifactItem
