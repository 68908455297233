import { useEffect, useRef, useState } from 'react'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

export interface TabBarProps {
  onTabChange: (tab: TabKey) => void
  initialTab?: TabKey
  tabs: Tab[]
  trackingLocation: string
  size?: 'small' | 'large'
  containerClassName?: string
  fullWidthBar?: boolean
  stateHandledExternally?: boolean
}

type Tab = {
  label: string
  key: TabKey
}

export type TabKey = string

const TabBar = ({
  tabs,
  onTabChange,
  initialTab,
  trackingLocation,
  size = 'large',
  containerClassName,
  fullWidthBar,
  stateHandledExternally = false // if true, the activeTab state is handled externally and this component will not manage it. Update the initialTab prop to update the active tab.
}: TabBarProps) => {
  const [activeTab, setActiveTab] = useState<TabKey>(initialTab ?? tabs[0].key)
  const [activeTabWidth, setActiveTabWidth] = useState<number>(0)
  const [activeTabLeft, setActiveTabLeft] = useState<number>(0)
  const tabBarRef = useRef<HTMLDivElement>(null)
  const hasMounted = useRef(false)

  useEffect(() => {
    const updateActiveTabStyle = () => {
      const activeTabEl = tabBarRef.current?.querySelector<HTMLButtonElement>(
        `[data-tab-key="${activeTab}"]`
      )
      setActiveTabWidth(activeTabEl?.offsetWidth ?? 0)
      setActiveTabLeft(
        (activeTabEl?.offsetLeft || 0) - (tabBarRef.current?.offsetLeft || 0) ?? 0
      )
    }

    updateActiveTabStyle()
    window.addEventListener('resize', updateActiveTabStyle)

    return () => {
      window.removeEventListener('resize', updateActiveTabStyle)
    }
  }, [activeTab, tabs, initialTab])

  useEffect(() => {
    if (initialTab && stateHandledExternally) {
      setActiveTab(initialTab)
    }
  }, [initialTab, stateHandledExternally])

  useEffect(() => {
    if (hasMounted.current) {
      const activeTabEl = tabBarRef.current?.querySelector<HTMLButtonElement>(
        `[data-tab-key="${activeTab}"]`
      )

      activeTabEl?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      })
    } else {
      hasMounted.current = true
    }
  }, [activeTab])

  const handleTabChange = (tab: Tab) => {
    if (!stateHandledExternally) {
      setActiveTab(tab.key)
    }
    onTabChange(tab.key)
    trackNavigationClicked({
      type: 'tab',
      text: tab.label,
      location: trackingLocation
    })
  }

  return (
    <div className={cn('flex justify-center', containerClassName)}>
      {/* the below bottom padding is to account for the scrollbar that can appear under the tabs. Please don't remove */}
      <div className={cn('overflow-x-auto pb-3', fullWidthBar && 'w-full')}>
        <div className={cn('w-max', fullWidthBar && 'min-w-max w-full')}>
          <div className="flex mb-2" ref={tabBarRef}>
            {tabs.map((tab) => (
              <button
                key={tab.key}
                onClick={() => handleTabChange(tab)}
                className={cn(
                  'font-polysans text-lg md:text-2xl leading-[1] md:leading-[1] tracking-[-0.36px] md:tracking-[-0.48px] px-12 py-2 shrink-0',
                  size === 'small' && 'font-sans text-sm md:text-sm tracking-normal px-6',
                  size === 'small' && tab.key === activeTab && 'font-semibold'
                )}
                data-tab-key={tab.key}
              >
                {tab.label}
              </button>
            ))}
          </div>

          <div className="h-1 w-full bg-rb-gray-100">
            <div
              className="h-1 bg-rb-blue-150 transition-all duration-300 relative left-0"
              style={{
                width: `${activeTabWidth}px`,
                left: `${activeTabLeft}px`
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabBar
