import React, { MutableRefObject, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import AudioPlayer from 'components/AudioPlayer/AudioPlayer'
import Button from 'components/Button'
import Countdown from 'components/Countdown/Countdown'
import { ToggleSwitch } from 'components/ToggleSwitch'
import Tooltip from 'components/Tooltip/Tooltip'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { useListeningModeContext } from './ListeningModeContextProvider'

interface ListeningModeProps {
  src: string
  autoAdvanceUrl?: string | null
}

const autoAdvanceDelayMs = 3000
const listeningModeAutoAdvanceKey = 'listening-mode-auto-advance-on'

const isListeningModeAutoAdvanceOn = () =>
  window.localStorage.getItem(listeningModeAutoAdvanceKey) === 'true'

export const ListeningMode = React.memo(function ListeningMode({
  src,
  autoAdvanceUrl = ''
}: ListeningModeProps) {
  const {
    isOpen,
    close,
    trackingMetadata,
    setCurrentTime,
    currentTime,
    setTrackEnded,
    autoAdvancing,
    setAutoAdvancing,
    setDuration
  } = useListeningModeContext()

  const [reloadAudio, setReloadAudio] = useState(false)

  const [timerId, setTimerId] = useState<any>()
  const [autoAdvanceOn, setAutoAdvanceOn] = useState(isListeningModeAutoAdvanceOn())

  const history = useHistory()

  const { listeningModeAutoAdvance } = useFeatureFlags()

  const showAutoAdvance = listeningModeAutoAdvance && !!autoAdvanceUrl

  useEffect(() => {
    if (reloadAudio) {
      setReloadAudio(false)
    }
  }, [reloadAudio])

  if (!isOpen) {
    return null
  }

  const tooltipText = 'Auto advance'

  const toggleListeningModeAutoAdvance = () => {
    const newValue = !autoAdvanceOn

    window.localStorage.setItem(listeningModeAutoAdvanceKey, newValue.toString())

    setAutoAdvanceOn(newValue)

    trackCtaClicked({
      cta_type: 'button',
      cta_location: trackingMetadata.ctaLocation,
      text: tooltipText,
      access_policy_kind: trackingMetadata.accessPolicyKind,
      related_identifiers: {
        ...trackingMetadata.relatedIdentifiers,
        path: window.location.pathname,
        url: window.location.href,
        auto_advance_new_value: newValue
      }
    })
  }

  const handleOnAudioEnded = () => {
    setTrackEnded(true)

    if (showAutoAdvance && autoAdvanceOn) {
      setAutoAdvancing(true)
      const timerId = setTimeout(() => {
        setReloadAudio(true)
        setAutoAdvancing(false)
        history.push(autoAdvanceUrl)
      }, autoAdvanceDelayMs)

      setTimerId(timerId)
    }
  }

  const handleAutoAdvanceCancel = () => {
    setReloadAudio(true)
    setAutoAdvancing(false)
    clearTimeout(timerId)
    trackCtaClicked({
      cta_type: 'button',
      cta_location: trackingMetadata.ctaLocation,
      text: 'Cancel',
      access_policy_kind: trackingMetadata.accessPolicyKind,
      related_identifiers: {
        ...trackingMetadata.relatedIdentifiers,
        path: window.location.pathname,
        url: window.location.href
      }
    })
  }

  const handleAutoAdvanceContinue = () => {
    setReloadAudio(true)
    clearTimeout(timerId)
    setAutoAdvancing(false)
    trackCtaClicked({
      cta_type: 'button',
      cta_location: trackingMetadata.ctaLocation,
      text: 'Continue',
      access_policy_kind: trackingMetadata.accessPolicyKind,
      related_identifiers: {
        ...trackingMetadata.relatedIdentifiers,
        path: window.location.pathname,
        url: window.location.href
      }
    })
    history.push(autoAdvanceUrl || '')
  }

  const handleLoadedMetadata = (audioRef: MutableRefObject<HTMLAudioElement>) => {
    setDuration(audioRef.current.duration)
  }

  const autoAdvanceOverlay = listeningModeAutoAdvance ? (
    <div
      id="auto-advance"
      className="bg-black sm:w-[590px] h-[85px] w-full text-white content-center"
    >
      <div className="flex flex-row justify-between h-fit">
        <div className="flex flex-row space-x-2">
          Advancing to next guide in <Countdown seconds={autoAdvanceDelayMs / 1000} />s
        </div>
        <div className="flex flex-row space-x-4">
          <Button onClick={handleAutoAdvanceCancel} className="border-white py-2 px-4">
            Cancel
          </Button>
          <Button onClick={handleAutoAdvanceContinue} className="border-white py-2 px-4">
            Continue
          </Button>
        </div>
      </div>
    </div>
  ) : null

  const autoAdvanceToggle = showAutoAdvance ? (
    <Tooltip place="top" contentWrapperClassname="w-fit hover" tooltipBody={tooltipText}>
      <ToggleSwitch
        className="ml-3 mt-1 p-0 m-0"
        toggled={autoAdvanceOn}
        handleClick={toggleListeningModeAutoAdvance}
      />
    </Tooltip>
  ) : null

  return (
    <div
      data-testid="listening-mode-popover"
      className={twMerge(
        'bottom-4 w-[92%] sm:w-fit mx-auto flex h-fit fixed sm:sticky z-10'
      )}
    >
      <AudioPlayer
        autoplay
        reloadAudio={reloadAudio}
        overlay={autoAdvancing ? autoAdvanceOverlay : null}
        src={src}
        currentTimeOverride={{ currentTime, setCurrentTime }}
        showCloseButton
        onClose={close}
        onEnded={handleOnAudioEnded}
        onLoadedMetadata={handleLoadedMetadata}
        trackingMetadata={{
          ...trackingMetadata,
          relatedIdentifiers: {
            ...trackingMetadata.relatedIdentifiers,
            listening_mode_auto_advance_on: autoAdvanceOn
          }
        }}
        footerComponent={autoAdvanceToggle}
      />
    </div>
  )
})

export default React.memo(ListeningMode)
