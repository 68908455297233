import { useCallback, useEffect } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'

import { usePage } from 'components/PageHeader/usePage'
import { useFilter } from 'components/Pill'

import { capitalizeFirstLetter } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import {
  MARKETPLACE_COURSE_TYPE_LEGACY,
  MARKETPLACE_COURSE_TYPE_MARKETPLACE
} from './CourseCard'

export const COURSE_FILTER_ON_DEMAND = 'on-demand'
export const COURSE_FILTER_LIVE = 'live'
export const COURSE_FILTER_ALL_COURSES = 'all'

export const getCourseTypeFromCourseFilter = (courseFilter: string) => {
  if (courseFilter === COURSE_FILTER_ON_DEMAND) {
    return MARKETPLACE_COURSE_TYPE_LEGACY
  }

  if (courseFilter === COURSE_FILTER_LIVE) {
    return MARKETPLACE_COURSE_TYPE_MARKETPLACE
  }

  return null
}

export const useGetFilterFromPath = () => {
  const { pathname } = useLocation()
  if (matchPath(pathname, { path: `/courses/${COURSE_FILTER_ON_DEMAND}` })) {
    return COURSE_FILTER_ON_DEMAND
  }

  if (matchPath(pathname, { path: `/courses/${COURSE_FILTER_LIVE}` })) {
    return COURSE_FILTER_LIVE
  }

  return null
}

export const useActiveCourseFilter = () => {
  const filterFromPath = useGetFilterFromPath()
  const { isActive, activePill, setActivePill } = useFilter(
    filterFromPath || COURSE_FILTER_LIVE
  )

  return {
    courseFilterIsActive: isActive,
    activeCourseFilter: activePill,
    setActiveCourseFilter: setActivePill
  }
}

export const useCoursesFilters = () => {
  const { setPageTabs } = usePage()
  const history = useHistory()
  const { courseFilterIsActive, setActiveCourseFilter, activeCourseFilter } =
    useActiveCourseFilter()
  const filterFromPath = useGetFilterFromPath()

  useEffect(() => {
    // Ensure the filter state reflects the filter in the path
    // (use case - make sure active filter reflects filter in path when browser back button used)
    if (filterFromPath && filterFromPath !== activeCourseFilter) {
      setActiveCourseFilter(filterFromPath)
    }
  }, [filterFromPath, activeCourseFilter, setActiveCourseFilter])

  const handleFilterOnClick = useCallback(
    (courseFilter: string) => {
      setActiveCourseFilter(courseFilter)
      const destination = `/courses/${courseFilter}`
      trackCtaClicked({
        destination: destination,
        cta_location: 'courses_course_filter',
        text: courseFilter,
        cta_type: 'card'
      })
      history.push(destination)
    },
    [history, setActiveCourseFilter]
  )

  useEffect(() => {
    setPageTabs([
      {
        label: `${capitalizeFirstLetter(COURSE_FILTER_LIVE)}`,
        onClick: () => handleFilterOnClick(COURSE_FILTER_LIVE),
        isActive: courseFilterIsActive(COURSE_FILTER_LIVE),
        dataTest: 'course-filter-pill'
      },
      {
        label: `${capitalizeFirstLetter(COURSE_FILTER_ON_DEMAND)}`,
        onClick: () => handleFilterOnClick(COURSE_FILTER_ON_DEMAND),
        isActive: courseFilterIsActive(COURSE_FILTER_ON_DEMAND),
        dataTest: 'course-filter-pill'
      }
    ])

    return () => {
      setPageTabs(null)
    }
  }, [setPageTabs, handleFilterOnClick, courseFilterIsActive])

  return null
}
