import React, { useCallback, useEffect, useRef, useState } from 'react'

import Button from 'components/Button'
import { CloseIcon } from 'components/icons'

import { EMAIL_REGEX } from 'constants/regexes'

import { EmailChip } from './components/EmailChip'

export interface EmailChipsInputProps {
  onEmailsChange: (emails: string[]) => void
  maxEmails?: number
  maxEmailsError?: string
  zeroEmailsError?: string
  turnOffEmailCountValidation?: boolean
  disallowedEmails: string[]
  setHasDisallowedEmailsInList: (present: boolean) => void
}
const EmailChipsInput = ({
  onEmailsChange,
  maxEmails,
  maxEmailsError,
  zeroEmailsError,
  turnOffEmailCountValidation,
  disallowedEmails,
  setHasDisallowedEmailsInList
}: EmailChipsInputProps) => {
  const [emails, setEmails] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [invalidEmails, setInvalidEmails] = useState<string[]>([])
  // keeps track of emails that are not allowed to be added to the list -- ie. pending or filled seats
  const [disallowedEmailsState, setDisallowedEmailsState] =
    useState<string[]>(disallowedEmails)
  // keeps track of emails that were added to the list but are already in the disallowedEmailsState
  const [disallowedEmailsErrorsState, setDisallowedEmailsErrorsState] = useState<
    string[]
  >([])
  const inputRef = useRef<HTMLInputElement>(null)

  const handleAddEmail = (email: string) => {
    if (email && !emails.includes(email)) {
      setEmails([...emails, email])
      onEmailsChange([...emails, email])
    }
  }

  const handleRemoveEmail = (email: string) => {
    const filteredEmails = emails.filter((e) => e !== email)
    setEmails(filteredEmails)
    handleRemoveDisallowedEmail(email)
    onEmailsChange(filteredEmails)
  }

  // calls the function sent from the parent component to update the state of the disallowedEmailsInList
  // so that it can adjust any UI elements that depend on this state
  useEffect(() => {
    if (emails && disallowedEmailsErrorsState.length > 0) {
      setHasDisallowedEmailsInList(true)
    } else {
      setHasDisallowedEmailsInList(false)
    }
    if (disallowedEmails) {
      setDisallowedEmailsState(disallowedEmails)
    }
  }, [
    emails,
    disallowedEmailsErrorsState,
    setHasDisallowedEmailsInList,
    disallowedEmails
  ])

  const handleAddDisallowedEmail = useCallback((email: string) => {
    setDisallowedEmailsErrorsState((prev) => [...prev, email])
  }, [])

  const handleRemoveDisallowedEmail = useCallback((email: string) => {
    setDisallowedEmailsErrorsState((prev) => prev.filter((e) => e !== email))
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleRemoveAllDisallowedEmails = () => {
    const filteredEmails = emails.filter(
      (email) => !disallowedEmailsErrorsState.includes(email)
    )
    setEmails(filteredEmails)
    setDisallowedEmailsErrorsState([])
    onEmailsChange(filteredEmails)
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault()
      if (EMAIL_REGEX.test(inputValue.trim())) {
        handleAddEmail(inputValue.trim())
        setInputValue('')
      }
    } else if (event.key === 'Backspace' && inputValue === '' && emails.length > 0) {
      setInputValue(emails[emails.length - 1])
      setEmails(emails.slice(0, -1))
    }
  }

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    setInvalidEmails([])

    const clipboardData = event.clipboardData.getData('Text')
    const cleanClipboardData = clipboardData.trim().replace(/,+$/, '') // remove surrounding whitespace and trailing commas
    const emailsToAdd = cleanClipboardData.split(/[\s,]+/).map((email) => email.trim())
    const validEmails = emailsToAdd
      .filter((email) => EMAIL_REGEX.test(email))
      .filter((email) => !emails.includes(email))
    const invalidEmails = emailsToAdd.filter((email) => !EMAIL_REGEX.test(email))

    setEmails((prevEmails) => [...prevEmails, ...validEmails])
    setInvalidEmails(invalidEmails)
    setInputValue('')
    onEmailsChange([...emails, ...validEmails])
  }

  const isMaxEmailsReached = maxEmails !== undefined && emails.length > maxEmails
  const isMaxEmailsZero = () => {
    if (maxEmails === undefined) return false
    return maxEmails === 0
  }

  return (
    <>
      {invalidEmails.length > 0 && (
        <div className="text-red-500 text-sm mt-2">
          There were invalid emails in your pasted list: {invalidEmails.join(', ')}
        </div>
      )}
      <div
        className="flex flex-wrap content-start justify-start items-start p-2 border border-gray-600 rounded cursor-text h-40 max-h-40 overflow-y-auto"
        onClick={handleInputClick}
        onKeyDown={handleInputKeyDown}
        tabIndex={0}
        role="button"
      >
        {emails.map((email) => (
          <EmailChip
            key={email}
            email={email}
            onRemove={handleRemoveEmail}
            disallowedEmails={disallowedEmailsState}
            onDisallowedEmail={handleAddDisallowedEmail}
          />
        ))}
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onPaste={handlePaste}
          placeholder="Enter email and press Enter or paste comma separated emails"
          className="flex-1 min-w-[150px] border-none outline-none text-sm"
          disabled={isMaxEmailsReached}
        />
      </div>
      {isMaxEmailsReached && !turnOffEmailCountValidation && (
        <div className="text-red-500 text-sm mt-2">
          {maxEmailsError ||
            `You have exceeded your seat limit.  Remove ${emails.length - maxEmails} seats.`}
        </div>
      )}
      {isMaxEmailsZero() && (
        <div className="text-red-500 text-sm mt-2">
          {zeroEmailsError || 'You have no remaining seats.  Remove seats or upgrade.'}
        </div>
      )}
      {disallowedEmailsErrorsState && disallowedEmailsErrorsState.length > 0 && (
        <>
          <div className="flex flex-col tl:flex-row tl:gap-0 tl:justify-between tl:items-center gap-1 mt-2 text-[#c73d23] text-sm font-normal font-['Inter'] leading-[21px]">
            <div>Highlighted emails have already been invited or are already users.</div>
            <Button
              className="flex items-center m-0 text-sm underline text-[#c73d23] !p-0"
              onClick={() => handleRemoveAllDisallowedEmails()}
              variant="text-only"
            >
              <CloseIcon className="h-4 w-4 stroke-1 mr-2" aria-hidden="true" />
              Remove all invalid emails
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default EmailChipsInput
