import { cn } from '@/lib/utils'
import { MouseEvent, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'

import TestimonialCard from 'pages/HomePage/LoggedOutHomepage/TestimonialCard'
import { FREE_TRIAL_DAYS } from 'pages/PurchaseFlow/constants'

import Button from 'components/Button'

import { PlanName, TestimonialWithImage, useTrackServerEventMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { trackCtaClicked } from 'utils/tracking/analytics'
import { getAnonymousId } from 'utils/tracking/segment'
import getRefererUrl from 'utils/url/getRefererUrl'

import { ReactComponent as CloseIcon } from 'images/p-cancel.svg'
import { ReactComponent as Checkmark } from 'images/p-checkmark-bold.svg'

const BENEFITS = [
  '1 live, expert-led course*',
  '35+ on-demand courses',
  '450+ step-by-step guides',
  '1,000+ real work examples',
  'AI-powered expert advice'
]
export const TryReforge = () => {
  const { abShowTryReforgeX } = useFeatureFlags()
  const { currentUser } = useCurrentUser()
  const searchParams = new URLSearchParams(location.search)
  const refererParam = searchParams.get('referer')
  const returnOnCloseParam = searchParams.get('returnOnClose')
  const refererUrl = getRefererUrl(refererParam)

  const teamPlansCta = `/subscribe/choose-plan?referer=${refererUrl ? encodeURIComponent(refererUrl) : 'trial-redirect'}${returnOnCloseParam ? `&returnOnClose=${returnOnCloseParam}` : ''}`
  const startTrialCta = `/subscribe/choose-plan?plan=${PlanName.INDIVIDUAL}${`&referer=${refererUrl ? encodeURIComponent(refererUrl) : 'trial-redirect'}`}${returnOnCloseParam ? `&returnOnClose=${returnOnCloseParam}` : ''}`

  const onCloseHref = returnOnCloseParam || refererUrl || '/'

  const [trackServerEvent] = useTrackServerEventMutation()

  useEffect(() => {
    trackServerEvent({
      variables: {
        input: {
          anonymousId: getAnonymousId(),
          event: 'Conversion Page Viewed - Server',
          properties: {
            access_policy_kind: currentUser?.accessPolicyKind,
            path: window.location.pathname,
            page_type: 'try_reforge_page',
            is_conversion_eligible: currentUser?.is.eligibleForTrial,
            logged_in: !!currentUser,
            url: window.location.href,
            referrer: document.referrer,
            trial_status: currentUser?.trialStatus
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!currentUser?.is.eligibleForTrial) {
    return <Redirect to="/" />
  }

  const onCtaClick = (e: MouseEvent<HTMLAnchorElement>) => {
    trackCtaClicked({
      cta_location: 'try_reforge_page',
      cta_type: 'button',
      destination: (e.target as HTMLAnchorElement).href || window.location.pathname,
      text: (e.target as HTMLAnchorElement).innerText.toLowerCase()
    })
  }

  return (
    <div className="grid h-[100dvh] lg:grid-cols-2">
      <div className="py-8 px-4 mx-auto sm:px-16 flex items-center">
        <div className="max-w-[480px] lg:max-w-[600px]">
          <div className="text-4xl font-semibold pb-2 tracking-tight">
            Try Reforge free for {FREE_TRIAL_DAYS} days
          </div>
          <div className="pb-8">
            Get access to everything Reforge has to offer with a {FREE_TRIAL_DAYS}-day
            free trial.
          </div>
          <div className="bg-rb-blue-50 rounded-xl py-6 px-8 mb-8">
            <div className="font-semibold pb-4">Individual subscription includes:</div>
            <div className="pb-4">
              {BENEFITS.map((benefit) => (
                <div key={benefit} className="flex items-center pb-2">
                  <Checkmark className="w-4 h-4" />
                  <div className="pl-6">{benefit}</div>
                </div>
              ))}
            </div>
            <div className="text-xs">
              Reforge membership costs $1,995 for the first year. Cancel your trial any
              time to avoid being charged.{' '}
              <span className="italic">
                *To use your live course pass, you must purchase the subscription.{' '}
              </span>
            </div>
          </div>
          <div className="flex flex-col-reverse xs:flex-row gap-2 xs:gap-4 mb-2">
            <Button
              fullWidth
              size="small"
              variant="outline"
              className="whitespace-nowrap font-semibold"
              onClick={onCtaClick}
              href={teamPlansCta}
            >
              View team plans
            </Button>
            <Button
              fullWidth
              size="small"
              color="premium"
              className="font-semibold whitespace-nowrap"
              onClick={onCtaClick}
              href={startTrialCta}
            >
              Start {FREE_TRIAL_DAYS}-day free trial
            </Button>
          </div>

          <Button
            fullWidth
            size="small"
            variant="text-only"
            className={cn(
              'text-rb-gray-300 font-semibold',
              abShowTryReforgeX && 'md:hidden'
            )}
            href={onCloseHref}
            onClick={onCtaClick}
          >
            Maybe later
          </Button>

          <Link
            className={cn(
              'hidden right-6 top-6 absolute text-rb-black hover:text-rb-black',
              abShowTryReforgeX && 'md:block'
            )}
            to={onCloseHref}
            onClick={(e) =>
              trackCtaClicked({
                cta_location: 'try_reforge_page',
                cta_type: 'button',
                destination:
                  (e.target as HTMLAnchorElement).href || window.location.pathname,
                text: 'x'
              })
            }
          >
            <CloseIcon className="w-5 h-5" />
          </Link>
        </div>
      </div>
      <div className="hidden bg-rb-orange-25 items-center justify-center lg:flex lg:w-full lg:flex-col py-8 px-4 mx-auto sm:px-16">
        <div className="max-w-[480px] lg:max-w-[600px] mx-auto">
          <TestimonialCard
            testimonial={
              {
                name: 'Jill Choi',
                positionAndCompany: 'Director of Product Management at SmugMug',
                quote:
                  'It’s a screaming deal. The investment is significantly lower than the cost of business graduate school credits or attending multiple conferences.',
                avatar: {
                  imageUrl: 'image-4497975eee94627aa2a41cd4afc4e00cafb168aa-800x800-png'
                }
              } as TestimonialWithImage
            }
          />
        </div>
      </div>
    </div>
  )
}

export default TryReforge
