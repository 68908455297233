import React, { useEffect, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  CollapsibleContentBlock,
  typeForDisplay
} from 'pages/UnitLandingPage/UnitContentBlock'

import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import { Breadcrumb } from 'components/Breadcrumb'
import RfHeader1 from 'components/typography/RfHeader/RfHeader1'

import { CourseDetailCourseBlockPartsFragment, useTrackServerEventMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { CourseRelatedIdentifierProperties } from 'utils/contentTrackingUtils'
import { getAnonymousId } from 'utils/tracking/analytics'

import { ReactComponent as LightBulbRaysIcon } from 'images/icon--light-bulb-rays.svg'
import { ReactComponent as VideoIcon } from 'images/icon--video.svg'

import CourseBlockContentBlockProgressConsumer from './CourseBlockContentBlockProgressConsumer'

interface CourseBlockContentBlockLandingPageProps {
  cclCourseBlock?: CourseDetailCourseBlockPartsFragment | null
  contentBlockSlug?: string
  backToCourseLabel: string
  backToCourseLink: string
  courseRelatedIdentifiers?: CourseRelatedIdentifierProperties
}

const CourseBlockContentBlockLandingPage: React.FC<
  CourseBlockContentBlockLandingPageProps
> = ({
  backToCourseLabel,
  backToCourseLink,
  cclCourseBlock,
  contentBlockSlug,
  courseRelatedIdentifiers
}) => {
  const [trackServerEvent] = useTrackServerEventMutation()
  const { currentUser } = useCurrentUser()
  const contentBlock = cclCourseBlock?.contentBlocks?.find(
    (block) => block.slug === contentBlockSlug
  )

  const videoTrackingMetadata = useMemo(
    () => ({
      consumption_format: 'video',
      content_id: contentBlock?.id,
      content_ccl_local_id: contentBlock?.id,
      content_mode: courseRelatedIdentifiers?.content_mode || '',
      content_name: contentBlock?.header || '',
      content_type: 'flex_content',
      related_identifiers: {
        ccl_course_local_id: courseRelatedIdentifiers?.ccl_course_id,
        ccl_course_title: courseRelatedIdentifiers?.course_title,
        ccl_course_session_id: courseRelatedIdentifiers?.course_session_id
      }
    }),
    [contentBlock?.id, contentBlock?.header, courseRelatedIdentifiers]
  )

  useEffect(() => {
    if (!contentBlock) {
      return
    }

    trackServerEvent({
      variables: {
        input: {
          event: 'Content Viewed - Server',
          anonymousId: getAnonymousId(),
          properties: {
            content_type: 'flex_content',
            content_title: contentBlock.header,
            content_ccl_local_id: contentBlock.id,
            access_policy_kind: currentUser?.accessPolicyKind,
            is_previewable: false, // no previewable setting for flex content
            related_identifiers: courseRelatedIdentifiers,
            trial_status: currentUser?.trialStatus
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentBlock])

  if (!cclCourseBlock || !contentBlockSlug || !cclCourseBlock.contentBlocks) {
    return null
  }

  if (!contentBlock) {
    return null
  }

  const renderCourseBlockContent = () => {
    return (
      <div className="space-y-12">
        {contentBlock.header && (
          <Breadcrumb
            id="guide-page-header"
            backTo={[{ label: backToCourseLabel, path: backToCourseLink }]}
            title={contentBlock.header}
          />
        )}

        <div
          id="content-block-container"
          className="rounded-xl bg-rb-orange-25 p-10 scroll-mt-16 w-full max-w-[780px] flex-col lg:w-3/4"
        >
          {contentBlock.header && (
            <RfHeader1
              className={twMerge(
                'py-1 !font-polysans !font-light rf-h2',
                cclCourseBlock.hasVideoContent ? '!mb-5 md:!mb-8' : '!mb-3'
              )}
            >
              <div className="flex items-center">
                <div className="inline-flex items-center justify-center p-2 md:p-3 bg-gray-200 rounded-lg mr-3 md:mr-4">
                  {contentBlock.hasVideoContent ? (
                    <VideoIcon className="inline-block w-[22px] md:w-[28px] h-[22px] md:h-[28px]" />
                  ) : (
                    <LightBulbRaysIcon className="inline-block w-[22px] md:w-[28px] h-[22px] md:h-[28px]" />
                  )}
                </div>
                <span className="font-light">{contentBlock.header}</span>
              </div>
            </RfHeader1>
          )}

          {contentBlock.description && (
            <UnitComplexPortableText
              content={JSON.parse(contentBlock.description)}
              forcePhotoLoad
              videoTrackingMetadata={videoTrackingMetadata}
            />
          )}
          {contentBlock.contentBlockCollapsible &&
            contentBlock.contentBlockCollapsible.map((collapsibleContentItem, i) => (
              <CollapsibleContentBlock
                key={i}
                type={typeForDisplay(collapsibleContentItem.type)}
                label={collapsibleContentItem.label}
                description={JSON.parse(collapsibleContentItem.description)}
              />
            ))}
        </div>
        <CourseBlockContentBlockProgressConsumer
          contentBlock={contentBlock}
          courseRelatedIdentifiers={courseRelatedIdentifiers}
        />
      </div>
    )
  }

  return <div>{renderCourseBlockContent()}</div>
}

export default CourseBlockContentBlockLandingPage
