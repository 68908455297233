import moment from 'moment'
import { useState } from 'react'

import Button from 'components/Button'

import { LearningGoal, useUpdateLearningGoalMutation } from 'gql'

import { useQueryParams } from 'hooks/useQueryParams'

import { ReactComponent as CheckCircle } from 'images/p-check-circle.svg'
import { ReactComponent as TrashCan } from 'images/p-trash.svg'

import { SimpleProgressBar } from '../ProgressBar'

export const SessionCard = ({ session }: { session: LearningGoal }) => {
  const { setNewParams } = useQueryParams()

  const totalSteps = session.data.steps.length
  const [restarting, setRestarting] = useState(false)
  const [updateSession] = useUpdateLearningGoalMutation()

  const handleSessionClick = () => {
    setNewParams({ step: 'recommendations', sessionId: session.externalId })
  }

  const handleRestartGoal = async () => {
    setRestarting(true)
    const payload = JSON.stringify({
      restartSession: true
    })

    await updateSession({
      variables: {
        input: {
          id: session!.id,
          data: payload
        }
      }
    })
    setRestarting(false)
    setNewParams({ step: 'recommendations', sessionId: session.externalId })
  }

  return (
    <div
      className="shadow-md p-6 border-1.5 border-rb-gray flex flex-col w-full mb-6 rounded-lg cursor-pointer"
      onClick={handleSessionClick}
    >
      <div className="font-sans text-rb-gray-400 font-semibold my-2">
        {session.data.goalTitle}
      </div>

      <div className="flex justify-between items-center font-sans font-semibold text-xs text-rb-gray-300 my-2">
        {session.status === 'complete' && (
          <div className="flex items-center font-sans font-semibold text-sm text-rb-success-200">
            <CheckCircle className="mr-2" height={16} width={16} />
            Complete
          </div>
        )}

        {session.status === 'cancelled' && (
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center font-sans font-semibold text-sm text-rb-gray-300">
              <TrashCan className="mr-2" height={16} width={16} />
              Cancelled
            </div>

            <Button
              size="x-small"
              shape="rounded-full"
              variant="outline"
              isLoadingSpinner={restarting}
              onClick={handleRestartGoal}
            >
              Restart goal
            </Button>
          </div>
        )}

        {['in-progress', 'new', undefined].includes(session.status) && (
          <div className="flex items-center justify-between w-full">
            <div>
              Estimated completion:{' '}
              {moment(session.data.steps[totalSteps - 1].completionDate).format(
                'MM/DD/YYYY'
              )}
            </div>

            <div className="flex items-center">
              <SimpleProgressBar
                manualPercentage={session.completionPercentage}
                showPercentage
              />

              <Button size="x-small" shape="rounded-full" variant="outline">
                Continue
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
