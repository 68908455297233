import { MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { CourseDetailPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { trackNavigationClicked } from 'utils/tracking/generated/events'

interface CourseDetailOnDemandProps {
  courseDetails: CourseDetailPartsFragment
  startCourseCTADestination: string
}

const CourseDetailOnDemand = ({
  courseDetails,
  startCourseCTADestination
}: CourseDetailOnDemandProps) => {
  const history = useHistory()
  const { currentUser, isLoggedIn } = useCurrentUser()
  const { showNewCourseGating } = useFeatureFlags()

  if (!courseDetails.onDemand) {
    return null
  }

  const onStartCourseClick = () => {
    const destination = `/courses/${courseDetails.slug}/on-demand`

    trackNavigationClicked({
      destination: destination,
      location: 'course_details_page',
      location_type: 'details_page',
      location_id: courseDetails?.id,
      type: 'button',
      text: 'Go to course',
      related_identifiers: {
        ccl_course_id: courseDetails?.id,
        course_title: courseDetails?.title,
        course_sanity_id: courseDetails?.sourceId,
        content_mode: 'on-demand'
      }
    })

    history.push(destination)
  }

  const onBecomeAMemberClick = (e: MouseEvent<HTMLButtonElement>) => {
    const destination = currentUser?.is.eligibleForTrial
      ? `/try-reforge?referer=${encodeURIComponent(`course-redirect-${courseDetails.slug}`)}`
      : `/subscribe/choose-plan?referer=${encodeURIComponent(`course-redirect-${courseDetails.slug}`)}`

    trackNavigationClicked({
      destination: destination,
      location: 'course_details_page',
      location_type: 'details_page',
      location_id: courseDetails?.id,
      type: 'button',
      text: e.currentTarget.textContent || ''
    })

    // Can't use history.push because they may be signed out and need to be redirected to /login
    window.location.assign(destination)
  }

  const onStartCourseFreeClick = (e: MouseEvent<HTMLButtonElement>) => {
    trackNavigationClicked({
      destination: startCourseCTADestination,
      location: 'course_details_page',
      location_type: 'details_page',
      location_id: courseDetails?.id,
      type: 'button',
      text: e.currentTarget.textContent || ''
    })
  }

  return (
    <div className="flex flex-col gap-5 ">
      <div className={twMerge('max-w-[360px] rounded-2xl p-6 w-full bg-rb-orange-25')}>
        <div>
          <div className="flex flex-row">
            <RfParagraphLarge>
              <span className="text-[20px] font-semibold">
                {currentUser?.is.member ? 'Access on demand' : 'Go at your own pace'}
              </span>
            </RfParagraphLarge>
          </div>
        </div>

        <div data-test="on_demand_expandable_section_content" className="pt-6">
          <RfParagraphMedium className="mb-6">
            Take this course on demand with a Reforge membership, anytime.
          </RfParagraphMedium>

          {currentUser?.is.member ? (
            <Button
              variant="fill"
              fullWidth
              size="medium"
              className="normal-case"
              onClick={onStartCourseClick}
            >
              Go to course →
            </Button>
          ) : (
            <Button
              variant="fill"
              fullWidth
              size="medium"
              className="normal-case"
              href={showNewCourseGating ? startCourseCTADestination : undefined}
              onClick={
                showNewCourseGating ? onStartCourseFreeClick : onBecomeAMemberClick
              }
            >
              {showNewCourseGating
                ? 'Start this course free'
                : currentUser?.is.eligibleForTrial || !isLoggedIn
                  ? 'Start 14 day free trial →'
                  : 'Become a member'}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default CourseDetailOnDemand
