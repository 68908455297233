import ManageSubscriptionButton from 'domains/Subscription/ManageSubscriptionButton'
import ManageTeamButton from 'domains/Team/ManageTeamButton'

import Button, { ButtonVariant } from 'components/Button'

import { trackCtaClicked } from 'utils/tracking/analytics'

const LegacySubscriptionCard = ({
  amountDue,
  expiresAt,
  isCanceled,
  isIndividual,
  isSubscriptionOwner,
  hasSelectedFlatTierPlan
}: {
  amountDue: string
  expiresAt: string
  isCanceled: boolean
  isIndividual: boolean
  isSubscriptionOwner: boolean
  hasSelectedFlatTierPlan: boolean
}) => {
  const prettyAmountDue = amountDue.slice(0, amountDue.length - 3)

  const getCopyElement = () => {
    const renewCopy = <>Your subscription will renew on {expiresAt}</>
    const expireCopy = (
      <span className="text-red-500">
        Your subscription is set to expire on {expiresAt}
      </span>
    )
    const ownerRenewCopy = (
      <>
        {renewCopy} and you will be charged {prettyAmountDue}.
      </>
    )
    const ownerExpireCopy = (
      <>{expireCopy}. Select a new plan to maintain access to Reforge content.</>
    )
    const memberRenewCopy = (
      <>{renewCopy}. To make changes to your plan, contact your subscription owner.</>
    )
    const memberExpireCopy = (
      <>
        {expireCopy}. Be sure to tell your subscription owner to select a plan before it
        expires
      </>
    )
    if (isSubscriptionOwner) {
      return isCanceled ? ownerExpireCopy : ownerRenewCopy
    } else {
      return isCanceled ? memberExpireCopy : memberRenewCopy
    }
  }

  const getButtons = () => {
    if (isSubscriptionOwner) {
      if (isIndividual) {
        if (isCanceled) {
          return <ExploreNewPlansButton />
        } else {
          return (
            <div className="flex">
              <ManageSubscriptionButton />
              {!hasSelectedFlatTierPlan ? (
                <ExploreNewPlansButton className="ml-5" variant="outline" />
              ) : null}
            </div>
          )
        }
      } else {
        if (isCanceled) {
          return <ExploreNewPlansButton />
        } else {
          return hasSelectedFlatTierPlan ? (
            <div className="flex">
              <ManageSubscriptionButton />
              <ManageTeamButton
                className="ml-5"
                variant="outline"
                handleClick={handleClick}
              />
            </div>
          ) : (
            <ExploreNewPlansButton />
          )
        }
      }
    }
  }
  return (
    <div>
      <p>{getCopyElement()}</p>
      {getButtons()}
    </div>
  )
}

export default LegacySubscriptionCard

const handleClick = (text: string, destination: string) => {
  trackCtaClicked({
    cta_location: 'subscription_card',
    cta_type: 'button',
    text: text.toLowerCase(),
    destination
  })
}

const ExploreNewPlansButton = ({
  variant,
  className
}: {
  variant?: ButtonVariant
  className?: string
}) => {
  const text = 'Explore New Plans'
  const destination = '/manage-plan'
  return (
    <Button
      className={className}
      shape="rounded-full"
      size="x-small"
      href={destination}
      variant={variant || 'fill'}
      onClick={() => handleClick(text, destination)}
    >
      {text}
    </Button>
  )
}
