import Button from 'components/Button'
import { LessonIcon } from 'components/icons'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

const OnDemandGetStartedCard = ({
  startCourseCTADestination,
  handleCtaClick
}: {
  startCourseCTADestination: string
  handleCtaClick: any
}) => {
  return (
    <div className="flex w-full max-w-[360px] flex-col rounded-xl p-8 bg-rb-orange-25">
      <RfParagraphLarge className="text-xl font-semibold pb-1">
        Get started for free
      </RfParagraphLarge>
      <div className="text-sm">
        On-demand courses provide expert-created content, real-world case studies, study
        materials, and the flexibility to learn at your own pace.
      </div>
      <div className="flex flex-col py-6">
        <div className="flex items-center pb-2">
          <LessonIcon className="w-4 h-4 mr-2.5" />
          Self-paced, on-demand
        </div>
      </div>
      <Button
        variant="fill"
        fullWidth
        href={startCourseCTADestination}
        size="medium"
        className="px-8 py-2 font-semibold"
        onClick={handleCtaClick}
      >
        Start this course free
      </Button>
    </div>
  )
}
export default OnDemandGetStartedCard
