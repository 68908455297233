import { FIRST_TIME_USER_PARAM } from 'pages/HomePage/LoggedInHomepage/HomePageLoader'

import { CurrentUserPartsFragment } from 'gql'

export const handleNavigation = (currentUser: CurrentUserPartsFragment) => {
  const queryParams = new URLSearchParams(window.location.search)
  const refererOrFwd = queryParams.get('referer') || queryParams.get('fwd')
  const courseType = queryParams.get('courseType')
  const cmsProgramSlug = queryParams.get('cmsProgramSlug')

  if (refererOrFwd) {
    const redirect = getRedirectFromReferer(refererOrFwd, courseType, cmsProgramSlug)

    const { pathname, search } = new URL(redirect, window.location.origin)
    const innerParams = new URLSearchParams(search)
    if (['/', '/account'].includes(pathname)) {
      innerParams.set(FIRST_TIME_USER_PARAM, 'true')
    }
    const paramString = innerParams.toString()

    return `/?redirect=${pathname}${paramString ? `?${paramString}` : ''}`
  }

  if (
    currentUser.is.banned ||
    currentUser.is.planManager ||
    currentUser.is.noAccess ||
    currentUser.is.copyHold
  ) {
    return '/account'
  }

  const redirectParams = new URLSearchParams(window.location.search)
  redirectParams.set(FIRST_TIME_USER_PARAM, 'true')

  return `/?${redirectParams.toString()}`
}

const getRedirectFromReferer = (
  referer: string,
  courseType: string | null,
  cmsProgramSlug: string | null
) => {
  const redirectToArtifacts = /\/artifacts\/c\/\S*/.test(referer)
  const redirectToReferer =
    /^\/artifacts\/?\S*/.test(referer) ||
    /^\/teams\/get-in-touch\S*/.test(referer) ||
    /^\/profiles\/?\S*/.test(referer) ||
    /^\/extension-authenticated\/?\S*/.test(referer)
  const redirectToCourseDetails = /\/courses\/\S*/.test(referer)
  const redirectToCourseStart = /\/start\/course\S*/.test(referer)
  const redirectToCourses = /\/course\S*/.test(referer)
  const redirectToUpgrade =
    /^\/pricing\S*/.test(referer) ||
    /^\/teams\S*/.test(referer) ||
    /^\/membership\S*/.test(referer)

  if (redirectToArtifacts) {
    return '/artifacts'
  }

  if (redirectToReferer) {
    return referer
  }

  if (redirectToCourseDetails) {
    if (courseType === 'marketplace') {
      return `${referer}/details`
    }
    if (courseType === 'legacy') {
      return `/programs/${cmsProgramSlug}`
    }
  }

  if (redirectToCourseStart) {
    return referer
  }

  if (redirectToCourses) {
    return '/courses'
  }

  if (redirectToUpgrade) {
    return '/upgrade'
  }

  return '/'
}
