import { ReactNode } from 'react'

import Button from 'components/Button'
import ArrowUpRight from 'components/icons/ArrowUpRight'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { CurrentUserPartsFragment } from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

const SLACK_KEYWORDS = ['slack']
const SUPPORT_KEYWORDS = ['help', 'support']
const COHORT_KEYWORDS = [
  'live cohort',
  'live classes',
  'live courses',
  'live program',
  'fall cohort',
  'fall classes',
  'fall courses',
  'enroll',
  '2023 cohort',
  '2023 fall cohort',
  '2023 fall courses'
]

interface HardcodedSearchResultsProps {
  searchQuery: string
  currentUser?: CurrentUserPartsFragment | null
  className?: string
}

const HardcodedSearchResults = ({
  searchQuery,
  currentUser,
  className
}: HardcodedSearchResultsProps) => {
  const searchString = searchQuery.trim().toLowerCase()

  const showSlack = SLACK_KEYWORDS.includes(searchString)
  const showSupport = SUPPORT_KEYWORDS.includes(searchString)
  const showCohort = COHORT_KEYWORDS.includes(searchString)

  if (!showSlack && !showSupport && !showCohort) return null

  return (
    <div className={className}>
      {showSlack && (
        <HardcodedSearchItem
          title="Reforge Members Slack"
          ctaText="View Reforge Slack"
          ctaHref={currentUser?.joinSlackHref || 'https://reforge-members.slack.com/'}
          ctaIdentifier="slack"
        >
          Meet other members and discuss Reforge concepts in our Slack group.
        </HardcodedSearchItem>
      )}
      {showSupport && (
        <HardcodedSearchItem
          title="We’re here to help"
          ctaText="Contact Support"
          ctaHref="mailto:hello@reforge.com"
          ctaIdentifier="support"
        >
          For any questions about using Reforge, please contact us at{' '}
          <a href="mailto:hello@reforge.com">hello@reforge.com</a>
        </HardcodedSearchItem>
      )}
      {showCohort && (
        <HardcodedSearchItem
          title="Live courses"
          ctaText="Learn More"
          ctaHref="/courses"
          ctaIdentifier="courses"
        >
          Reforge’s courses allow you to unlock step-change growth in your career in less
          than six weeks. How?
        </HardcodedSearchItem>
      )}
    </div>
  )
}

interface HardcodedSearchItemProps {
  title: string
  ctaText: string
  ctaHref: string
  ctaIdentifier: string
  children: ReactNode
}

const HardcodedSearchItem = ({
  title,
  ctaText,
  ctaHref,
  ctaIdentifier,
  children
}: HardcodedSearchItemProps) => {
  const trackClick = () => {
    trackCtaClicked({
      cta_location: 'search_banner',
      cta_type: 'button',
      text: ctaIdentifier
    })
  }

  return (
    <div className="flex flex-row items-center rounded bg-rb-gray-50 px-8 py-6">
      <div className="grow">
        <RfHeader3 className="!mb-0 pb-2 !font-semibold">{title}</RfHeader3>
        <RfParagraphSmall>{children}</RfParagraphSmall>
      </div>
      <Button
        className="h-8 text-[14px] font-semibold leading-5"
        href={ctaHref}
        shape="rounded"
        target="_blank"
        variant="outline"
        size="x-small"
        iconAfter={<ArrowUpRight className="mr-3 h-3 w-3 fill-current" />}
        onClick={trackClick}
      >
        {ctaText}
      </Button>
    </div>
  )
}

export default HardcodedSearchResults
