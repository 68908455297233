/* eslint-disable camelcase */
import EmbeddedHubspotForm from 'domains/Patronage/EmbeddedHubspotForm'

import { cn } from 'utils/tailwind'
import { trackCtaClicked, trackMarketingFormSubmitted } from 'utils/tracking/analytics'

const BookADemoForm = ({ className }: { className?: string }) => {
  const formId = '355229a4-7519-4719-b5c8-64c2a56a776a'

  const handleFormSubmit = (formData: any) => {
    trackCtaClicked({
      cta_location: 'hubspot_book_a_demo_form',
      cta_type: 'button',
      text: 'submit to get in touch'
    })

    const el = formData.elements ?? formData?.[0]?.elements

    if (!el) {
      return
    }

    const email = el.email?.value
    const first_name = el.firstname?.value
    const last_name = el.lastname?.value
    const company = el.company?.value
    const website = el.website?.value
    const num_employees = el.numemployees?.value

    const form_fields = {
      email,
      first_name,
      last_name,
      company,
      website,
      num_employees
    }

    trackMarketingFormSubmitted({
      form_fields,
      email,
      form_id: formId,
      form_type: 'hubspot',
      form_name: 'book a demo form',
      location: 'hubspot_book_a_demo_form',
      url: '/book-a-demo',
      source: 'marketing site',
      type: 'page'
    })
  }

  return (
    <EmbeddedHubspotForm
      classes={cn('w-3/5 mt-12', className)}
      region="na1"
      portalId="20491267"
      formId={formId}
      onFormSubmit={handleFormSubmit}
      onFormSubmitted={() => window.location.reload()}
    />
  )
}

export default BookADemoForm
