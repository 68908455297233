import { ReactComponent as ChevronLeft } from 'chevron-left.svg'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { usePage } from 'components/PageHeader/usePage'
import GuideCard from 'components/cards/Content/GuideCard'
import SkeletonContentCard from 'components/skeletons/cards/SkeletonContentCard'
import SkeletonRfHeader from 'components/skeletons/typography/SkeletonRfHeader'

import { ProgramBookmarkPartsFragment, TagGroup, Unit } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'

import { SWIMLANE_ALL } from './constants'
import useSwimlaneData from './useSwimLaneData'

interface SwimlaneViewProps {
  slug: string
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  tagGroup?: TagGroup | null | undefined
  guides?: Unit[] | null | undefined
  isLoading: boolean
}

interface SwimlaneViewContainerProps {
  slug: string
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

export const SwimlaneViewContainer = (props: SwimlaneViewContainerProps) => {
  const isSwimlaneAll = props.slug === SWIMLANE_ALL
  const swimlaneData = useSwimlaneData(props.slug, isSwimlaneAll)

  return <SwimlaneView {...props} {...swimlaneData} />
}
export const SwimlaneView = ({
  slug,
  openAddToBookmarkFolderModal,
  tagGroup,
  guides,
  isLoading
}: SwimlaneViewProps) => {
  const history = useHistory()
  const isSwimlaneAll = slug === SWIMLANE_ALL

  const swimlaneTitle = isSwimlaneAll ? 'All Guides' : tagGroup?.title
  const handleNavClick = useCallback(() => {
    history.push('/guides')
  }, [history])

  const { setPageTitle } = usePage()

  useEffect(() => {
    setPageTitle(
      <h3 className="flex items-center gap-2 pb-4 font-polysans !font-light fluid-text-4xl">
        <span
          tabIndex={0}
          role="button"
          className="group flex items-center gap-2 text-rb-gray-300 hover:text-rb-teal-400 hover:no-underline"
          onClick={handleNavClick}
          onKeyUp={onEnterKeyPress(handleNavClick)}
        >
          <span className="rounded-full border border-rb-gray-100 p-2 text-rb-gray-100 group-hover:text-rb-teal-400">
            <ChevronLeft className="h-[17px] min-w-[17px] stroke-current" />
          </span>
        </span>
        {isLoading && <SkeletonRfHeader header="h2" width="1/4" />}
        {!isLoading && <span>{swimlaneTitle}</span>}
      </h3>
    )

    return () => {
      setPageTitle(null)
    }
  }, [swimlaneTitle, setPageTitle, handleNavClick, isLoading])

  return (
    <>
      <div
        className={
          'grid list-none grid-cols-1 gap-x-6 gap-y-8 p-0 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'
        }
        data-testid="content-card-swim-lane-loading"
      >
        {isLoading &&
          Array.from({ length: 4 }).map((_, i) => <SkeletonContentCard key={i} />)}
        {!isLoading &&
          (guides ?? []).map((guide, idx) => (
            <GuideCard
              key={guide.id}
              guide={guide}
              swimlaneSlug={slug}
              additionalRelatedIdentifiers={{
                referrer_swimlane: isSwimlaneAll ? 'all' : tagGroup?.title,
                referrer_category: tagGroup?.functionTag?.slug
              }}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              impressionTrackingProperties={{
                location: `/guides tag_page (${tagGroup?.title?.toLowerCase()})`,
                sectionTitle: swimlaneTitle,
                sectionImpressionIndex: idx,
                cclEntityId: guide.guide?.id
              }}
            />
          ))}
      </div>
    </>
  )
}
