import { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

import CustomPageTitle from 'pages/CustomPageTitle'

import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'
import { usePage } from 'components/PageHeader/usePage'
import ChevronRightThinIcon from 'components/icons/ChevronRightThinIcon'

import {
  ProgramBookmarkPartsFragment,
  TagGroup,
  useSanityTagGroupsQuery
} from 'gql/index'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { trackCtaClicked, trackNavigationClicked } from 'utils/tracking/analytics'

import { ContentCardSwimlane } from './ContentCardSwimlane'
import Hero from './Hero'
import { CATEGORY_ALL, SWIMLANE_ALL } from './constants'

export const CTA_LINK_STYLES =
  'lowercase hover:no-underline text-sm font-normal leading-6 tracking-wide text-rb-gray-300 hover:text-rb-teal-300 pl-4 flex cursor-pointer items-center'

export const trackCtaClick = (altitude: string) => {
  trackCtaClicked({
    cta_location: 'guides_page',
    cta_type: 'link',
    text: 'view_all',
    related_identifiers: {
      altitude
    }
  })
}

const getChevronIcon = () => {
  return <ChevronRightThinIcon className="h-3 fill-current pl-2" />
}

const getGroupsByCategory = (category: string, tagGroups: any) => {
  if (category === CATEGORY_ALL) {
    // find all tag groups that have global set to true
    const globalTagGroups = tagGroups?.sanityTagGroups.filter(
      (tagGroup: { global: boolean }) => tagGroup.global
    )
    return globalTagGroups
  }

  // find all tag groups that have a function tag with a slug equal to the category param
  const groups = tagGroups?.sanityTagGroups.filter(
    (tagGroup: { functionTag: { slug: string } }) =>
      tagGroup.functionTag?.slug === category
  )
  const sortProp = category === CATEGORY_ALL ? 'globalSortOrder' : 'sortOrder'
  const sorted = groups?.sort(
    (a: TagGroup, b: TagGroup) => (a[sortProp] ?? 0) - (b[sortProp] ?? 0)
  )

  return sorted
}

interface SwimlaneIndexProps {
  category: string
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

export const SwimlaneIndex = ({
  category,
  openAddToBookmarkFolderModal
}: SwimlaneIndexProps) => {
  const { currentUser } = useCurrentUser()

  const { ref16263TopicBasedBrowsing } = useFeatureFlags()

  const { data: tagGroups } = useSanityTagGroupsQuery()
  const groups = getGroupsByCategory(category, tagGroups)
  const { setPageTabs, setPageTitle, setPageAboveHeader, setPageSubtitle } = usePage()

  // build a unique set of function tags that represent pills/tabs
  const tabs = useMemo(() => {
    const tabsFunctionTags = tagGroups?.sanityTagGroups.map(
      (tagGroup) => tagGroup.functionTag
    )

    return tabsFunctionTags
      ?.filter((tag, index, self) => index === self.findIndex((t) => t.slug === tag.slug))
      .sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
  }, [tagGroups?.sanityTagGroups])

  const trackPillClick = (name: string) => {
    trackNavigationClicked({
      text: name || '',
      type: 'button',
      location: 'guides_page'
    })
  }

  useEffect(() => {
    const pageTabs = [
      {
        href: `/guides/?category=${CATEGORY_ALL}`,
        isActive: category === CATEGORY_ALL,
        onClick: () => trackPillClick('all'),
        label: 'All'
      },
      ...(tabs || []).map((tab) => ({
        href: `/guides?category=${tab.slug}`,
        isActive: category === tab.slug,
        onClick: () => trackPillClick(tab?.title),
        label: tab?.title
      }))
    ]

    setPageTabs(pageTabs)

    return () => {
      setPageTabs(null)
    }
  }, [tabs, setPageTabs, category, currentUser])

  useEffect(() => {
    if (!currentUser || currentUser?.is.freeUser) {
      setPageTitle(<CustomPageTitle title="Guides"></CustomPageTitle>)
    }

    if (currentUser) {
      if (ref16263TopicBasedBrowsing) {
        setPageAboveHeader(
          <div className="mb-8">
            <BaseBreadcrumbs
              backIcon={
                <ChevronLeftIcon width={16} height={16} className="mr-4 fill-rb-black" />
              }
              oneLevel
              onClick={() => {
                trackNavigationClicked({
                  type: 'hyperlink text',
                  text: 'back to explore',
                  location: location.pathname,
                  destination: '/explore'
                })
              }}
              breadcrumbPages={[{ title: 'Explore', path: '/explore' }]}
            />
          </div>
        )
      }
      setPageSubtitle('Follow quick step-by-step frameworks created by top experts')
    }

    return () => {
      setPageTitle(null)
      setPageAboveHeader(null)
      setPageSubtitle(null)
    }
  }, [
    currentUser,
    setPageTitle,
    setPageAboveHeader,
    setPageSubtitle,
    ref16263TopicBasedBrowsing
  ])

  const topPadding = ref16263TopicBasedBrowsing ? 'pt-4' : ''

  return (
    <div className="mb-5">
      {!ref16263TopicBasedBrowsing && <Hero />}

      <div className={topPadding}>
        {groups?.map((tagGroup: TagGroup, rowIdx: number) => (
          <ContentCardSwimlane
            key={tagGroup?.id}
            isLoading={false}
            slug={tagGroup.slug}
            tagGroup={tagGroup}
            category={category}
            contents={[]}
            className="mb-12"
            ctaButton={
              <Link
                to={`/guides?swimlane=${tagGroup.slug}`}
                className={CTA_LINK_STYLES}
                onClick={() => trackCtaClick('Guides Page')}
              >
                view all
                {getChevronIcon()}
              </Link>
            }
            title={tagGroup?.title}
            rowIdx={rowIdx}
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            pageLocation="/guides"
          />
        ))}
        {category === CATEGORY_ALL && (
          <ContentCardSwimlane
            isLoading={false}
            slug={SWIMLANE_ALL}
            category={category}
            contents={[]}
            className="pb-4"
            ctaButton={
              <Link
                to={`/guides?swimlane=${SWIMLANE_ALL}`}
                className={CTA_LINK_STYLES}
                onClick={() => trackCtaClick('Guides Page')}
              >
                view all
                {getChevronIcon()}
              </Link>
            }
            title="All Guides"
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            rowIdx={groups?.length}
            pageLocation="/guides"
          />
        )}
      </div>
    </div>
  )
}
