import { CoursesListFilters } from 'gql'

export const COURSES_TAB_MY_COURSES = 'my-courses'

export type CombinedFiltersType = {
  topics: string[]
  functions: string[]
  onlyLive: boolean
}

export type FilterType = 'topics' | 'functions' | 'onlyLive'

export const combinedFilterCount = (combinedFilters: CombinedFiltersType) => {
  type FilterTypeWithoutOnlyLive = Exclude<FilterType, 'onlyLive'>

  return Object.keys(combinedFilters).reduce(
    (count, filterType) =>
      filterType === 'onlyLive'
        ? count + (combinedFilters.onlyLive ? 1 : 0)
        : count + combinedFilters[filterType as FilterTypeWithoutOnlyLive].length,
    0
  )
}

export const getValidSelectedFilters = (
  allCourseFilters: CoursesListFilters,
  selectedFilters: CombinedFiltersType,
  type: 'topics' | 'functions'
) => {
  const validFilters = allCourseFilters[type]?.map((filter) => filter.slug) || []

  return selectedFilters[type].filter((slug) => validFilters.includes(slug))
}
