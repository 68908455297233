import PersonalizeContent from 'domains/Ai/Chat/PersonalizeContent'

import { Loading } from 'components'
import { useGlobalModal } from 'components/GlobalModal'
import { ModalContent } from 'components/Modal'
import { useRefetch } from 'components/RefetchProvider'

import { useAiPersonalizationQuery, useDedupedTopicsAndFunctionsQuery } from 'gql'

interface PersonalizeModalProps {
  onSave?: () => void
  onCancel?: () => void
}

const PersonalizeModal = ({ onSave, onCancel }: PersonalizeModalProps) => {
  const { closeGlobalModal } = useGlobalModal()
  const { setFetchChecklistTasks } = useRefetch()
  const { data: dedupedTopicsAndFunctions, loading: dedupedTopicsAndFunctionsLoading } =
    useDedupedTopicsAndFunctionsQuery()

  const { data: personalizationData, loading: personalizationLoading } =
    useAiPersonalizationQuery()

  const loading = dedupedTopicsAndFunctionsLoading || personalizationLoading

  const handleCancel = async () => {
    closeGlobalModal()
    onCancel?.()
  }

  const handleSave = () => {
    onSave?.()
    setFetchChecklistTasks(true)
    closeGlobalModal()
  }

  return (
    <ModalContent className="px-4 md:px-12" scrollContent={false}>
      {loading ? (
        <Loading />
      ) : (
        <PersonalizeContent
          dedupedTopicsAndFunctions={
            dedupedTopicsAndFunctions?.dedupedTopicsAndFunctions || []
          }
          personalizationData={personalizationData?.aiPersonalization}
          isModal={true}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </ModalContent>
  )
}

export default PersonalizeModal
