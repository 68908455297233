import { useLayoutEffect, useRef, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useGlobalState } from 'state'

import CohortDashboard from 'pages/CohortDashboard'

import Bookmarks from 'domains/Bookmarks/Bookmarks'
import { LOCATION_LOCAL_SAVED_ITEMS } from 'domains/Bookmarks/utils'
import ClipViewerModal from 'domains/Clip/ClipViewerModal'
import CohortViewerHeader from 'domains/CohortViewer/CohortViewerHeader'
import Tabs from 'domains/CohortViewer/CohortViewerTabs'
import Highlights from 'domains/CohortViewer/Highlights'
import HostsColumn from 'domains/CohortViewer/HostsColumn'
import Material from 'domains/CohortViewer/Material'
import WeeklyList from 'domains/CohortViewer/WeeklyList'
import {
  getHiglightSectionData,
  getPercentCompleteBasedOnStartAndEndDate
} from 'domains/CohortViewer/utils'

import { Loading } from 'components'
import { useModal } from 'components/Modal'
import SearchModal from 'components/SearchModal'
import HighlightsCards from 'components/cards/HighlightsCards'

import { ClipPartsFragment, useCohortViewerQuery, useUsersFeedQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import useDebouncedStringState from 'hooks/useDebouncedStringState'

import { isBeforeDate } from 'utils/date'

import { useCohortViewerPage } from './useCohortViewerPage'

const CohortViewer = () => {
  const {
    slug,
    data,
    loading,
    shouldRedirect,
    showCohortDashboardForCohortSeason = false
  } = useCohortViewerPage()

  if (loading) return <Loading />
  if (!data) return null

  const showCohortViewerAt = data?.cohort?.season?.showCohortViewerAt

  if (Boolean(showCohortViewerAt) && isBeforeDate(showCohortViewerAt)) {
    return <Redirect to="/" />
  }

  if (shouldRedirect) return <Redirect to="/" />

  return showCohortDashboardForCohortSeason ? (
    <div data-testid="cohort-dashboard">
      <CohortDashboard />
    </div>
  ) : (
    <CohortViewerMain slug={slug} />
  )
}

interface CohortViewerMainProps {
  slug: string
}

const CohortViewerMain = ({ slug }: CohortViewerMainProps) => {
  const [envVariables] = useGlobalState('envVariables')
  const currentUser = useAssertCurrentUser()
  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false)
  const [selectedClip, setSelectedClip] = useState<null | ClipPartsFragment>(null)
  const { isModalOpen, setIsModalOpen } = useModal()
  const [headerOffset, setHeaderOffset] = useState('200px')
  const [headerLeftPosition, setHeaderLeftPosition] = useState(0)
  const headerWrapperRef = useRef(null)
  const bodyWrapperRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const { data } = useCohortViewerQuery({
    variables: { slug: slug }
  })

  const [search, setSearch] = useDebouncedStringState()

  useLayoutEffect(() => {
    window.addEventListener('resize', updateHeaderOffset)
    window.addEventListener('resize', updateHeaderLeftPosition)
    return () => {
      window.removeEventListener('resize', updateHeaderOffset)
      window.removeEventListener('resize', updateHeaderLeftPosition)
    }
  }, [])

  useLayoutEffect(() => {
    updateHeaderOffset()
    updateHeaderLeftPosition()
  }, [data])

  const updateHeaderOffset = () => {
    if (headerWrapperRef && headerWrapperRef.current) {
      setHeaderOffset(`${(headerWrapperRef.current as any).offsetHeight}px`)
    }
  }

  const updateHeaderLeftPosition = () => {
    if (bodyWrapperRef && bodyWrapperRef.current) {
      setHeaderLeftPosition(bodyWrapperRef.current.getBoundingClientRect().left)
    }
  }

  const userCohort = data?.userCohort

  const inCohortScheduledWeeks =
    userCohort?.cohort.scheduledWeeks &&
    userCohort.cohort.scheduledWeeks.filter((sw) => !sw.bonus && !sw.postCohort)
  const endsAt =
    inCohortScheduledWeeks &&
    inCohortScheduledWeeks[inCohortScheduledWeeks.length - 1]?.endsAt

  const percentCompleteBasedOnStartAndEndDate = getPercentCompleteBasedOnStartAndEndDate(
    userCohort?.cohort.events[0]?.startsAtUtc,
    endsAt
  )

  const clips = getHiglightSectionData(userCohort?.cohort.scheduledWeeks) || {}
  const hasClips = Boolean(clips?.allClips?.length && clips.allClips.length > 0)
  const clipsHeader = clips.headerName || userCohort?.cohort.cmsProgram.name
  const navigateToHighlightsTab = () => {
    // hack because of uk-tabs
    const elem = document.getElementById('cohort-viewer-tabs--overview')
    if (elem) {
      elem.classList.remove('uk-active')
    }
    history.push(`/cohorts/${slug}/highlights`)
  }

  const {
    loading,
    error,
    data: userData,
    fetchMore
  } = useUsersFeedQuery({
    variables: {
      cohortSlug: slug,
      limit: 40,
      offset: 0,
      search
    }
  })

  const count = userData?.usersFeed?.count || 0
  const users = userData?.usersFeed?.users || []

  const hasMoreUsers = count > users.length

  const loadMore = () => {
    fetchMore({
      variables: {
        cohortSlug: slug,
        limit: 20,
        offset: users.length,
        search
      }
    })
  }

  const onClose = () => {
    setIsMemberModalOpen(false)
    // Clear search filter when modal is closed.
    setSearch('')
  }

  const isLiveProgramCompleted = percentCompleteBasedOnStartAndEndDate === 100

  return (
    <div id="cohort-viewer" data-testid="cohort-viewer-main">
      <SearchModal
        isOpen={isMemberModalOpen}
        onClose={onClose}
        users={users}
        count={count}
        modalTitle="Program Participants"
        handleSearch={setSearch}
        hasMoreUsers={hasMoreUsers}
        loadMore={loadMore}
        isLoading={loading}
        error={error}
      />
      <div
        id="cohort-viewer-header-wrapper"
        className="fixed top-0 right-0 z-50 bg-white"
        style={{ left: headerLeftPosition }}
        ref={headerWrapperRef}
      >
        <CohortViewerHeader
          programCode={userCohort?.cohort.cmsProgram.code || ''}
          iconImageUrl={userCohort?.cohort.cmsProgram.iconImageUrl || ''}
          heroImageUrl={userCohort?.cohort.cmsProgram.heroImageUrl || ''}
          programName={userCohort?.cohort.cmsProgram.name || ''}
          seasonName={userCohort?.cohort.season.name || ''}
          seasonYear={userCohort?.cohort.season.year || ''}
          kickOffStartsAt={userCohort?.cohort.startsAt}
          endsAt={endsAt || ''}
          percentCompleted={percentCompleteBasedOnStartAndEndDate}
          slackChannelId={userCohort?.cohort.slackChannelId || ''}
          currentUser={currentUser}
          joinSlackHref={envVariables.joinSlackHref}
          cohortSeasonId={userCohort?.cohort.season.id || ''}
        />
        <Tabs
          rootRoute={`/cohorts/${slug}`}
          slug={slug}
          setIsMemberModalOpen={setIsMemberModalOpen}
          showHightlightsTab={Boolean(
            clips?.allClips?.length && clips.allClips.length > 0
          )}
        />
      </div>
      <div
        className="min-h-screen flex flex-col"
        style={{ marginTop: headerOffset }}
        ref={bodyWrapperRef}
      >
        {userCohort && (
          <Switch>
            <Route
              exact
              path={`/cohorts/${slug}`}
              render={() => (
                <>
                  <div className="sm:flex">
                    <div className={`w-full ${hasClips ? 'xl:w-2/3' : ''}`}>
                      <WeeklyList
                        currentUser={currentUser}
                        isLiveProgramCompleted={isLiveProgramCompleted}
                        userCohort={userCohort}
                        endsAt={endsAt || ''}
                        slug={slug}
                        cohortStart={userCohort.cohort.startsAt || ''}
                        joinSlackHref={envVariables.joinSlackHref}
                        hasDismissedPostCohortEirMessage={
                          userCohort?.hasDismissedPostCohortEirMessage
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-4">
                      <div
                        className="top-0 hidden pt-6 lg:block"
                        style={{ top: '258px' }}
                      >
                        {userCohort.cohort.hosts &&
                          userCohort.cohort.hosts.length > 0 && (
                            <HostsColumn hosts={userCohort.cohort.hosts} label="Hosts" />
                          )}
                        {userCohort.cohort.programCollaborators &&
                          userCohort.cohort.programCollaborators.length > 0 && (
                            <HostsColumn
                              label="Program Collaborators"
                              hosts={userCohort.cohort.programCollaborators}
                            />
                          )}
                      </div>
                      {hasClips && (
                        <div className="hidden gap-4 xl:flex xl:w-5/6 xl:flex-col">
                          <div className="sticky top-[258px] hidden h-[600px] overflow-y-scroll xl:block">
                            <HighlightsCards
                              clips={clips}
                              className="flex-col xl:flex"
                              headerName={`View top moments from past expert guests on ${clipsHeader}`}
                              onClipSelect={(clip) => {
                                setSelectedClip(clip)
                                setIsModalOpen(true)
                              }}
                              navigateToHighlightsTab={navigateToHighlightsTab}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <ClipViewerModal
                    clip={selectedClip}
                    isOpen={isModalOpen}
                    handleClose={() => setIsModalOpen(false)}
                  />
                </>
              )}
            />
            <Route
              path={`/cohorts/${slug}/bookmarks`}
              render={() => (
                <div className="flex flex-1 bg-white px-4 lg:px-8">
                  <Bookmarks
                    trackingLocation={LOCATION_LOCAL_SAVED_ITEMS}
                    cmsProgramId={userCohort?.cohort.cmsProgram.id}
                    programName={userCohort?.cohort.cmsProgram.name}
                  />
                </div>
              )}
            />
            <Route
              path={`/cohorts/${slug}/material`}
              render={() => (
                <Material
                  programId={userCohort.cohort.cmsProgram.id}
                  scheduledWeeks={userCohort.cohort.scheduledWeeks}
                />
              )}
            />
            {clips?.allClips?.length && clips.allClips.length > 0 && (
              <Route
                path={`/cohorts/${slug}/highlights`}
                render={() => <Highlights currentUser={currentUser} clips={clips} />}
              />
            )}
          </Switch>
        )}
        {/* TODO: Empty state while the data is loading */}
      </div>
    </div>
  )
}

export default CohortViewer
