import { useEffect } from 'react'

import { useListeningModeContext } from 'components/ListeningMode/ListeningModeContextProvider'

interface UseListeningModeProgressListenerParams {
  onTrackEnded?: () => void
  onTrackProgressed: (progressPercent: number) => void
}

export const useListeningModeProgressListener = ({
  onTrackEnded,
  onTrackProgressed
}: UseListeningModeProgressListenerParams) => {
  const { currentTime, duration, trackEnded, setTrackEnded } = useListeningModeContext()

  useEffect(() => {
    onTrackProgressed(Math.floor((currentTime / duration) * 100))
  }, [onTrackProgressed, currentTime, duration])

  useEffect(() => {
    if (trackEnded) {
      onTrackEnded?.()
      setTrackEnded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackEnded])
}

export default useListeningModeProgressListener
