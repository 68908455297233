import React, { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import { ModalContent } from 'components/Modal'

import { ReactComponent as PaperPlaneSendWhiteIcon } from 'images/icon--paper-plane-send-white.svg'
import { ReactComponent as PaperPlaneSendIcon } from 'images/icon--paper-plane-send.svg'
import { ReactComponent as ThumbsDownIconFilled } from 'images/icon--thumb-down-filled.svg'
import { ReactComponent as ThumbsDownIcon } from 'images/icon--thumb-down.svg'
import { ReactComponent as ThumbsUpIconFilled } from 'images/icon--thumb-up-filled.svg'
import { ReactComponent as ThumbsUpIcon } from 'images/icon--thumb-up.svg'

import { GlobalChatContextProps } from '../GlobalChatProvider'
import { Message } from '../types'

const BUTTON_CLASS =
  'cursor-pointer rounded-full border border-rb-gray-100 p-2 transition duration-200 ease-in-out hover:bg-rb-gray-50 active:bg-rb-gray-100'

export interface SendFeedbackProps {
  sessionId: string
  sessionType: 'learning_goal'
  thumb: 'up' | 'down'
  feedbackMessage?: string
}

export const sendFeedback = async ({
  sessionId,
  thumb,
  feedbackMessage = '',
  sessionType
}: SendFeedbackProps) => {
  // TODO: Implement feedback API call
  console.log('Sending feedback', { sessionId, thumb, feedbackMessage, sessionType })
}

export interface FeedbackProps {
  chatId: string
  message: Message
  onReload: () => void
  allowReload: boolean
  draftTemplateName?: string
  mode: GlobalChatContextProps['mode']
}

/** ThumbsUp Component */
interface ThumbsUpProps {
  selected: boolean
  onClick: () => void
  className?: string
}

export const ThumbsUp: React.FC<ThumbsUpProps> = ({
  selected,
  onClick,
  className = BUTTON_CLASS
}) => {
  return (
    <button onClick={onClick} className={className}>
      {selected ? <ThumbsUpIconFilled width={16} /> : <ThumbsUpIcon width={16} />}
    </button>
  )
}

/** ThumbsDown Component */
interface ThumbsDownProps {
  selected: boolean
  onClick: () => void
  className?: string
  onClickSendFeedback: () => void
}

export const ThumbsDown: React.FC<ThumbsDownProps> = ({
  selected,
  onClick,
  onClickSendFeedback,
  className = BUTTON_CLASS
}) => {
  return (
    <button onClick={onClick} className={className}>
      {selected ? (
        <div
          className="flex items-center gap-2 px-3 text-sm font-medium text-rb-destructive-100"
          onClick={onClickSendFeedback}
        >
          <ThumbsDownIconFilled width={16} />
          Send Feedback?
        </div>
      ) : (
        <ThumbsDownIcon width={16} />
      )}
    </button>
  )
}

export function FurtherFeedbackModal({
  sessionId,
  sessionType,
  sendFeedback,
  removeDislike,
  onClose,
  headerText,
  subHeaderText
}: {
  sessionId: string
  sessionType: 'learning_goal'
  sendFeedback: (props: SendFeedbackProps) => Promise<void>
  removeDislike: () => void
  onClose?: () => void
  headerText: string
  subHeaderText: string
}) {
  const [feedbackMessage, setFeedbackMessage] = useState<string>('')
  const canSubmit = feedbackMessage.trim().length > 0
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const mounted = useRef<boolean>(true)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const onFeedbackSubmit = async () => {
    await sendFeedback({
      sessionId,
      sessionType,
      thumb: 'down',
      feedbackMessage
    })
    setIsSubmitted(true)
    setTimeout(() => {
      if (mounted.current) {
        onClose?.()
      }
    }, 1000)
  }

  return (
    <>
      {isSubmitted ? (
        <ModalContent>
          <div className="flex h-[304px] items-center justify-center pb-14 text-center text-xl font-semibold">
            Thank you for your feedback!
          </div>
        </ModalContent>
      ) : (
        <>
          <div className="flex justify-start px-8 text-center text-lg font-bold md:px-11">
            {headerText}
          </div>
          <ModalContent className="flex flex-col gap-4">
            <div className="flex flex-col gap-y-6 pb-6 pt-2">
              <div className="flex justify-start text-center text-rb-gray-300">
                {subHeaderText}
              </div>
              <div className="relative flex">
                <textarea
                  className="focus:border=rb-gray-300 w-full resize-none rounded border border-rb-gray-200 p-3"
                  style={{ minHeight: '150px' }}
                  value={feedbackMessage}
                  onChange={(e) => setFeedbackMessage(e.target.value)}
                  placeholder="Leave feedback"
                />
                <div
                  className={twMerge(
                    'absolute right-4 bottom-4 p-2',
                    feedbackMessage.trim() ? 'cursor-pointer rounded bg-rb-teal-600' : ''
                  )}
                  onClick={canSubmit ? onFeedbackSubmit : () => {}}
                >
                  {canSubmit ? (
                    <PaperPlaneSendWhiteIcon width={12} />
                  ) : (
                    <PaperPlaneSendIcon width={12} />
                  )}
                </div>
              </div>
              <Button
                className="justify-content-center rounded-full"
                variant="outline"
                fullWidth={true}
                size="medium"
                onClick={removeDislike}
              >
                Remove Dislike
              </Button>
            </div>
          </ModalContent>
        </>
      )}
    </>
  )
}
