import { cn } from '@/lib/utils'

export interface SimpleProgressBarProps {
  className?: string
  manualPercentage?: number
  totalSteps?: number
  completed?: number
  backgroundColorClass?: string
  progressColorClass?: string
  showPercentage?: boolean
}

export const SimpleProgressBar = ({
  className,
  totalSteps,
  completed,
  manualPercentage,
  backgroundColorClass,
  progressColorClass,
  showPercentage = false
}: SimpleProgressBarProps) => {
  const calculatePercentage = (): number => {
    if (manualPercentage !== undefined) return manualPercentage

    if (totalSteps === 0) return 0
    return Math.round((completed ?? 0 / (totalSteps ?? 0)) * 100)
  }

  return (
    <PercentageProgressBar
      className={className}
      percentage={calculatePercentage()}
      backgroundColorClass={backgroundColorClass}
      progressColorClass={progressColorClass}
      showPercentage={showPercentage}
    />
  )
}

export interface PercentageProgressBarProps {
  className?: string
  percentage: number
  backgroundColorClass?: string
  progressColorClass?: string
  showPercentage?: boolean
}

/**
 * PercentageProgressBar Component
 *
 * This component is used to display a progress bar with a given percentage value.
 * Unlike SimpleProgressBar, it doesn't calculate the percentage from props,
 * but instead directly uses the provided percentage value.
 *
 * @param className - Optional CSS class name for styling
 * @param percentage - The percentage value to display (0-100)
 * @param backgroundColorClass - Optional CSS class for background color
 * @param progressColorClass - Optional CSS class for progress bar color
 * @param showPercentage - Whether to show the percentage value as text
 */
export const PercentageProgressBar = ({
  className,
  percentage,
  backgroundColorClass,
  progressColorClass,
  showPercentage = false
}: PercentageProgressBarProps) => {
  return (
    <div className={cn('flex flex-col rounded-md', className)}>
      <div className="flex items-center">
        <div
          className={cn(
            'mr-4 flex h-2 w-52 justify-start rounded-md bg-[rgba(227,204,189,0.30)]',
            backgroundColorClass
          )}
        >
          <div
            className={cn('bg-rb-success rounded-md', progressColorClass)}
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        {showPercentage && (
          <span className="mx-4 font-sans text-sm font-semibold">{`${percentage}%`}</span>
        )}
      </div>
    </div>
  )
}
