import { useFeatureFlags } from 'hooks/useFeatureFlags'

import CoursesList from './CoursesList'
import CoursesListOld from './CoursesListOld'
import UpcomingLiveCoursesCarousel from './UpcomingLiveCoursesCarousel'

export const CoursesIndexPage = () => {
  const { showCourseLandingPageUpdates } = useFeatureFlags()

  return (
    <div className="flex min-w-[311px] flex-col gap-12">
      {showCourseLandingPageUpdates ? (
        <>
          <UpcomingLiveCoursesCarousel pageLocation="logged_in_courses_index" />
          <CoursesList />
        </>
      ) : (
        <CoursesListOld />
      )}
    </div>
  )
}

export default CoursesIndexPage
