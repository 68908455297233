import { format } from 'date-fns'

import {
  HOUR_MIN_FORMAT,
  getCurrentTimezone,
  getTimezoneAbbreviation,
  utcToNewTimezone
} from 'utils/date'

/**
 * Gets a human readable string of unique event times based on the course session's events
 * and the user's timezone.
 * @param {array} events - any[]) TO DO: TYPES
 * @param {string} timezone - user timezone, defaults to 'America/Los_Angeles'
 * @param {boolean} showTimezone - whether to show the timezone abbreviation, defaults to true
 * @returns '9:30 AM - 11:00 AM MST' depending on the timezone.
 */
export function getUniqueEventTimes(
  events?: any[] | null, // TO DO: Type
  timezone = 'America/Los_Angeles',
  showTimezone = true
) {
  if (!events || !events.length) return []

  const currentTimezone = getCurrentTimezone(timezone)
  const abbreviatedTimezone = getTimezoneAbbreviation(currentTimezone)
  const uniqueEventTimes: string[] = []

  events.forEach((event) => {
    const startTime = format(
      utcToNewTimezone(event.startsAt, currentTimezone),
      HOUR_MIN_FORMAT
    )
    const endTime = format(
      utcToNewTimezone(event.endsAt, currentTimezone),
      HOUR_MIN_FORMAT
    )
    const startAndEndTime = showTimezone
      ? `${startTime} - ${endTime} ${abbreviatedTimezone}`
      : `${startTime} - ${endTime}`
    if (!uniqueEventTimes.includes(startAndEndTime)) {
      uniqueEventTimes.push(startAndEndTime)
    }
  })

  return uniqueEventTimes
}

/**
 * Gets a human readable string of event days based on the course session's events
 * and the user's timezone.
 * @param {array} events -  - any[]) TO DO: TYPES
 * @param {string} timezone - user timezone, defaults to 'America/Los_Angeles'
 * @param {boolean} fullDays - whether to show full day names or abbreviations, defaults to false
 * @returns 'Tues, Wed, Thurs' or 'Tuesday, Wedsnesday, Thursday' depending on the fullDays.
 */
export function getUniqueEventDays(
  events?: any[] | null,
  timezone = 'America/Los_Angeles',
  fullDays = false
) {
  if (!events || !events.length) return []
  const tz = getCurrentTimezone(timezone)

  const uniqueEventDays: string[] = []

  const dayFormat = fullDays ? 'EEEE' : 'EE'

  events.forEach((event) => {
    const date = utcToNewTimezone(event.startsAt, tz)
    const day = format(date, dayFormat)
    if (!uniqueEventDays.includes(day)) {
      uniqueEventDays.push(day)
    }
  })

  return uniqueEventDays
}

/**
 *
 * @param instructors
 * @param creators
 * @returns an array of instructors (courseSession 'experts') who are not also a creator of the course
 */
export function getNonCreatorInstructors(instructors: any[], creators: any[]) {
  const creatorIds = new Set(creators.map((creator) => creator.id))
  return instructors.filter((instructor) => !creatorIds.has(instructor.id))
}

/**
 *
 * @param instructors
 * @param creators
 * @returns an array of instructors (courseSession 'experts') who are also a creator of the course
 */
export function getCreatorInstructors(instructors: any[], creators: any[]) {
  const creatorIds = new Set(creators.map((creator) => creator.id))
  return instructors.filter((instructor) => creatorIds.has(instructor.id))
}
