import React, { useState } from 'react'

import { ProgressBar } from 'pages/TeamMembersPage/ProgressBar/ProgressBar'

import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemHeader
} from 'components/AccordionView'
import Button from 'components/Button'
import { useCheckoutModal } from 'components/CheckoutModal/useCheckoutModal'
import { ToggleSwitch } from 'components/ToggleSwitch'
import Tooltip from 'components/Tooltip/Tooltip'
import { BasicInfoIcon } from 'components/icons'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { TeamMembersPageSubscriptionFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { ReactComponent as ClipboardTableIcon } from 'images/icon--clipboard-table.svg'
import { ReactComponent as TicketPassIcon } from 'images/icon--ticket-pass.svg'

export interface SeatSummaryProps {
  subscription: TeamMembersPageSubscriptionFragment
  totalSeatCount: number
  assignedSeatcount: number
  totalCohortCreditCount: number
  assignedCohortCreditCount: number
  isAssistantAdmin?: boolean | null
  manualAssign: boolean | undefined | null
  onToggleManualAssign: () => void
  autoAssignCoursePass: boolean
}

export const SeatSummary = ({
  autoAssignCoursePass,
  subscription,
  totalSeatCount,
  assignedSeatcount,
  totalCohortCreditCount,
  assignedCohortCreditCount,
  isAssistantAdmin,
  manualAssign,
  onToggleManualAssign
}: SeatSummaryProps) => {
  const isIndividual = subscription.numSeats === 1
  const { openCheckoutModal } = useCheckoutModal()
  const [openAccordions, setOpenAccordions] = useState<number[]>([])

  const planDetailsContent = (
    <div
      className="flex flex-col gap-2 rounded md:border px-6 pt-4 md:h-full lg:h-auto"
      data-test="plan-details"
    >
      <div>
        <div className="hidden md:block text-lg mb-5 font-semibold">Membership Seats</div>
        <ProgressBar
          totalCount={totalSeatCount}
          usedCount={assignedSeatcount}
          countNoun="seat"
        />
      </div>
      <div className="mt-0 -ml-[22px]">
        <Button
          variant="text-only"
          className={
            isAssistantAdmin
              ? 'cursor-not-allowed md:mt-0 lg:mt-auto md:py-1 lg:py-4'
              : 'md:mt-0 md:py-1 lg:py-4 lg:mt-auto text-[#1A6C69] hover:text-[#1A6C69] hover:underline hover:bg-transparent cursor-pointer'
          }
          href={!isAssistantAdmin ? '/manage-plan' : undefined}
          disabled={isAssistantAdmin || false}
        >
          Manage plan
        </Button>
      </div>
    </div>
  )

  const passesDetailsContent = (
    <div
      className="flex flex-col gap-2 rounded md:border px-6 pt-4 pb-4 lg:pb-0"
      data-test="pass-details"
    >
      <div>
        <div className="hidden md:block text-lg mb-5 font-semibold">Course Passes</div>
        <ProgressBar
          totalCount={totalCohortCreditCount}
          usedCount={assignedCohortCreditCount}
          countNoun="pass"
        />
      </div>
      <div className="mt-0 sm:-ml-[22px] flex flex-col items-start lg:flex-row lg:justify-between lg:items-center">
        <Button
          className={
            isAssistantAdmin
              ? 'cursor-not-allowed -ml-[16px] sm:ml-0 py-[4px] lg:py-[16px]'
              : '-ml-[16px] sm:ml-0 py-[4px] lg:py-[16px] text-[#1A6C69] hover:text-[#1A6C69] hover:underline hover:bg-transparent cursor-pointer'
          }
          onClick={() =>
            openCheckoutModal({ isIndividual, flowContext: 'cohortPassPurchase' })
          }
          disabled={isAssistantAdmin || false}
          variant="text-only"
          size={
            useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`) ? 'x-small' : undefined
          }
        >
          Add passes
        </Button>
        {autoAssignCoursePass && (
          <div className="sm:ml-[24px] flex items-center gap-2 lg:ml-0">
            <span className="text-sm flex-shrink-0 whitespace-nowrap">
              Require pass assignment
            </span>
            <Tooltip
              place="bottom"
              html={true}
              delayUpdate={300}
              delayHide={300}
              className="!opacity-100 flex-shrink-0"
              tooltipBody="If toggled off, any live course pass will be available<br /> for use by any team member."
            >
              <BasicInfoIcon
                className="text-rb-gray-300 hover:text-rb-gray-400 w-6 h-6 flex-shrink-0"
                fill="currentColor"
              />
            </Tooltip>
            <ToggleSwitch
              dataTest="manual-assign-toggle"
              className="mt-[6px]"
              toggled={manualAssign ?? false}
              canEdit={!isAssistantAdmin}
              handleClick={onToggleManualAssign}
            />
          </div>
        )}
      </div>
    </div>
  )

  const accordionData = [
    {
      title: (
        <div className="flex items-center">
          <ClipboardTableIcon className="w-6 h-6 mr-4" />
          <span>Membership Seats</span>
        </div>
      ),
      content: planDetailsContent,
      accordionHeaderId: 'plan-details-header',
      accordionContentId: 'plan-details-content'
    },
    {
      title: (
        <div className="flex items-center">
          <TicketPassIcon className="w-6 h-6 mr-4" />
          <span>Course Passes</span>
        </div>
      ),
      content: passesDetailsContent,
      accordionHeaderId: 'passes-details-header',
      accordionContentId: 'passes-details-content'
    }
  ]

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-6 gap-2 md:gap-4">
      {accordionData.map((item, index) => (
        <div
          key={index}
          className={`${index === 0 ? 'md:col-span-3' : 'md:col-span-3'} md:h-full`}
        >
          <div className="md:hidden">
            <AccordionItem>
              <AccordionItemHeader
                id={item.accordionHeaderId}
                isOpen={openAccordions.includes(index)}
                onToggle={() => {
                  setOpenAccordions((prev) =>
                    prev.includes(index)
                      ? prev.filter((i) => i !== index)
                      : [...prev, index]
                  )
                }}
              >
                <div className="text-lg font-semibold mb-0 text-rb-gray-300 py-2 px-2">
                  {item.title}
                </div>
              </AccordionItemHeader>
              <AccordionItemContent
                id={item.accordionContentId}
                isOpen={openAccordions.includes(index)}
              >
                {item.content}
              </AccordionItemContent>
            </AccordionItem>
          </div>
          <div className="hidden md:block h-full">{item.content}</div>
        </div>
      ))}
    </div>
  )
}
