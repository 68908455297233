import { uniqBy } from 'lodash'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { ModifyEnrollment } from 'components'
import { isPathActive } from 'components/LeftSidebar/NavigationTab'

import { CohortLinksPartsFragment, CohortLinksUserPartsFragment } from 'gql'

import { isAfterDate, isBeforeDate } from 'utils/date'

export const useShouldShowLiveProgramLinksSection = (
  currentUser?: CohortLinksUserPartsFragment | null
) => {
  if (!currentUser || currentUser.accessPolicyKind === 'expired') {
    return false
  }

  // the latest live cohort that the user is enrolled in
  if (currentUser.cohorts?.latestCurrent) {
    return true
  }

  return (
    currentUser.hostCohortsForActiveSeasons?.length > 0 ||
    currentUser.eventSpeakerCohortsForActiveSeasons?.length > 0
  )
}

const liveProgramDetail = (
  cohort: CohortLinksPartsFragment,
  pathname: string,
  isCollapsed: boolean
) => {
  const isActive = isPathActive({
    pathname,
    path: `/cohorts/${cohort.slug}`
  })

  return (
    <span className="flex items-center text-xl">
      <span
        className={` ${
          isCollapsed ? 'ml-[15%]' : 'ml-0 lg:ml-[20px]'
        } flex h-9 w-9 items-center rounded-[18px] bg-white`}
      >
        <div className="w-9">
          <img
            className="ml-[7px] h-[22px] w-[22px]"
            src={cohort.cmsProgram.iconImageUrl || ''}
            alt={cohort.cmsProgram.name}
          />
        </div>
      </span>
      <span
        className={
          isActive
            ? 'ml-4 inline-block w-[60%] whitespace-normal break-words align-middle text-sm font-medium leading-[18px] tracking-normal !text-rb-gray-400'
            : `${
                isCollapsed && 'whitespace-nowrap opacity-0'
              } ml-4 inline-block w-[60%] whitespace-normal break-words align-middle text-sm font-medium leading-[18px] tracking-normal text-rb-gray-400`
        }
      >
        {cohort.cmsProgram.name}
      </span>
    </span>
  )
}

interface AvailableLiveProgramProps {
  cohort: CohortLinksPartsFragment
  canBeUpdated: boolean
  pathname: string
  isCollapsed: boolean
  enrollmentSeason: any
}

const AvailableLiveProgram = ({
  cohort,
  canBeUpdated,
  pathname,
  isCollapsed,
  enrollmentSeason
}: AvailableLiveProgramProps) => {
  const [isEnrollmentModalOpen, setIsEnrollmentModalOpen] = useState(false)
  const [clickedMoreInfo, setClickedMoreInfo] = useState(false)

  const handleMoreInfoClick = () => {
    setClickedMoreInfo(true)
    setIsEnrollmentModalOpen(true)
  }

  const onModifyModalClose = () => {
    setClickedMoreInfo(false)
    setIsEnrollmentModalOpen(false)
  }

  return (
    <li
      key={`sb-desktop-live-program-${cohort.cmsProgram.id}`}
      className={twMerge(
        'relative pb-[4px]',
        isCollapsed ? 'inline' : 'flex flex-wrap items-center',
        'icon-link rounded pl-5 pr-5 lg:py-[9px] lg:pr-1 lg:pl-0',
        isAfterDate(enrollmentSeason?.startDate) ? 'hover:bg-rb-gray-100 ' : '',
        isPathActive({ pathname, path: `/cohorts/${cohort.slug}` })
          ? 'bg-rb-green/60 hover:bg-rb-green/60'
          : ''
      )}
    >
      {' '}
      {cohort?.season?.showCohortViewerAt &&
      isBeforeDate(cohort?.season?.showCohortViewerAt) ? (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={handleMoreInfoClick}
          onClick={handleMoreInfoClick}
          className={`${
            isCollapsed && 'block min-w-[40px] py-2 px-[5px]'
          } w-[80%] hover:no-underline disabled:cursor-default`}
        >
          {liveProgramDetail(cohort, pathname, isCollapsed)}
        </div>
      ) : (
        <a
          href={`/cohorts/${cohort.slug}`}
          target="_blank"
          id="live_program_link"
          className={`${
            isCollapsed && 'block min-w-[40px] py-2 px-[5px]'
          } w-[80%] hover:no-underline disabled:cursor-default`}
          data-test="live_program_link"
          data-testid={`live_program_link_${cohort.id}`}
          rel="noreferrer"
        >
          {liveProgramDetail(cohort, pathname, isCollapsed)}
        </a>
      )}
      {!isCollapsed && !!enrollmentSeason && canBeUpdated && (
        <ModifyEnrollment
          id="left-sidebar"
          pencilClassName="fill-rb-gray-300"
          className="absolute right-[12%] ml-0"
          isMoreInfoScreen={clickedMoreInfo}
          isModifyModalOpen={isEnrollmentModalOpen}
          expandedProgramId={clickedMoreInfo ? cohort.cmsProgram.id : undefined}
          onModifyModalClose={onModifyModalClose}
        />
      )}
    </li>
  )
}

interface LiveProgramLinksProps {
  pathname: string
  isCollapsed: boolean
  enrollmentSeason: any
  currentUser: CohortLinksUserPartsFragment
}

const LiveProgramLinks = ({
  pathname,
  isCollapsed,
  enrollmentSeason,
  currentUser
}: LiveProgramLinksProps) => {
  let cohortsToDisplayAsLiveProgramLinks = [
    ...currentUser.eventSpeakerCohortsForActiveSeasons,
    ...currentUser.hostCohortsForActiveSeasons
  ]

  const latestLiveCohort = currentUser?.cohorts?.latestCurrent?.cohort
  if (latestLiveCohort) {
    cohortsToDisplayAsLiveProgramLinks = [
      latestLiveCohort,
      ...cohortsToDisplayAsLiveProgramLinks
    ]
  }

  const dedupedCohortsToDisplayAsLiveProgramLinks = uniqBy(
    cohortsToDisplayAsLiveProgramLinks,
    (cohort) => cohort.id
  )

  return (
    <div>
      {dedupedCohortsToDisplayAsLiveProgramLinks.map((cohort) => {
        return (
          <AvailableLiveProgram
            key={`available-live-program-cohortid${cohort.id}`}
            cohort={cohort}
            canBeUpdated={latestLiveCohort?.id === cohort.id}
            pathname={pathname}
            isCollapsed={isCollapsed}
            enrollmentSeason={enrollmentSeason}
          />
        )
      })}
    </div>
  )
}

interface LiveProgramSectionProps {
  pathname: string
  isCollapsed: boolean
  enrollmentSeason: any
  currentUser: CohortLinksUserPartsFragment
}

const LiveProgramLinksSection = ({
  pathname,
  isCollapsed,
  enrollmentSeason,
  currentUser
}: LiveProgramSectionProps) => (
  <div data-test="sidebar-nav--live-cohort-link" className="mb-8">
    <li
      className={twMerge(
        isCollapsed ? 'text-[11.2px] opacity-0' : '',
        'mt-8 mr-0 ml-[20px] mb-2 text-xs font-medium uppercase leading-[1.5] tracking-widest text-rb-gray-300'
      )}
    >
      My Live Program
    </li>
    <LiveProgramLinks
      pathname={pathname}
      isCollapsed={isCollapsed}
      enrollmentSeason={enrollmentSeason}
      currentUser={currentUser}
    />
  </div>
)

export default LiveProgramLinksSection
