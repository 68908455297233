import { CohortsPageAccessUserPartsFragment } from 'gql'

export interface UseBlockCurrentCohortPageProps {
  slug: String
  currentUser: CohortsPageAccessUserPartsFragment
}

const useBlockCurrentCohortPage = ({
  slug,
  currentUser
}: UseBlockCurrentCohortPageProps) => {
  // until launchDarkly is implemented for the backend, this is a workaround frontend check give staff/collective
  // the ability to view this page for cohorts they are not enrolled in
  const userWasOrIsEnrolledInCohort = (currentUser.cohorts?.all || []).some(
    (cohort) => cohort?.cohort?.slug === slug
  )

  // user is/was enrolled in this cohort so do not block this cohort page from them
  if (userWasOrIsEnrolledInCohort || currentUser.is.staff || currentUser.is.collective) {
    return false
  }

  return true
}

export default useBlockCurrentCohortPage
