import { useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Redirect } from 'react-router-dom'

import AntiCopy from 'domains/AntiPiracy/AntiCopy'
import { useAntiCopy } from 'domains/AntiPiracy/hooks/useAntiCopy'
import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CmsSectionDescription from 'domains/Cms/CmsSectionDescription'
import { CmsContentPartialContent } from 'domains/Cms/CmsSectionPartialContent'
import { CommentableBlockContainer } from 'domains/Cms/CommentableBlocksApp'
import ContentHeader from 'domains/Cms/ContentHeader'
import CmsContentToc from 'domains/Cms/ContentToc'
import CopyrightFooter from 'domains/Cms/CopyrightFooter'
import FeedbacksCmsContent from 'domains/Cms/FeedbacksCmsContent'
import RelatedContent from 'domains/Cms/RelatedContent'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { ErrorMessage, Loading } from 'components'
import { SideDrawer, SideDrawerProvider, useSideDrawer } from 'components/SideDrawer'
import { DeliverableCard } from 'components/cards/DeliverableCard'

import {
  BookmarkType,
  ProgramBookmarkPartsFragment,
  useBookmarkFoldersQuery,
  useContentViewerQuery
} from 'gql'

import useContentViewerUrlData from 'hooks/useContentViewerUrlData'
import { useCurrentUser } from 'hooks/useCurrentUser'

import { matchesViewAccessApolloError } from 'utils/graphqlErrors'
import { mapNodes } from 'utils/mapNodes'
import { track } from 'utils/tracking/segment'

import { SharedType } from 'typings/sharedType'

import DiscoveryDrawer from '../components/DiscoveryDrawer'

const leftDrawerId = 'tocDrawer'
const rightDrawerId = 'discoveryDrawer'

type ContentViewerProps = {
  cmsContentType?: SharedType
  cmsSectionSlug?: string
}

const ContentViewer = (props: ContentViewerProps) => {
  const contentViewerUrlData = useContentViewerUrlData()
  const [activeDiscoveryDrawerPanel, setActiveDiscoveryDrawerPanel] =
    useState('bookmarks')
  const cmsContentType = props.cmsContentType || contentViewerUrlData.cmsContentType
  const cmsSectionSlug = props.cmsSectionSlug || contentViewerUrlData.cmsSectionSlug
  const [isInSavedItems, setIsInSavedItems] = useState(false)

  const { currentUser, currentUserLoading, currentUserError } = useCurrentUser()

  const {
    loading: contentViewerLoading,
    error: contentViewerError,
    data: contentViewerData
  } = useContentViewerQuery({
    variables: {
      cmsType: cmsContentType,
      slug: cmsSectionSlug,
      path: location.pathname
    }
  })
  useEffect(() => {
    setIsInSavedItems(
      !!contentViewerData?.contentViewer?.cmsContent?.contentIsInMySavedItems
    )
  }, [contentViewerData?.contentViewer?.cmsContent?.contentIsInMySavedItems])

  const {
    isAddToBookmarkFolderModalOpen,
    openAddToBookmarkFolderModal,
    closeAddToBookmarkFolderModal,
    currentBookmarkForDropdown
  } = useOpenAddToBookmarkFolderModal()
  const {
    isCreateBookmarkFolderModalOpen,
    openCreateBookmarkFolderModal,
    closeCreateBookmarkFolderModal,
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate
  } = useOpenCreateBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  const handleOpenAddToBookmarkFolderModal = (bookmark: ProgramBookmarkPartsFragment) => {
    if (isDrawerOpen(leftDrawerId)) toggleDrawer(leftDrawerId)
    openAddToBookmarkFolderModal(bookmark)
  }

  const { isDrawerOpen, toggleDrawer } = useSideDrawer()

  const { data: bookmarkFolderData } = useBookmarkFoldersQuery()

  const loading = currentUserLoading || contentViewerLoading
  const error = currentUserError || contentViewerError

  const fullLength =
    contentViewerData?.contentViewer?.cmsContent?.descriptionText?.length || 0

  useAntiCopy({ fullLength, currentUser })

  const blockedContentHtmlSafe =
    contentViewerData?.contentViewer?.cmsContent?.blockedContentHtmlSafe

  useEffect(() => {
    const phaseElement = document.getElementById('phase-0')
    if (phaseElement) {
      phaseElement.classList.remove('uk-hidden')
      const phases = document.querySelectorAll('.phase')
      const relatedContentDiv = document.getElementById('js-related-content')
      const feedbackDiv = document.getElementById('js-content-feedback')
      const summaryDiv = document.getElementById('js-content-summary')
      if (phases[phases.length - 1].id === phaseElement.id) {
        if (relatedContentDiv) {
          relatedContentDiv.classList.remove('uk-hidden')
        }
        if (feedbackDiv) {
          feedbackDiv.classList.remove('uk-hidden')
        }
        if (summaryDiv) {
          summaryDiv.classList.remove('uk-hidden')
        }
      } else {
        if (relatedContentDiv) {
          relatedContentDiv.classList.add('uk-hidden')
        }
        if (feedbackDiv) {
          feedbackDiv.classList.add('uk-hidden')
        }
        if (summaryDiv) {
          summaryDiv.classList.add('uk-hidden')
        }
      }
    }
  }, [blockedContentHtmlSafe])

  const onSetSideDrawerOpen = useCallback((isOpen: boolean) => {
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      action: isOpen ? 'ToC__show' : 'ToC__hide',
      location: window.location.pathname
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  if (error || !contentViewerData?.contentViewer) {
    if (matchesViewAccessApolloError(error)) {
      // wait for redirect from apollo client onError
      return <Loading />
    }
    return (
      <div className="min-h-screen uk-grid ml-0">
        <ErrorMessage error={error || new Error('Failed to load content viewer data.')} />
      </div>
    )
  }

  if (!currentUser) {
    return <Redirect to="/" />
  }

  const { contentViewer } = contentViewerData

  const { cmsProgram, cmsModule, cmsContent, userProgram } = contentViewer

  if (!cmsProgram || !cmsModule || !cmsContent) {
    return (
      <div className="min-h-screen uk-grid ml-0">
        <ErrorMessage error={new Error('Failed to load content viewer data.')} />
      </div>
    )
  }

  const needsFeedback =
    cmsModule.showFeedback &&
    cmsContent.showFeedback &&
    !cmsContent.currentUserSubmittedFeedback &&
    !currentUser.preference?.skipFeedback &&
    contentViewer.viewAccess === 'full'

  const groups = mapNodes(cmsContent.groups)
  const lastGroup = groups[groups.length - 1]

  const trackActionButtonClick = (type: 'discussions' | 'bookmarks') => {
    const actionTrigger = {
      discussions: 'comment_button_header',
      bookmarks: 'bookmark_button_header'
    }[type]

    const anchor = {
      discussions: 'discovery-drawer-discussions-button',
      bookmarks: 'discovery-drawer-bookmarks-button'
    }[type]

    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      user_id: currentUser.id,
      cms_program_id: cmsContent?.cmsProgram.id,
      cms_section_id: cmsContent?.id,
      action: 'display_content_viewer_drawer',
      anchor,
      action_trigger: actionTrigger,
      location: window.location.pathname
    })
  }

  return (
    <>
      <SideDrawerProvider
        onSetIsOpen={onSetSideDrawerOpen}
        localStorageKey="content-viewer-open-drawer"
        openDrawerId={leftDrawerId}
      >
        <Helmet>
          <title>{cmsContent.name}</title>
        </Helmet>
        <div className="uk-grid-collapse min-h-screen ml-0" uk-grid="">
          <div className="uk-width-expand">
            <div className="flex justify-between">
              <SideDrawer
                className="uk-overflow-auto bg-white md:transition-all md:duration-300 md:ease-out"
                id={leftDrawerId}
              >
                <div className="cms-sidebar box-border min-w-[400px] pt-0 pr-0 pb-[30px] font-sans text-[15px] text-rb-gray-500">
                  {' '}
                  <CmsContentToc contentViewer={contentViewer} />
                </div>
              </SideDrawer>
              <div className="uk-width-expand cms-container relative">
                <ContentHeader
                  contentViewer={contentViewer}
                  leftDrawerId={leftDrawerId}
                  rightDrawerId={rightDrawerId}
                  setActiveDiscoveryDrawerPanel={setActiveDiscoveryDrawerPanel}
                  activeDiscoveryDrawerPanel={activeDiscoveryDrawerPanel}
                  trackActionButtonClick={trackActionButtonClick}
                  openAddToBookmarkFolderModal={handleOpenAddToBookmarkFolderModal}
                  isInSavedItems={isInSavedItems}
                  setIsInSavedItems={setIsInSavedItems}
                />
                <AddBookmarkToFolderModal
                  isOpen={isAddToBookmarkFolderModalOpen}
                  handleClose={closeAddToBookmarkFolderModal}
                  bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
                  openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
                  currentBookmarkForDropdown={currentBookmarkForDropdown}
                  showCollectionsOnboardingInfo={
                    !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
                  }
                />
                <CreateBookmarkFolderModal
                  isModalOpen={isCreateBookmarkFolderModalOpen}
                  handleClose={closeCreateBookmarkFolderModal}
                  currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
                  trackingTriggerAction={'bookmark'}
                />
                <div id="content">
                  <div
                    className={`cms-${cmsContentType} left-auto`} /* cms-concept or cms-project */
                  >
                    <div className="wysiwig-container pr-2.5 pl-2.5 xs:pr-4 xs:pl-4 md:pr-8 md:pl-8">
                      <div className="fr-content-wrapper box-border min-h-[calc(100vh-100px)] max-w-[970px] transition-width-margin duration-300 lg:my-0 lg:mx-auto">
                        {contentViewer.viewAccess === 'partial' && (
                          <CmsContentPartialContent
                            name={cmsContent.name}
                            truncatedDescriptionHtml={cmsContent.truncatedDescriptionHtml}
                          />
                        )}
                        {contentViewer.viewAccess === 'full' && (
                          <>
                            {contentViewer.cmsProjectDeliverable && (
                              <div className="float-right mt-6 hidden tl:block">
                                <DeliverableCard
                                  cmsContentId={cmsContent.id}
                                  cmsDeliverableId={
                                    contentViewer.cmsProjectDeliverable.id
                                  }
                                  userProgress={
                                    userProgram?.progress?.[cmsModule.id] || {}
                                  }
                                  cmsExamples={cmsContent.deliverableExamples}
                                  userEmails={currentUser.contact.allEmails}
                                  projectEmail={currentUser.contact.projectEmail}
                                />
                              </div>
                            )}
                            {cmsContent.introHtmlSafe && (
                              <div className="uk-grid uk-grid-collapse mt-7">
                                <div
                                  id="cms-intro"
                                  className="cms-intro uk-width-expand h-full max-w-[700px] bg-rb-gray-50 p-2.5 xs:p-4 md:p-8"
                                >
                                  {/* TODO: arbitrary html tailwind conversion */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: cmsContent.introHtmlSafe
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                            <CmsSectionDescription
                              cmsSection={cmsContent}
                              descriptionHTML={cmsContent.blockedContentHtmlSafe}
                              setActiveDiscoveryDrawerPanel={
                                setActiveDiscoveryDrawerPanel
                              }
                              trackActionButtonClick={trackActionButtonClick}
                              openAddToBookmarkFolderModal={
                                handleOpenAddToBookmarkFolderModal
                              }
                            />
                            {/* # dummy end marker for progress tracking */}
                            <h1 id="end-center-content" className="m-0"></h1>
                            {cmsContent.summaryHtmlSafe && (
                              <div
                                id="js-content-summary"
                                className="uk-grid uk-grid-collapse"
                              >
                                <div className="uk-width-expand bg-rb-gray-50-light-blue mt-10 max-w-[700px] py-7 pl-6 pr-2.5 xs:pr-4 md:pr-8 ">
                                  <div className="min-h-[100px] bg-[url('/app/assets/images/icon--finger-right.svg')] bg-left-top bg-no-repeat">
                                    {/* TODO: arbitrary html tailwind conversion */}
                                    <div
                                      className="cms-summary__content pl-14 font-sans text-base text-rb-gray-500"
                                      dangerouslySetInnerHTML={{
                                        __html: cmsContent.summaryHtmlSafe
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {needsFeedback && (
                              <div
                                id="js-content-feedback"
                                className="mt-12 mb-10 text-center sm:text-left"
                                uk-scrollspy="cls: uk-animation-fade; repeat: true"
                                data-test="content-feedback-bottom"
                              >
                                <FeedbacksCmsContent
                                  contentType={cmsContentType}
                                  cmsContentId={cmsContent.id}
                                  cmsProgramId={cmsProgram.id}
                                  userId={currentUser.id}
                                />
                              </div>
                            )}
                          </>
                        )}

                        {cmsContent.relatedContent && (
                          <RelatedContent
                            relatedCards={cmsContent.relatedContent}
                            cmsContentId={cmsContent.id}
                            userProgress={userProgram?.progress || {}}
                          />
                        )}
                      </div>
                      <div id="anticopy"></div>
                      <AntiCopy isAdmin={currentUser.is.admin} userId={currentUser.id} />
                    </div>
                    <CopyrightFooter />
                  </div>

                  <div
                    id="cms_section_feedbacks"
                    data-answered={needsFeedback ? 'false' : 'true'}
                  />
                  <a
                    id="right-sidenav-toggle"
                    uk-toggle="target: #right-sidenav; cls: right-nav-revealed"
                    hidden
                  ></a>
                </div>
              </div>
              <DiscoveryDrawer
                id={rightDrawerId}
                activePanel={activeDiscoveryDrawerPanel}
                setActivePanel={setActiveDiscoveryDrawerPanel}
                contentBookmarkId={cmsContent?.contentBookmarkId}
                isInSavedItems={isInSavedItems}
                setIsInSavedItems={setIsInSavedItems}
                cmsProgramId={cmsProgram.id}
                cmsSectionId={cmsContent.id}
                bookmarkType={
                  cmsContent?.contentType === 'Concept'
                    ? BookmarkType.CONCEPTBOOKMARK
                    : BookmarkType.PROJECTBOOKMARK
                }
                userId={currentUser.id}
                cmsModuleId={cmsModule.id}
                openAddToBookmarkFolderModal={handleOpenAddToBookmarkFolderModal}
              >
                <DiscoveryDrawer.BookmarksPanel
                  isActive={activeDiscoveryDrawerPanel === 'bookmarks'}
                  cmsProgramId={cmsProgram.id}
                  cmsModuleId={cmsModule.id}
                  cmsProgramName={cmsProgram.name}
                  cmsContent={cmsContent}
                  cmsSectionId={cmsContent.id}
                />
                <DiscoveryDrawer.DiscussionsPanel
                  isActive={activeDiscoveryDrawerPanel === 'discussions'}
                  cmsProgramId={cmsProgram.id}
                  cmsModuleId={cmsModule.id}
                  cmsSectionId={cmsContent.id}
                  userId={currentUser.id}
                  isTrial={false}
                />
              </DiscoveryDrawer>
              <div
                id="right-sidenav"
                className="uk-overflow-auto absolute top-0 right-0 h-full w-0 overflow-x-hidden bg-rb-gray-50 opacity-0 sm:opacity-100 sm:transition-width"
              >
                <CommentableBlockContainer
                  cmsProgramId={cmsProgram.id}
                  cmsModuleId={cmsModule.id}
                  cmsContentId={cmsContent.id}
                  cmsContentEndpoint={`/api/v1/cms_sections/${cmsContent.id}`}
                  contentType={cmsContent.contentType || cmsContentType}
                  isPremember={currentUser.is.premember}
                  userId={currentUser.id}
                  initialGroupId={lastGroup?.id}
                  initialTopicId={cmsContent?.topic?.id}
                  setActivePanel={setActiveDiscoveryDrawerPanel}
                  accessPolicyKind={currentUser.accessPolicyKind}
                  hasApplied={currentUser.hasApplied}
                />
              </div>
            </div>
          </div>
        </div>
      </SideDrawerProvider>
    </>
  )
}

export default ContentViewer
