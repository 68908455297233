import { useEffect, useMemo } from 'react'

import { CloseIcon } from 'components/icons'

import { ReactComponent as AlertIcon } from 'images/p-alert.svg'

export interface EmailChipProps {
  email: string
  onRemove: (email: string) => void
  disallowedEmails?: string[]
  onDisallowedEmail?: (email: string) => void
}

export const EmailChip = ({
  email,
  onRemove,
  disallowedEmails,
  onDisallowedEmail
}: EmailChipProps) => {
  const isDisallowed = useMemo(() => {
    return disallowedEmails?.some(
      (disallowedEmail) => disallowedEmail.toLowerCase() === email.toLowerCase()
    )
  }, [email, disallowedEmails])

  useEffect(() => {
    if (isDisallowed && onDisallowedEmail) {
      onDisallowedEmail(email)
    }
  }, [isDisallowed, email, onDisallowedEmail])

  return (
    <>
      {isDisallowed ? (
        <div className="h-6 p-2.5 m-0.5 ml-0 mr-1 bg-[#ffdede] rounded-md border border-[#c73d23] justify-center items-center gap-1.5 inline-flex">
          <div className="w-3.5 h-3.5 relative">
            <AlertIcon aria-hidden="true" />
          </div>
          <span className="sr-only" role="alert" aria-live="polite">
            Invalid email: {email}
          </span>
          <div
            className="text-[#c73d23] text-xs font-normal font-['Inter'] leading-5"
            aria-hidden="true"
          >
            {email}
          </div>
          <button
            className="text-[#c73d23] ml-2 align-middle"
            onClick={() => onRemove(email)}
            aria-label={`Remove ${email}`}
          >
            <CloseIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
          </button>
        </div>
      ) : (
        <div className="h-6 p-2.5 m-0.5 ml-0 mr-1 bg-[#F6F6F5] rounded-md border border-[#bbbdba] justify-center items-center gap-1.5 inline-flex">
          <div className="text-[#2d2f2f] text-xs font-normal font-['Inter'] leading-5">
            {email}
          </div>
          <button
            className="ml-2 text-gray-600 align-middle"
            onClick={() => onRemove(email)}
            aria-label={`Remove ${email}`}
          >
            <CloseIcon className="h-4 w-4 stroke-1" aria-hidden="true" />
          </button>
        </div>
      )}
    </>
  )
}
