import Lottie from 'lottie-react'
import { RefObject } from 'react'

import { usePersonalizeModal } from 'pages/HomePage/LoggedInHomepage/PersonalizeModal/usePersonalizeModal'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'
import { ChecklistOverlay } from 'domains/Onboarding/Checklist/ChecklistOverlay'
import {
  ChecklistRow,
  ChecklistRowProps
} from 'domains/Onboarding/Checklist/ChecklistRow'

import Button from 'components/Button'

import { ONBOARDING_CHECKLIST } from 'constants/checklistNames'
import { INSTALL_EXTENSION } from 'constants/checklistTaskNames'

import { ChecklistTask, useCompleteChecklistTaskMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  trackAiChatActionClicked,
  trackCtaClicked,
  trackNavigationClicked
} from 'utils/tracking/analytics'

import rocketAnimation from 'assets/animations/rocket.json'

interface OnboardingChecklistWrapperProps {
  checklistTasks: ChecklistTask[]
  closeChecklist: () => void
  openButtonRef: RefObject<HTMLDivElement> | null
}

export const OnboardingChecklistWrapper = ({
  checklistTasks,
  closeChecklist,
  openButtonRef
}: OnboardingChecklistWrapperProps) => {
  const [completeChecklistTask] = useCompleteChecklistTaskMutation()
  const { openPersonalizeModal } = usePersonalizeModal()
  const { currentUser } = useCurrentUser()
  const { setShowProductTourView, toggle } = useGlobalChat()

  // NOTE: The keys to this object must match the name column of the checklist_tasks table
  const onboardingChecklistTaskData: { [key: string]: ChecklistRowProps } = {
    personalize_your_account: {
      title: 'Personalize your account',
      subtitle: 'You can always update this later',
      action: () => {
        closeChecklist()
        openPersonalizeModal()
        trackCtaClicked({
          cta_location: 'checklist',
          cta_type: 'menu_select',
          text: 'personalize your account'
        })
      }
    },
    attend_new_member_event: {
      title: 'Attend an onboarding event',
      subtitle: 'We’ll show you the ropes',
      action: () => {
        closeChecklist()
        window.location.href = '/events'
        trackNavigationClicked({
          text: 'attend an onboarding event',
          destination: '/events',
          type: 'top nav link',
          location: 'checklist',
          logged_in: true,
          path: window.location.pathname
        })
      }
    },
    onboarding_tour: {
      title: 'Take a quick tour',
      subtitle: 'See how Reforge can help',
      action: () => {
        closeChecklist()
        toggle({ shouldOpen: true, ctaText: 'Reforge AI' })
        setShowProductTourView(true)
        trackAiChatActionClicked({
          text: 'take a quick tour',
          access_policy_kind: currentUser?.accessPolicyKind,
          action: 'hardcoded_tour',
          location: 'onboarding_checklist'
        })
      }
    },
    install_extension: {
      title: 'Install the Reforge extension',
      subtitle: 'Get expert feedback as you work',
      action: async () => {
        closeChecklist()
        trackNavigationClicked({
          text: 'install the reforge extension',
          destination: '/extension',
          type: 'top nav link',
          location: 'checklist',
          logged_in: true,
          path: window.location.pathname
        })
        window.open('https://www.reforge.com/extension', '_blank')?.focus()
        await completeChecklistTask({
          variables: {
            input: {
              checklistTaskName: INSTALL_EXTENSION
            }
          },
          refetchQueries: ['ChecklistTasks']
        })
      }
    }
  }

  const checklistTasksDecorated = []
  for (let i = 0; i < checklistTasks.length; i++) {
    const task = checklistTasks[i]
    if (!onboardingChecklistTaskData[task.name]) {
      console.error(`No data set for task ${task.name}`)
      continue
    }
    checklistTasksDecorated.push({
      isCompleted: task.completed,
      row: (
        <ChecklistRow
          key={task.id}
          title={onboardingChecklistTaskData[task.name].title}
          subtitle={onboardingChecklistTaskData[task.name].subtitle}
          action={onboardingChecklistTaskData[task.name].action}
          isCompleted={task.completed}
        />
      )
    })
  }

  const handleExploreClick = () => {
    trackNavigationClicked({
      text: 'explore reforge',
      destination: '/explore',
      type: 'top nav link',
      location: 'checklist',
      logged_in: true,
      path: window.location.pathname
    })
    closeChecklist()
    window.location.href = '/explore'
  }

  const completionView = (
    <div className="flex flex-col pb-3 items-center justify-center">
      <Lottie
        animationData={rocketAnimation}
        style={{
          width: '198px',
          height: '198px'
        }}
        className="chromatic-ignore"
      />
      <div className="px-10 pb-8 text-center text-xl font-medium">
        All set! Let’s reach new heights together.
      </div>
      <Button className="mb-2" size="small" onClick={handleExploreClick}>
        Explore Reforge
      </Button>
      <Button variant="text-only" size="small" onClick={closeChecklist}>
        Dismiss
      </Button>
    </div>
  )

  return (
    <ChecklistOverlay
      className="w-[370px] xs:w-[384px]"
      checklistName={ONBOARDING_CHECKLIST}
      title="Get the most out of Reforge"
      checklistTasks={checklistTasksDecorated}
      onCloseClick={closeChecklist}
      completionView={completionView}
      openButtonRef={openButtonRef}
    />
  )
}
