import { MouseEvent } from 'react'

import Button from 'components/Button'
import { useGlobalModal } from 'components/GlobalModal'
import { ModalContent } from 'components/Modal'

import { useTrackServerEventMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  getAnonymousId,
  trackCtaClicked,
  trackModalDismissed,
  trackModalDisplayed
} from 'utils/tracking/analytics'

interface CourseContentGateModalProps {
  courseTitle: string
  onCTAClick: () => void
  onClose: () => void
  referer: string
}

const CourseContentGateModal = ({
  courseTitle,
  onCTAClick,
  onClose,
  referer
}: CourseContentGateModalProps) => {
  const { currentUser } = useCurrentUser()

  return (
    <ModalContent>
      <div className="max-w-[610px] w-full mx-auto text-center pt-6 md:pt-10 pb-10 md:pb-24">
        <p className="text-[28px] font-medium leading-[1.2] mb-6">
          {currentUser?.is.eligibleForTrial
            ? 'Start a 14-day free trial to continue '
            : 'Become a member to continue '}
          {courseTitle}
        </p>

        <p className="text-base leading-[1.6] mb-16">
          Start your free trial today to gain unlimited access to the rest of this course
          and unlock all the tools, resources, and expert guidance you need to succeed.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Button fullWidth variant="outline" size="small" onClick={onClose}>
            Back
          </Button>
          <Button
            fullWidth
            size="small"
            href={
              currentUser?.is.eligibleForTrial
                ? `/try-reforge?referer=${referer}&returnOnClose=${window.location.pathname}`
                : `/subscribe/choose-plan?referer=${referer}&returnOnClose=${window.location.pathname}`
            }
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              trackCtaClicked({
                cta_location: 'free_course_trial_gate',
                cta_type: 'button',
                destination: e.currentTarget.getAttribute('href') || '',
                text: e.currentTarget.textContent || ''
              })
              onCTAClick()
            }}
          >
            {currentUser?.is.eligibleForTrial
              ? 'Start 14-day free trial'
              : 'Become a member'}
          </Button>
        </div>
      </div>
    </ModalContent>
  )
}

export const useCourseContentGate = ({
  courseTitle,
  courseId,
  contentTitle,
  contentId,
  contentType
}: {
  courseTitle: string
  courseId: string
  contentTitle?: string
  contentId?: string
  contentType: string
}) => {
  const { isLoggedIn, currentUser } = useCurrentUser()
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const [trackServerEvent] = useTrackServerEventMutation()

  const trackingData = {
    category: 'app' as const,
    location: 'free_course_preview',
    logged_in: isLoggedIn,
    modal_group: 'free_course_preview',
    modal_name: currentUser?.is.eligibleForTrial
      ? 'trial_start_content_gate'
      : 'expired_user_signup_content_gate',
    related_identifiers: {
      course_title: courseTitle,
      course_ccl_local_id: courseId,
      content_title: contentTitle,
      content_ccl_local_id: contentId,
      content_type: contentType
    }
  }

  const handleClose = () => {
    trackModalDismissed(trackingData)

    closeGlobalModal()
  }

  const openCourseContentGate = ({ destination }: { destination: string }) => {
    trackModalDisplayed(trackingData)

    trackServerEvent({
      variables: {
        input: {
          event: 'Conversion Page Viewed - Server',
          anonymousId: getAnonymousId(),
          properties: {
            access_policy_kind: currentUser?.accessPolicyKind,
            page_type: 'free_course_trial_gate',
            path: window.location.pathname,
            referrer: document.referrer,
            url: window.location.href,
            is_conversion_eligible: true,
            logged_in: !!currentUser,
            trial_status: currentUser?.trialStatus,
            related_identifiers: {
              course_id: courseId,
              course_title: courseTitle,
              content_id: contentId,
              content_title: contentTitle
            }
          }
        }
      }
    })

    openGlobalModal(
      <CourseContentGateModal
        courseTitle={courseTitle}
        onClose={handleClose}
        onCTAClick={handleClose}
        referer={destination}
      />,
      {
        className: 'rounded-xl max-w-[970px]',
        handleClose
      }
    )
  }

  return { openCourseContentGate }
}

export default CourseContentGateModal
