export const COURSE_SORT_TYPE_START_DATE = 'start_date'
export const COURSE_SORT_TYPE_DURATION = 'duration'
export const COURSE_SORT_TYPE_ALPHA = 'alphabetical'
export const COURSE_SORT_TYPE_POPULARITY = 'popularity'

export const SORT_TYPE_LABEL_MAP = {
  [COURSE_SORT_TYPE_START_DATE]: 'Live start date',
  [COURSE_SORT_TYPE_DURATION]: 'Duration',
  [COURSE_SORT_TYPE_ALPHA]: 'Alphabetical',
  [COURSE_SORT_TYPE_POPULARITY]: 'Most popular'
}
