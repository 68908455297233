import { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AccountBillingPage from 'pages/AccountBillingPage'
import AccountCohortHistoryPage from 'pages/AccountCohortHistoryPage'
import AccountMyOrganizationPage from 'pages/AccountMyOrganizationPage'
import AccountOverviewPage from 'pages/AccountOverviewPage'
import ArtifactPage from 'pages/Artifacts/ArtifactPage'
import ArtifactsIndexPage from 'pages/Artifacts/ArtifactsIndexPage/ArtifactsIndexPage'
import CohortViewerPage from 'pages/CohortViewerPage'
import CollectionShowPage from 'pages/CollectionShowPage'
import ContentViewerPage from 'pages/ContentViewer'
import { CourseOnDemandPage } from 'pages/CoursOnDemandPage/CourseOnDemandPage'
import { CourseCheckoutPage } from 'pages/CourseCheckoutPage/CourseCheckoutPage'
import CourseDetailsPageRouter from 'pages/CourseDetailsPage/CourseDetailsPageRouter'
import { CoursePaymentPageWrapper } from 'pages/CoursePurchaseFlow/CoursePayment/CoursePaymentPage'
import CourseSessionHostDashboard from 'pages/CourseSessionHostDashboard'
import CoursesIndexPage from 'pages/CoursesIndexPage/CoursesIndexPage'
import CourseEnrollConfirmationPage from 'pages/CoursesPage/CourseEnrollConfirmationPage'
import { CoursePage } from 'pages/CoursesPage/CoursePage'
import CreateAccountPage from 'pages/CreateAccountPage'
import EventCountdownPage from 'pages/EventCountdownPage/EventCountdownPage'
import EventPage from 'pages/EventPage/EventPage'
import { EventPastRecordings } from 'pages/EventRecordings/EventPastRecordings'
import EventsPage from 'pages/EventsPage'
import { MyEvents } from 'pages/EventsPage/components/MyEvents'
import EventUpcoming from 'pages/EventsUpcoming/EventUpcoming'
import ExplorePage from 'pages/Explore/ExplorePage/ExplorePage'
import ExploreSubtopicPage from 'pages/Explore/ExploreSubtopicPage/ExploreSubtopicPage'
import ExploreTopicPage from 'pages/Explore/ExploreTopicPage/ExploreTopicPage'
import ExtensionAuthenticatedPage from 'pages/ExtensionAuthenticatedPage'
import GroupPage from 'pages/GroupPage'
import GroupsPage from 'pages/GroupsPage'
import { GuidesPage } from 'pages/GuidesPage'
import HomePage from 'pages/HomePage'
import InvoicePage from 'pages/InvoicePage'
import LessonViewerPage from 'pages/LessonViewer'
import { ManagePlanPage } from 'pages/ManagePlanPage'
import MemberDirectoryPage from 'pages/MemberDirectoryPage'
import MemberProfilePage from 'pages/MemberProfilePage'
import { MyTeamPage } from 'pages/MyTeamPage'
import PostPage from 'pages/PostPage'
import PostsPage from 'pages/PostsPage'
import PreviewContent from 'pages/PreviewContent'
import ProfilePage from 'pages/Profiles/ProfilePage'
import ProgramPage from 'pages/ProgramPage'
import ProgramPreviewPage from 'pages/ProgramPreviewPage'
import ProgramsPage from 'pages/ProgramsPage/ProgramsPage'
import { PurchaseFlow } from 'pages/PurchaseFlow/PurchaseFlow'
import ReforgeCollectionsPage from 'pages/ReforgeCollectionsPage/ReforgeCollectionsPage'
import ReimbursementCenterPage from 'pages/ReimbursementPage'
import SavedItemsPage from 'pages/SavedItemsPage'
import SearchResultsPage from 'pages/SearchResultsPage'
import { TeamEngagementPage } from 'pages/TeamEngagementPage'
import { TeamMembersPage } from 'pages/TeamMembersPage'
import { UnitLandingPage } from 'pages/UnitLandingPage'
import UpgradePage from 'pages/UpgradePage'
import GoalsPage from 'pages/goals/GoalsPage'

import EventCancelRsvp from 'domains/Event/EventCancelRsvp'
import DiscoverTeamsPage from 'domains/Onboarding/FreeUserOnboarding/DiscoverTeamsPage'
import TryReforge from 'domains/Onboarding/FreeUserOnboarding/TryReforge'
import { Guidelines } from 'domains/Post/Guidelines'
import PostActivity from 'domains/Post/PostActivity'
import PostsContributions from 'domains/Post/PostsContributions'
import { ApproveJoinTeamRequestStatus } from 'domains/Subscription/ApproveJoinTeamRequestStatus'
import { DeclineJoinTeamRequestStatus } from 'domains/Subscription/DeclineJoinTeamRequestStatus'
import ConfirmUserMerge from 'domains/User/ConfirmUserMerge'

import Certificates from 'components/Certificates'
import Layout from 'components/Layout'
import PlanUpdateRequestForm from 'components/PlanOptionCard/PlanUpdateRequestForm'

import { CurrentUserPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import {
  CONTENT_VIEWER_URL,
  LESSON_VIEWER_URL,
  PURCHASE_FLOW_STEP_URL,
  PURCHASE_FLOW_URL,
  REIMBURSEMENT_CENTER_URL
} from 'utils/url'

import { ExternalRedirect } from './utils'

/* If a route is added, you need to add the path to the array in _analytics.html.erb where the
page tracking is occurring. This will prevent double firing of the analytics call. */

const PrivateRoutes = () => {
  const { currentUser, currentUserLoading, currentUserUpdating } = useCurrentUser()
  const { projectGoalsPoc, ref16263TopicBasedBrowsing } = useFeatureFlags()

  if ((currentUserLoading && !currentUserUpdating) || !currentUser) {
    return null
  }

  return (
    <Switch>
      <PrivateRoute exact path="/" component={HomePage} />
      <PrivateRoute
        exact
        path={[
          '/apply',
          '/apply/:token?',
          '/join/:code',
          '/growth_apply/:token?',
          '/retention_apply/:token?'
        ]}
        component={CreateAccountPage}
        showHeader={false}
        showSideBar={false}
      />

      <PrivateRoute
        exact
        path="/courses/:courseSlug/payment"
        component={CourseCheckoutPage}
        showHeader={false}
        showSideBar={false}
        pageTitle="Course Payment"
      />
      {/* TODO: Redirect from the /course-payment to /payment when new checkout page is turned on */}
      <PrivateRoute
        exact
        path="/course-payment/:slug/:startDate/:cclCourseSessionId"
        component={CoursePaymentPageWrapper}
        showHeader={false}
        showSideBar={false}
        pageTitle="Course Payment"
      />
      <PrivateRoute
        exact
        path="/course-enroll-confirmation"
        component={CourseEnrollConfirmationPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/courses"
        component={CoursesIndexPage}
        pageTitle="Courses"
      />
      <PrivateRoute
        exact
        path={[
          '/courses/:courseSlug/on-demand',
          '/courses/:courseSlug/on-demand/guides/:guideSlug',
          '/courses/:courseSlug/on-demand/artifacts/:artifactSlug',
          '/courses/:courseSlug/on-demand/course-blocks/:courseBlockIndex/content-blocks/:contentBlockSlug'
        ]}
        component={CourseOnDemandPage}
      />

      <PrivateRoute
        exact
        path={[
          '/courses/:courseSlug/details',
          '/courses/:courseSlug/events/:sanityEventId/preview',
          '/courses/:courseSlug/guides/:guideSlug/preview',
          '/courses/:courseSlug/artifacts/:artifactSlug/preview'
        ]}
        component={CourseDetailsPageRouter}
      />
      <Redirect exact from="/courses/:courseSlug" to="/courses/:courseSlug/details" />

      <Redirect
        exact
        from="/courses/:courseSlug/preview"
        to="/courses/:courseSlug/details"
      />

      <Redirect
        exact
        from="/courses/:courseSlug/:courseSessionId"
        to="/courses/:courseSlug/sessions/:courseSessionId"
      />

      <Redirect
        exact
        from="/courses/:courseSlug/units/:guideSlug/preview"
        to="/courses/:courseSlug/guides/:guideSlug/preview"
      />

      <Redirect
        exact
        from="/courses/:courseSlug/sessions/:courseSessionId/units/:guideSlug"
        to="/courses/:courseSlug/sessions/:courseSessionId/guides/:guideSlug"
      />

      <Redirect
        exact
        from="/courses/:courseSlug/on-demand/units/:guideSlug"
        to="/courses/:courseSlug/on-demand/guides/:guideSlug"
      />

      <Redirect
        exact
        from="/courses/:courseSlug/:courseSessionId/events/:sanityEventId"
        to="/courses/:courseSlug/sessions/:courseSessionId/events/:sanityEventId"
      />

      <Redirect
        from="/courses/:courseSlug/:courseSessionId/guides/:guideSlug"
        to="/courses/:courseSlug/sessions/:courseSessionId/guides/:guideSlug"
      />

      <PrivateRoute
        exact
        path={[
          '/courses/:courseSlug/sessions/:courseSessionId',
          '/courses/:courseSlug/sessions/:courseSessionId/events/:sanityEventId',
          '/courses/:courseSlug/sessions/:courseSessionId/guides/:guideSlug',
          '/courses/:courseSlug/sessions/:courseSessionId/artifacts/:artifactSlug',
          '/courses/:courseSlug/sessions/:courseSessionId/course-blocks/:courseBlockIndex/content-blocks/:contentBlockSlug'
        ]}
        component={CoursePage}
      />

      <PrivateRoute
        exact
        path="/courses/:courseSlug/sessions/:courseSessionId/host"
        component={CourseSessionHostDashboard}
      />

      <PrivateRoute
        exact
        path="/account"
        component={AccountOverviewPage}
        pageTitle="My Account"
        headerDataTest="account-pages-nav"
      />
      <PrivateRoute
        exact
        path="/course-history"
        component={AccountCohortHistoryPage}
        pageTitle="Course History"
        headerDataTest="account-pages-nav"
      />
      <PrivateRoute
        exact
        path={['/my_team/general', '/my_team/members']}
        component={MyTeamPage}
        pageTitle="My Team"
        headerDataTest="account-pages-nav"
      />
      <PrivateRoute
        exact
        path="/my_organization"
        component={AccountMyOrganizationPage}
        pageTitle="My Organization"
        headerDataTest="account-pages-nav"
      />
      <PrivateRoute
        exact
        path="/approve_team_join_request"
        component={ApproveJoinTeamRequestStatus}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/decline_team_join_request"
        component={DeclineJoinTeamRequestStatus}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/billing"
        component={AccountBillingPage}
        pageTitle="Billing"
        headerDataTest="account-pages-nav"
      />
      <PrivateRoute
        exact
        path="/team_members"
        component={TeamMembersPage}
        pageTitle="Team Members"
        headerDataTest="account-pages-nav"
      />
      <PrivateRoute
        exact
        path="/manage-plan"
        component={ManagePlanPage}
        pageTitle="Manage Plan"
        headerDataTest="account-pages-nav"
      />
      <PrivateRoute
        exact={false}
        path="/team-engagement"
        component={TeamEngagementPage}
        pageTitle="Team Engagement"
        headerDataTest="account-pages-nav"
      />
      <PrivateRoute
        exact
        path="/user_merges/:nonce/confirm"
        component={ConfirmUserMerge}
      />
      <PrivateRoute
        exact={false}
        path="/plan-update-request"
        component={PlanUpdateRequestForm}
        showSideBar={false}
        showHeader={false}
      />

      <PrivateRoute
        exact
        path="/directory/:userId"
        component={MemberProfilePage}
        pageTitle="Member"
      />
      <PrivateRoute
        exact
        path="/directory"
        component={MemberDirectoryPage}
        pageTitle="Directory"
      />

      {projectGoalsPoc && <PrivateRoute exact path="/goals" component={GoalsPage} />}

      <PrivateRoute exact path="/events" component={EventsPage} pageTitle="Events" />
      <PrivateRoute exact path="/events/my-events" component={MyEvents} />
      <PrivateRoute exact path="/events/recordings" component={EventPastRecordings} />
      <PrivateRoute exact path="/events/upcoming" component={EventUpcoming} />
      <PrivateRoute
        exact
        path="/events/:id-:slug/cancel_rsvp"
        component={EventCancelRsvp}
      />
      <PrivateRoute
        exact
        path={[
          '/events/:id-:slug',
          '/events/:id-:slug/attendees',
          '/events/:id-:slug/summary',
          // has to be after :id-:slug oherwise :id will always match and include the slug
          '/events/:id'
        ]}
        component={EventPage}
      />
      {/* Start Post routes */}
      <PrivateRoute exact component={Guidelines} path="/posts/guidelines" />
      <PrivateRoute
        exact
        component={PostActivity}
        path="/posts/activity"
        pageTitle="Activity"
      />
      <PrivateRoute
        exact
        component={PostsContributions}
        path="/posts/my-contributions"
        pageTitle="My Contributions"
      />
      <PrivateRoute
        exact
        component={PostsPage}
        path="/posts/collection/:topic"
        pageTitle="Discussions"
      />
      <PrivateRoute
        exact
        component={PostPage}
        path={['/posts/:slug', '/groups/:group/posts/:slug']}
      />
      <PrivateRoute exact component={PostsPage} path="/posts" pageTitle="Discussions" />
      <PrivateRoute
        exact
        component={PostsPage}
        path="/posts/unfollow/:followId/:followType"
        pageTitle="Discussions"
      />
      {/* End Post routes */}

      {/* Start redirect to /guides routes */}
      <Redirect exact from="/concepts" to="/guides" />
      <Redirect exact from="/projects" to="/guides" />
      <Redirect exact from="/units" to="/guides" />
      <Redirect exact from="/units/:slug" to="/guides/:slug" />
      {/* End redirect to /guides routes */}
      <PrivateRoute exact path="/guides" component={GuidesPage} pageTitle="Guides" />
      <PrivateRoute exact path={['/guides/:slug']} component={UnitLandingPage} />
      <PrivateRoute
        exact
        path={['/groups/:group/members', '/groups/:group']}
        component={GroupPage}
      />
      <PrivateRoute exact path="/saved" component={SavedItemsPage} pageTitle="Saved" />
      <PrivateRoute
        exact
        path="/saved/:id"
        component={CollectionShowPage}
        pageTitle="Saved"
      />
      <PrivateRoute
        exact
        path={['/programs/:programSlug', '/programs/:programSlug/bookmarks']}
        component={ProgramPage}
      />
      <PrivateRoute exact path="/upgrade" component={UpgradePage} />
      <PrivateRoute
        exact
        path="/events/links/:uuid"
        component={EventCountdownPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/end"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/conversations"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/conversations/:topicSlug"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/conversations/:topicSlug/thread/:postId"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/events/links/:uuid"
        component={EventCountdownPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path={[
          '/cohorts/:slug/events/:eventId-:eventSlug',
          '/cohorts/:slug/events/:eventId-:eventSlug/attendees',
          '/cohorts/:slug/events/:eventId-:eventSlug/summary',
          // has to be after :eventId-:eventSlug otherwise :eventId will always match and include the slug
          '/cohorts/:slug/events/:eventId'
        ]}
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/material"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/c/:cmsProgramSlug/:cmsModuleSlug/:cmsSectionParentSlug?/:cmsSectionSlug"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/bookmarks"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/notifications"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/highlights"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/cohorts/:slug/my-team"
        component={CohortViewerPage}
        showHeader={false}
        showSideBar={false}
      />
      <PrivateRoute
        exact
        path="/programs"
        component={ProgramsPage}
        pageTitle="Programs"
      />
      <PrivateRoute
        exact
        path="/collections"
        component={ReforgeCollectionsPage}
        pageTitle="Collections"
      />
      <PrivateRoute
        exact
        path={['/programs/:slug/preview', '/programs/:slug/preview/material']}
        component={ProgramPreviewPage}
      />
      <PrivateRoute exact path="/search" component={SearchResultsPage} />
      <PrivateRoute exact path="/groups" component={GroupsPage} pageTitle="Groups" />
      <PrivateRoute exact path="/letters" component={Certificates} pageTitle="Letters" />
      <PrivateRoute
        exact
        path="/invoices/:invoiceId"
        component={InvoicePage}
        pageTitle="Invoice"
      />
      <PrivateRoute exact path={LESSON_VIEWER_URL} component={LessonViewerPage} />
      <PrivateRoute
        exact
        path={REIMBURSEMENT_CENTER_URL}
        component={ReimbursementCenterPage}
      />
      <PrivateRoute exact path={CONTENT_VIEWER_URL} component={ContentViewerPage} />
      <PrivateRoute
        exact
        path="/preview_content"
        component={PreviewContent}
        pageTitle="Content Coming Soon"
      />

      <PrivateRoute
        exact
        path="/artifacts"
        component={ArtifactsIndexPage}
        pageTitle="Artifacts"
      />
      <PrivateRoute exact path="/artifacts/:slug" component={ArtifactPage} />

      <PrivateRoute exact path="/profiles/:slug" component={ProfilePage} />

      <PrivateRoute
        exact
        component={PurchaseFlow}
        path={[PURCHASE_FLOW_URL, PURCHASE_FLOW_STEP_URL]}
        showSideBar={false}
        showHeader={false}
      />
      <PrivateRoute
        exact
        component={TryReforge}
        path="/try-reforge"
        showSideBar={false}
        showHeader={false}
      />
      <PrivateRoute
        exact
        component={DiscoverTeamsPage}
        path="/discover-teams"
        showSideBar={false}
        showHeader={false}
      />
      <PrivateRoute
        exact
        path="/extension-authenticated"
        component={ExtensionAuthenticatedPage}
        pageTitle="Logged In"
        showHeader={false}
        showSideBar={false}
        useLayout={false}
      />
      {ref16263TopicBasedBrowsing && (
        <PrivateRoute
          exact
          path="/explore"
          component={ExplorePage}
          pageTitle="Explore"
          showHeader={true}
          showSideBar={true}
          useLayout={true}
        />
      )}
      {ref16263TopicBasedBrowsing && (
        <PrivateRoute
          exact
          path="/explore/:topic"
          component={ExploreTopicPage}
          showHeader={true}
          showSideBar={true}
          useLayout={true}
        />
      )}
      {ref16263TopicBasedBrowsing && (
        <PrivateRoute
          exact
          path="/explore/:topic/:subtopic"
          component={ExploreSubtopicPage}
          showHeader={true}
          showSideBar={true}
          useLayout={true}
        />
      )}
    </Switch>
  )
}

const PrivateRoute = ({
  component: Component,
  exact,
  path,
  pageTitle,
  showHeader = true,
  showSideBar = true,
  headerDataTest,
  useLayout = true
}: {
  component: FC<any>
  exact?: boolean
  path: string | string[]
  pageTitle?: string
  showHeader?: boolean
  showSideBar?: boolean
  headerDataTest?: string
  useLayout?: boolean
}) => {
  const { currentUser } = useCurrentUser()

  const ComponentOrRedirect = (props: any) => {
    if (currentUser) {
      const [shouldRedirect, redirectTo] = handleRedirects(currentUser)

      // `&& redirectTo` should not be necessary but TS doesn't seem smart enough to know it's
      // a string if we desctructure. Oddly it works without destructuring.
      if (shouldRedirect && redirectTo) {
        if (redirectTo.includes('https://www.')) {
          return <ExternalRedirect url={redirectTo} />
        }

        return <Redirect to={redirectTo} />
      }
      return <Component {...props} />
    }

    return <Redirect to={{ pathname: '/login' }} />
  }

  return useLayout ? (
    <Layout
      pageTitle={pageTitle}
      showHeader={showHeader}
      showSideBar={showSideBar}
      headerDataTest={headerDataTest}
    >
      <Route exact={exact} path={path} render={ComponentOrRedirect} />
    </Layout>
  ) : (
    <Route exact={exact} path={path} render={ComponentOrRedirect} />
  )
}

function handleRedirects(user: CurrentUserPartsFragment): [true, string] | [false, null] {
  // This is the sister function of handle_redirects in spa_controller.rb
  // This func will get pretty ugly after a while. Open to suggestions on best how to form it.
  // This func also has to handle redirects to a non-SPA page, which sucks
  const { pathname, search } = window.location
  const params = new URLSearchParams(search)
  const mtoken = params.get('mtoken')

  const { accessPolicyKind: kind, can } = user
  const {
    viewMemberIndex,
    viewCohortIndex,
    manageMyTeam,
    assistantManageTeam,
    viewPost
  } = can || {}

  // send people to the apply page if they don't have an mtoken and haven't been accepted (anyone who has applied is).
  // mtoken is for a team member that is invited to a team. If they are invited to the team,
  // they should go straight to the dashboard. From there the mtoken is used to activate their team member
  // subscription in the HomePageContainer.
  if (kind === 'account_created' && !pathname.includes('/apply')) {
    if (pathname !== '/') {
      return [
        true,
        `/apply?fwd=${encodeURIComponent(pathname)}${mtoken ? `&mtoken=${mtoken}` : ''}`
      ]
    }
    return [true, `/apply${mtoken ? `?mtoken=${mtoken}` : ''}`]
  }

  if (pathname === '/billing') {
    // TODO: Change this to '!can.viewBilling' when the currentUser is always coming from GraphQL.
    if (kind === 'account_created' || !can.viewBilling) {
      return [true, '/account']
    }
  }

  // Redirect to root if user is an account_created or they need to be onboarded.
  if (['/events', '/directory'].includes(pathname)) {
    if (kind === 'account_created') {
      return [true, '/']
    }
  }

  // Page specific rules.
  if (['/posts'].includes(pathname) && !viewPost) return [true, '/']
  if (
    ['/directory', '/groups'].includes(pathname) &&
    (!viewMemberIndex || user.is.trialing)
  ) {
    return [true, '/']
  }

  if (pathname.includes('/cohorts') && !viewCohortIndex) return [true, '/']
  if (pathname.includes('/team_members') && (manageMyTeam || assistantManageTeam)) {
    return [false, null]
  }
  if (pathname.includes('/team_members') && !manageMyTeam) {
    return [true, '/account']
  }

  return [false, null]
}

export default PrivateRoutes
