import {
  ReactNode,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react'

import { TabsType } from 'components/Tabs/Tabs'

import PageHeader from './PageHeader'

interface SectionSize {
  height: number
  width: number
}

interface SizeState {
  banner?: SectionSize
  header?: SectionSize
  pageTitle?: SectionSize
  pageContent?: SectionSize
  sideBar?: SectionSize
  sidePanel?: SectionSize
}

type Action = {
  type: 'SET_SIZE'
  section: keyof SizeState
  height: number
  width: number
}

const initialState: SizeState = {}

function sectionSizeReducer(state: SizeState, action: Action): SizeState {
  switch (action.type) {
    case 'SET_SIZE':
      return {
        ...state,
        [action.section]: { height: action.height, width: action.width }
      }
    default:
      return state
  }
}

interface PageContextState {
  sectionSizes: SizeState
  setPageTitle: (content?: string | ReactNode | null) => void
  setPageSubtitle: (content?: string | null) => void
  setPageTabs: (content?: TabsType | null) => void
  setPageHeaderTopRightContent: (content?: ReactNode | null) => void
  setPageHeaderTopLeftContent: (content?: ReactNode | null) => void
  setPageAboveHeader: (content?: ReactNode | null) => void
}

const PageContext = createContext<PageContextState>({
  sectionSizes: {},
  setPageTitle: () => {},
  setPageTabs: () => {},
  setPageHeaderTopRightContent: () => {},
  setPageHeaderTopLeftContent: () => {},
  setPageAboveHeader: () => {},
  setPageSubtitle: () => {}
})

interface PageProviderProps {
  showHeader: boolean
  pageTitle?: string | ReactNode
  children: ReactNode
  headerWrapperClassName?: string
  dataTest?: string
  headerRef?: RefObject<HTMLDivElement>
  bannerRef?: RefObject<HTMLDivElement>
  sidePanelRef?: RefObject<HTMLDivElement>
  sideBarRef?: RefObject<HTMLDivElement>
}

export const PageProvider = ({
  headerRef,
  bannerRef,
  sideBarRef,
  sidePanelRef,
  showHeader,
  pageTitle,
  children,
  headerWrapperClassName,
  dataTest
}: PageProviderProps) => {
  const [title, setTitle] = useState<string | ReactNode | null>()
  const pageTitleRef = useRef<HTMLDivElement>(null)
  const pageContentRef = useRef<HTMLDivElement>(null)
  const [subtitle, setPageSubtitle] = useState<string | null>()
  const [headerTopRight, setHeaderTopRight] = useState<ReactNode | null>()
  const [headerTopLeft, setHeaderTopLeft] = useState<ReactNode | null>()
  const [headerAboveTitle, setHeaderAboveTitle] = useState<ReactNode | null>()
  const [tabs, setTabs] = useState<TabsType | null>()

  const setPageTitle = useCallback((t: string | ReactNode | null) => {
    setTitle(!t ? null : t)
  }, [])

  const [sectionSizes, dispatch] = useReducer(sectionSizeReducer, initialState)

  useEffect(() => {
    const sections = {
      banner: bannerRef?.current,
      header: headerRef?.current,
      sideBar: sideBarRef?.current,
      sidePanel: sidePanelRef?.current,
      pageTitle: pageTitleRef.current,
      pageContent: pageContentRef.current
    }

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const height = Math.floor(entry.contentRect.height)
        const width = Math.floor(entry.contentRect.width)
        const sectionName = Object.keys(sections).find(
          (key) => sections[key as keyof SizeState] === entry.target
        ) as keyof SizeState

        const sectionIsBeingTracked = !!sectionName
        const sectionSizeHasChanged =
          sectionSizes[sectionName]?.width !== width ||
          sectionSizes[sectionName]?.height !== height

        if (sectionIsBeingTracked && sectionSizeHasChanged) {
          dispatch({
            type: 'SET_SIZE',
            section: sectionName,
            height,
            width
          })
        }
      })
    })

    Object.values(sections).forEach((section) => {
      if (section) {
        resizeObserver.observe(section)
      }
    })

    return () => {
      Object.values(sections).forEach((section) => {
        if (section) {
          resizeObserver.unobserve(section)
        }
      })
    }
  }, [bannerRef, headerRef, pageContentRef, sideBarRef, sidePanelRef, sectionSizes])

  return (
    <>
      {showHeader && (
        <div ref={pageTitleRef} className={headerWrapperClassName}>
          <PageHeader
            title={title ?? pageTitle}
            subtitle={subtitle}
            headerTopRight={headerTopRight}
            headerTopLeft={headerTopLeft}
            headerAboveTitle={headerAboveTitle}
            tabs={tabs}
            dataTest={dataTest}
          />
        </div>
      )}
      <div ref={pageContentRef}>
        <PageContext.Provider
          value={{
            sectionSizes,
            setPageTitle,
            setPageTabs: setTabs,
            setPageHeaderTopRightContent: setHeaderTopRight,
            setPageHeaderTopLeftContent: setHeaderTopLeft,
            setPageAboveHeader: setHeaderAboveTitle,
            setPageSubtitle
          }}
        >
          {children}
        </PageContext.Provider>
      </div>
    </>
  )
}

export function usePage() {
  return useContext(PageContext)
}
