import { useCallback, useEffect, useRef, useState } from 'react'

import { Loading } from 'components'
import Button from 'components/Button'
import EmailChipsInput from 'components/EmailChipsInput/EmailChipsInput'

import {
  Seat,
  TeamMembersPageSubscriptionFragment,
  useAssignSeatMutation,
  useTeamMembersPageQuery
} from 'gql'

import InviteLink from './components/InviteLink'
import { MultiInviteStatusModal } from './components/MultiInviteStatusModal'
import PendingInviteList from './components/PendingInviteList'

export interface MultiInviteModalContentProps {
  currentUserId: string
  handleEmailsChange: (emails: string[]) => void
  onUpgradePlanClick: () => void
  hidePendingInviteList?: boolean
  onInvitesSent?: (successes: string[]) => void
  canonicalUrl?: string
}

export const MultiInviteModalContent = ({
  currentUserId,
  handleEmailsChange,
  onUpgradePlanClick,
  hidePendingInviteList,
  onInvitesSent,
  canonicalUrl
}: MultiInviteModalContentProps) => {
  // any updates to subscriptionState are handled in the useEffect below
  // this is the subscription that will be passed into the refetchPendingSeats and refetchAllFilledSeats functions
  const [subscriptionState, setSubscriptionState] =
    useState<TeamMembersPageSubscriptionFragment>()
  const [pendingSeatsState, setPendingSeatsState] = useState<Seat[]>([])
  const [allFilledSeatsState, setAllFilledSeatsState] = useState<Seat[]>([])
  const [maxEmailsState, setMaxEmailsState] = useState(0)
  const [emails, setEmails] = useState<string[]>([])
  const emailsRef = useRef<string[]>(emails)
  const [noEmailsError, setNoEmailsError] = useState<string | null>(null)
  // this below keeps track any emails that failed to send for whatever reason
  const [emailErrorMessages, setEmailErrorMessages] = useState<
    { email: string; errors: string }[]
  >([])
  // this below keeps track of any emails that were successfully sent
  const [successfulEmails, setSuccessfulEmails] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  // this below only tracks whether there are any disallowed emails in the list
  const [hasDisallowedEmails, setHasDisallowedEmails] = useState(false)
  const [assignSeat] = useAssignSeatMutation()

  const {
    data,
    loading: tmpqLoading,
    refetch
  } = useTeamMembersPageQuery({
    variables: { id: currentUserId }
  })

  const getPendingSeats = (subscription: TeamMembersPageSubscriptionFragment): Seat[] => {
    return subscription.seats
      .filter(
        (seat): seat is Seat =>
          seat.subscriptionMember !== null &&
          seat.subscriptionMember?.status === 'pending'
      )
      .sort((a, b) => {
        if (a.subscriptionMember?.email && b.subscriptionMember?.email) {
          return a.subscriptionMember.email.localeCompare(b.subscriptionMember.email)
        }
        return 0
      })
  }

  const getAllFilledSeats = (
    subscription: TeamMembersPageSubscriptionFragment
  ): Seat[] => {
    const filledSeats = subscription.seats.filter(
      (seat): seat is Seat => seat.subscriptionMember !== null
    )
    return filledSeats
  }

  const activeSubscription = data?.user?.subscriptions?.active!
  const totalSeatCount = activeSubscription?.seats.length || 0
  const assignedSeatCount =
    activeSubscription?.seats.filter((seat) => seat.subscriptionMember !== null).length ||
    0
  const maxEmailCount = totalSeatCount - assignedSeatCount

  useEffect(() => {
    setMaxEmailsState(maxEmailCount)
    setSubscriptionState(activeSubscription!)
    if (subscriptionState) {
      setPendingSeatsState(getPendingSeats(subscriptionState))
      setAllFilledSeatsState(getAllFilledSeats(subscriptionState))
    }
  }, [subscriptionState, activeSubscription, maxEmailCount])

  // called from the EmailChipsInput component
  const handleHasDisallowedEmail = useCallback((present: boolean) => {
    setHasDisallowedEmails(present)
  }, [])

  const subscriptionToken = activeSubscription?.teamInviteToken ?? ''

  const handleUpdateMaxEmails = (count: number) => {
    setMaxEmailsState((prevMaxEmails) => prevMaxEmails + count)
  }

  const handleSendInvitesClick = () => {
    if (emailsRef.current.length === 0) {
      setNoEmailsError('Please enter at least one email address.')
      return
    }
    submitModal()
  }

  const emailsChanged = (newEmails: string[]) => {
    setEmails(newEmails)
    emailsRef.current = newEmails
    handleEmailsChange(newEmails)
    setNoEmailsError(null)
  }

  const submitModal = async () => {
    setLoading(true)
    const emailsToSend = emailsRef.current
    const assignableSeats =
      subscriptionState?.seats.filter((seat) => seat.subscriptionMember === null) || []
    const seatsLength = assignableSeats?.length || 0
    const emailsToSendLength = emailsToSend.length
    const smallestLength = Math.min(seatsLength, emailsToSendLength)
    const errors: { email: string; errors: string }[] = []
    const successes: string[] = []

    for (let index = 0; index < smallestLength; index++) {
      const result = await sendInvite(emailsToSend[index], assignableSeats[index] as Seat)
      if (result.errors) {
        errors.push(result)
      } else {
        successes.push(result.email)
        setMaxEmailsState((prevMaxEmails) => prevMaxEmails - 1)
      }
    }
    // in the off chance that there are more emails than seats
    // ie. some assistant admin assigns seats in the middle of this process
    // this will track those emails for which a seat was not available
    if (emailsToSendLength > seatsLength) {
      for (let index = seatsLength; index < emailsToSendLength; index++) {
        errors.push({ email: emailsToSend[index], errors: 'No more seats available.' })
      }
    }

    if (errors.length) {
      setEmailErrorMessages(errors)
    }
    setSuccessfulEmails(successes)
    if (successes.length > 0) {
      // if there were successful invites sent, then we need to refetch the subscription
      // in order to update the pending seats which will trigger a re-render of the PendingInviteList
      refetch().then(() => {
        setSubscriptionState(data?.user?.subscriptions?.active!)
        if (onInvitesSent) {
          onInvitesSent(successes)
        }
      })
    }
    // reset the EmailChipsInput and the loading state
    setEmails([])
    handleEmailsChange([])
    setLoading(false)
  }

  const sendInvite = async (
    email: string,
    assignableSeat: Seat
  ): Promise<{ email: string; errors: string }> => {
    let errors = ''
    if (subscriptionState) {
      const result = await assignSeat({
        variables: {
          input: {
            seatId: assignableSeat.id,
            email,
            subscriptionId: subscriptionState.id
          }
        }
      })
      if (result.errors || result.data?.assignSeat?.errors?.length) {
        errors = result.data?.assignSeat?.errors?.join(' ') || 'Unable to send invite.'
      }
    }

    return { email: email, errors: errors }
  }

  return (
    <div className="bg-white rounded-lg">
      <div className="pl-4 pr-4 pt-0 pb-0 mb-3.5 mt-0 w-full">
        <h1 className="mb-0 font-polysans leading-[1.2] tracking-[-4%] text-rb-gray-400 font-medium text-[20px] sm:text-3xl">
          Manage Your Invites
        </h1>
        <p className="mt-0 text-[12px] sm:text-[16px]">
          You have {maxEmailsState} {maxEmailsState === 1 ? 'seat' : 'seats'} remaining on
          your plan
        </p>
      </div>
      <div
        className={`p-4 pt-2 border-t border-gray-200 w-full mb-2 ${hidePendingInviteList ? '' : 'border-b'}`}
      >
        <h4 className="font-polysans text-rb-gray-400 font-semibold mb-0">
          Add new users
        </h4>
        <p className="mt-0 mb-1 font-light text-[12px]">Invite users via email</p>

        {successfulEmails.length > 0 || emailErrorMessages.length > 0 ? (
          <MultiInviteStatusModal
            emailErrors={emailErrorMessages}
            successes={successfulEmails.length > 0}
            close={() => {
              setEmailErrorMessages([])
              setSuccessfulEmails([])
            }}
          />
        ) : (
          <>
            <EmailChipsInput
              onEmailsChange={emailsChanged}
              maxEmails={maxEmailsState}
              turnOffEmailCountValidation={loading}
              disallowedEmails={allFilledSeatsState
                .map((seat) => seat.subscriptionMember?.email)
                .filter((email): email is string => email !== undefined)}
              setHasDisallowedEmailsInList={handleHasDisallowedEmail}
            />
            {noEmailsError && (
              <p className="text-red-500 text-[12px] mb-0">{noEmailsError}</p>
            )}
            <div className="flex justify-end pt-4 mb-4 sm:mb-0">
              {maxEmailsState > 0 && (
                <Button
                  className="w-full p-2 sm:w-auto sm:p-2.5 sm:pt-1.5 sm:pb-1.5 text-[12px] rounded-none sm:rounded-sm"
                  onClick={() => {
                    handleSendInvitesClick()
                  }}
                  disabled={loading || hasDisallowedEmails || emails.length === 0}
                  isLoadingSpinner={loading}
                >
                  Send invites
                </Button>
              )}
              {maxEmailsState === 0 && (
                <>
                  <button
                    disabled
                    className="hidden sm:inline p-2 sm:ml-1 sm:mr-1 sm:w-auto sm:p-2.5 sm:pt-1.5 sm:pb-1.5 bg-gray-200 text-gray-500 text-[12px] rounded-none sm:rounded-sm"
                  >
                    Send invites
                  </button>
                  <button
                    className="w-full p-2 sm:ml-1 sm:mr-1 sm:w-auto sm:p-2.5 sm:pt-1.5 sm:pb-1.5 bg-black text-white text-[12px] rounded-none sm:rounded-sm"
                    onClick={() => {
                      onUpgradePlanClick()
                    }}
                  >
                    Upgrade plan
                  </button>
                </>
              )}
            </div>
          </>
        )}
        <InviteLink subscriptionToken={subscriptionToken} canonicalUrl={canonicalUrl} />
      </div>
      {!hidePendingInviteList && (
        <>
          {tmpqLoading ? (
            <Loading />
          ) : (
            <PendingInviteList
              pendingSeats={pendingSeatsState}
              currentUserId={currentUserId}
              refetchSubscription={refetch}
              onMaxEmailsChange={handleUpdateMaxEmails}
            />
          )}
        </>
      )}
    </div>
  )
}
