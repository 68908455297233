import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import UIkit from 'uikit'

import { useCohortDashboardTrackingContext } from 'domains/CohortDashboard/hooks/useCohortDashboardTrackingContext'
import { SeatReservedModal } from 'domains/Event/SeatReservedModal'
import { useTrackEventConversionPageViewed } from 'domains/Event/hooks/useTrackEventConversionPageViewed'

import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'

import { EventQuery, SpeakerPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useURLParams from 'hooks/useURLParams'

import { capitalizeFirstLetter } from 'utils/stringUtils'
import { cn } from 'utils/tailwind'
import { trackCtaClicked } from 'utils/tracking/analytics'
import { track } from 'utils/tracking/segment'

import EventRelatedResources from './EventRelatedResources'

interface EventShowProps {
  userTimezone: string
  event: EventQuery['event']
  hosts: SpeakerPartsFragment[]
  featuredGuests: SpeakerPartsFragment[]
}

function EventShow({ userTimezone, event }: EventShowProps) {
  useTrackEventConversionPageViewed({ event })

  const { isLoggedIn } = useCurrentUser()
  const { getParam } = useURLParams()
  const showSeatReservedModal = getParam('show_confirmation') && isLoggedIn
  const eventCopy = 'Event'
  const caseStudyEvent = event.caseCompany?.name && event.kind === 'Cohort Event'

  useEffect(() => {
    if (showSeatReservedModal) {
      openSeatReservedModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSeatReservedModal])

  const openSeatReservedModal = () => {
    const seatReserved = document.getElementById('modal-seat-reserved')
    if (seatReserved) {
      UIkit.modal(seatReserved).show()
      // @ts-ignore - 'Event Seat Reserved Modal Opened' event is not defined in Segment JIRA#REF-5159
      track('Event Seat Reserved Modal Opened', {
        event_id: event.id
      })
    }
  }

  const { cohort, userCohortId } = useCohortDashboardTrackingContext()

  const onLinkClick = () => {
    trackCtaClicked({
      cta_location: 'event_details_page_preread_link',
      cta_type: 'link',
      text: 'Event pre-read',
      destination: event.preread,
      related_identifiers: {
        cohort_id: cohort.id,
        cohort_name: cohort.name,
        user_cohort_id: userCohortId
      }
    })
  }

  return (
    <>
      <div className="mb-16 flex flex-col tl:flex-row">
        {/* About this Event */}
        <div className={cn('order-last space-y-4 tl:order-first tl:mr-2')}>
          {event.description && (
            <div>
              <div className="mb-2 text-xl font-semibold text-black">
                About this {eventCopy}
              </div>
              {caseStudyEvent && (
                <RfParagraphMedium>
                  <span className="font-semibold">
                    {event.caseCompany?.name} Case Study
                  </span>
                </RfParagraphMedium>
              )}
              <div>
                <div dangerouslySetInnerHTML={{ __html: event.description as string }} />
              </div>
            </div>
          )}

          {event.eventFormat !== '' && (
            <div>
              <div className="mb-2 text-xl font-semibold text-black">
                {capitalizeFirstLetter(eventCopy)} Format
              </div>
              {caseStudyEvent && (
                <RfParagraphMedium>
                  <span className="font-semibold">
                    {event.caseCompany?.name} Case Study
                  </span>
                </RfParagraphMedium>
              )}
              <div>
                {event.eventFormat && (
                  <div dangerouslySetInnerHTML={{ __html: event.eventFormat }} />
                )}
              </div>
            </div>
          )}

          {event.preread && (
            <div className="mt-10">
              <Link
                to={{ pathname: event.preread }}
                target="_blank"
                className="hover:no-underline"
                onClick={onLinkClick}
              >
                <RfParagraphMediumSemiBold className="text-rb-teal-200">
                  Event pre-read
                </RfParagraphMediumSemiBold>
              </Link>
            </div>
          )}

          <div>
            <EventRelatedResources event={event} />
          </div>
        </div>
      </div>

      {isLoggedIn && <SeatReservedModal event={event} timezone={userTimezone} />}
    </>
  )
}

export default EventShow
